import { Typography } from "@mui/material";

/**
 * With the new theme customizations coming up in theme.js starting Sep24,
 * this class has been deprecated in favous of using ```<Typography variant="h4" />```
 * instead
 * @param {*} props
 * @returns
 */
export const H4 = (props) => {
  return (
    <Typography
      variant="h4"
      style={
        {
          // base style goes here
        }
      }
      {...props}
    />
  );
};
