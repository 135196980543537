// Import custom components
import {
    UPDATE_TOKEN,
    INSERT_TOKEN,
    DELETE_TOKEN,
    GET_TOKEN_LIST
} from '../constants/actionType';

var initialState = {
    token: null,
    data: null,
    errorMessage: null
};

/**
 * A reducer takes two arguments, the current state and an action.
 */
const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case INSERT_TOKEN:
            return {
                ...state,
                token: null,
                data: action.data
            };
        case GET_TOKEN_LIST:
            return {
                ...state,
                token: null,
                data: action.data
            };
        case UPDATE_TOKEN:
            return {
                ...state,
                token: action.data,
                data: action.data.data
            };

        case DELETE_TOKEN:
            return {
                ...state,
                token: null,
                data: null,
                errorMessage: action.error.message || 'Something went wrong.'
            };

        default:
            return state;
    }
};

export default authReducer;
