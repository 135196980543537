import { createTheme } from "@mui/material";
import { blue } from "@mui/material/colors";

const theme = createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 768,
      laptop: 1440,
      desktop: 1920,
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      "Roboto",
      "Protest Guerrilla",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  palette: {
    primary: blue,
    cloudNudge: {
      main: "#FF0000",
      dull: "#818181",
      red: "#EB4132",
      green: "#30A952",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            background: "rgba(0, 0, 0, 0.12)",
            color: "rgba(0, 0, 0, 0.38)",
          },
          "&:hover": {
            background: "#0074d040",
          },
        },
      },
    },
  },
});

theme.typography.h3 = {
  fontWeight: 400,
  [theme.breakpoints.up("mobile")]: {
    fontSize: "28px",
  },
  [theme.breakpoints.up("tablet")]: {
    fontSize: "42px",
  },
  [theme.breakpoints.up("laptop")]: {
    fontSize: "48px",
  },
  [theme.breakpoints.up("desktop")]: {
    fontSize: "48px",
  },
};

theme.typography.h4 = {
  fontSize: "34px",
  fontWeight: 400,
  [theme.breakpoints.up("mobile")]: {
    fontSize: "24px",
  },
  [theme.breakpoints.up("tablet")]: {
    fontSize: "28px",
  },
  [theme.breakpoints.up("laptop")]: {
    fontSize: "34px",
  },
  [theme.breakpoints.up("desktop")]: {
    fontSize: "34px",
  },
};

theme.typography.h6 = {
  fontSize: "48px",
  fontWeight: 400,
  [theme.breakpoints.up("mobile")]: {
    fontSize: "32px", // WARN: design override. Increasing this size, introduces horizontal scroll
  },
  [theme.breakpoints.up("tablet")]: {
    fontSize: "46px",
  },
  [theme.breakpoints.up("laptop")]: {
    fontSize: "48px",
  },
  [theme.breakpoints.up("desktop")]: {
    fontSize: "50px",
  },
};

theme.typography.h5 = {
  fontSize: "24px",
  fontWeight: 400,
  [theme.breakpoints.up("mobile")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.up("tablet")]: {
    fontSize: "18px",
  },
  [theme.breakpoints.up("laptop")]: {
    fontSize: "22px",
  },
  [theme.breakpoints.up("desktop")]: {
    fontSize: "24px",
  },
};

export default theme;
