import React from 'react';
import {Avatar, Box, Button, Card, CardContent, CardHeader, Divider, IconButton, Typography} from "@mui/material";
import MenuIcon from "@material-ui/icons/Menu";
import {Circle, UploadFileOutlined} from "@mui/icons-material";
import CloudIcon from '@mui/icons-material/CloudOutlined';
import {Image, MailOutline} from "@material-ui/icons";
import {useForm} from "react-hook-form";

import {SolidButton} from "../../ui/SolidButton";
import {Chip} from "../../ui/Chip";
import InputField from "../InputField/InputField";
import leftHalfCloud from "../../assets/half_cloud_left.svg"
import rightHalfCloud from "../../assets/half_cloud_right.svg"

const ProfileAndPlanForAdmin = () => {

    const {
        control,
        handleSubmit,
        formState: {isSubmitting, isValid},
        getValues
    } = useForm({
        defaultValues: {
            name: "Shubham Nikam",
            email: "snikam@whileone.in",
            organization_name: "Whileone TechSoft",
            new_password: '',
            confirm_new_password: '',
        },
        mode: 'onChange',
    });

    const onSubmit = (data) => {
        console.log(data);
    }

    return (
        <Box style={{display: 'grid', gridTemplateColumns: '3fr 1fr'}}>
            <Box style={{marginRight: '20px'}}>
                <Box sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 2fr',
                    borderRadius: '5px',
                    gap: '20px',
                }}>
                    <Card style={{
                        padding: '30px',
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                    }}>
                        <Card sx={{maxWidth: 328, border: '2px solid #0078d4', borderRadius: 4}}>
                            <CardHeader title="Starter Plan" sx={{textAlign: 'center', paddingX: 4}}/>
                            <CardContent sx={{textAlign: 'center', paddingTop: 0, paddingX: 4}}>
                                <Divider sx={{marginBottom: 4}}/>
                                <Typography variant="h4">$99/m</Typography>
                                <Typography color="primary" fontWeight="bold">Upto 5K USD Monthly Spend</Typography>
                                <Typography fontWeight="bold">Essentials to jump start your cloud cost
                                    optimization</Typography>
                                <Divider sx={{marginY: 4}}/>
                                <SolidButton>Change Plan</SolidButton>
                            </CardContent>
                        </Card>
                    </Card>
                    <Card style={{
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                    }}>
                        <Box style={{display: 'flex', justifyContent: 'space-between', padding: '0 20px'}}>
                            <CardHeader title="Transaction History" sx={{textAlign: 'center'}}/>
                            <Box style={{display: 'flex', gap: 2}}>
                                <IconButton
                                    aria-label="Menu"
                                >
                                    <UploadFileOutlined/>
                                </IconButton>
                                <IconButton
                                    aria-label="Menu"
                                >
                                    <MailOutline/>
                                </IconButton>
                            </Box>
                        </Box>
                        <CardContent sx={{paddingTop: 0, paddingX: 4}}>
                            <Box maxHeight="300px" style={{overflowY: 'auto', marginBottom: '20px', padding: '0 10px'}}>
                                <Card
                                    sx={{
                                        borderRadius: 5,
                                        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
                                        marginY: '10px'
                                    }}>
                                    <Box
                                        sx={{
                                            boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
                                            padding: '10px 10px 10px 44px'
                                        }}>
                                        <Typography variant="h5">Evaluate Plan</Typography>
                                    </Box>
                                    <CardContent>
                                        <Box display="flex" justifyContent="space-between" alignItems="center">
                                            <Box display="flex" gap={2} alignItems="center">
                                                <Circle color="primary" size={30}/>
                                                <Box>
                                                    <Typography fontWeight="bold">
                                                        Alisha B
                                                    </Typography>
                                                    <Typography component="p">
                                                        3rd, Nov 2024 at 5:30pm
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box display="flex" gap={2}>
                                                <MenuIcon/>
                                                <Typography fontWeight="bold">
                                                    $25
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </CardContent>
                                </Card>
                                <Card
                                    sx={{
                                        borderRadius: 5,
                                        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
                                        marginY: '10px'
                                    }}>
                                    <Box
                                        sx={{
                                            boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
                                            padding: '10px 10px 10px 44px'
                                        }}>
                                        <Typography variant="h5">Evaluate Plan</Typography>
                                    </Box>
                                    <CardContent>
                                        <Box display="flex" justifyContent="space-between" alignItems="center">
                                            <Box display="flex" gap={2} alignItems="center">
                                                <Circle color="primary" size={30}/>
                                                <Box>
                                                    <Typography fontWeight="bold">
                                                        Alisha B
                                                    </Typography>
                                                    <Typography component="p">
                                                        3rd, Nov 2024 at 5:30pm
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box display="flex" gap={2}>
                                                <MenuIcon/>
                                                <Typography fontWeight="bold">
                                                    $25
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </CardContent>
                                </Card>
                                <Card
                                    sx={{
                                        borderRadius: 5,
                                        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
                                        marginY: '10px'
                                    }}>
                                    <Box
                                        sx={{
                                            boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
                                            padding: '10px 10px 10px 44px'
                                        }}>
                                        <Typography variant="h5">Evaluate Plan</Typography>
                                    </Box>
                                    <CardContent>
                                        <Box display="flex" justifyContent="space-between" alignItems="center">
                                            <Box display="flex" gap={2} alignItems="center">
                                                <Circle color="primary" size={30}/>
                                                <Box>
                                                    <Typography fontWeight="bold">
                                                        Alisha B
                                                    </Typography>
                                                    <Typography component="p">
                                                        3rd, Nov 2024 at 5:30pm
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box display="flex" gap={2}>
                                                <MenuIcon/>
                                                <Typography fontWeight="bold">
                                                    $25
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </CardContent>
                                </Card>
                                <Card
                                    sx={{
                                        borderRadius: 5,
                                        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
                                        marginY: '10px'
                                    }}>
                                    <Box
                                        sx={{
                                            boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
                                            padding: '10px 10px 10px 44px'
                                        }}>
                                        <Typography variant="h5">Evaluate Plan</Typography>
                                    </Box>
                                    <CardContent>
                                        <Box display="flex" justifyContent="space-between" alignItems="center">
                                            <Box display="flex" gap={2} alignItems="center">
                                                <Circle color="primary" size={30}/>
                                                <Box>
                                                    <Typography fontWeight="bold">
                                                        Alisha B
                                                    </Typography>
                                                    <Typography component="p">
                                                        3rd, Nov 2024 at 5:30pm
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box display="flex" gap={2}>
                                                <MenuIcon/>
                                                <Typography fontWeight="bold">
                                                    $25
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </CardContent>
                                </Card>
                                <Card
                                    sx={{
                                        borderRadius: 5,
                                        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
                                        marginY: '10px'
                                    }}>
                                    <Box
                                        sx={{
                                            boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
                                            padding: '10px 10px 10px 44px'
                                        }}>
                                        <Typography variant="h5">Evaluate Plan</Typography>
                                    </Box>
                                    <CardContent>
                                        <Box display="flex" justifyContent="space-between" alignItems="center">
                                            <Box display="flex" gap={2} alignItems="center">
                                                <Circle color="primary" size={30}/>
                                                <Box>
                                                    <Typography fontWeight="bold">
                                                        Alisha B
                                                    </Typography>
                                                    <Typography component="p">
                                                        3rd, Nov 2024 at 5:30pm
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box display="flex" gap={2}>
                                                <MenuIcon/>
                                                <Typography fontWeight="bold">
                                                    $25
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </CardContent>
                                </Card>
                                <Card
                                    sx={{
                                        borderRadius: 5,
                                        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
                                        marginY: '10px'
                                    }}>
                                    <Box
                                        sx={{
                                            boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
                                            padding: '10px 10px 10px 44px'
                                        }}>
                                        <Typography variant="h5">Evaluate Plan</Typography>
                                    </Box>
                                    <CardContent>
                                        <Box display="flex" justifyContent="space-between" alignItems="center">
                                            <Box display="flex" gap={2} alignItems="center">
                                                <Circle color="primary" size={30}/>
                                                <Box>
                                                    <Typography fontWeight="bold">
                                                        Alisha B
                                                    </Typography>
                                                    <Typography component="p">
                                                        3rd, Nov 2024 at 5:30pm
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box display="flex" gap={2}>
                                                <MenuIcon/>
                                                <Typography fontWeight="bold">
                                                    $25
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
                <Box sx={{
                    display: 'grid',
                    gridTemplateColumns: '2fr 1fr',
                    marginTop: '10px',
                    gap: '20px',
                    borderRadius: '5px',
                }}>
                    <Card style={{boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)', borderRadius: '5px',}}>
                        <CardHeader title="Included in your plan"/>
                        <CardContent>
                            <Box sx={{display: "grid", gridTemplateColumns: '1fr 1fr 1fr', gap: 2}}>
                                <Box sx={{display: "flex", gap: 2}}>
                                    <CloudIcon style={{
                                        stroke: "transparent",
                                        color: "#0090D1"
                                    }}/>
                                    <Box>
                                        <Typography fontWeight="bold">Budget: <Typography
                                            fontWeight="normal" component={"span"}>Up to
                                            $5k/month with a 48 hour</Typography></Typography>
                                    </Box>
                                </Box>
                                <Box sx={{display: "flex", gap: 2}}>
                                    <CloudIcon style={{
                                        stroke: "transparent",
                                        color: "#0090D1"
                                    }}/>
                                    <Box>
                                        <Typography fontWeight="bold">Usage Analytics: <Typography
                                            fontWeight="normal" component={"span"}>Get an overview of the top 5 cost
                                            drivers
                                            impacting your spending</Typography></Typography>
                                    </Box>
                                </Box>
                                <Box sx={{display: "flex", gap: 2}}>
                                    <CloudIcon style={{
                                        stroke: "transparent",
                                        color: "#0090D1"
                                    }}/>
                                    <Box>
                                        <Typography fontWeight="bold">Single User Access<Typography
                                            fontWeight="normal" component={"span"}></Typography></Typography>
                                    </Box>
                                </Box>
                                <Box sx={{display: "flex", gap: 2}}>
                                    <CloudIcon style={{
                                        stroke: "transparent",
                                        color: "#0090D1"
                                    }}/>
                                    <Box>
                                        <Typography fontWeight="bold">Automated Reporting <Typography
                                            fontWeight="normal" component={"span"}>Receive a weekly email report with
                                            cost
                                            breakdowns and CSV export for easy analysis.</Typography></Typography>
                                    </Box>
                                </Box>
                                <Box sx={{display: "flex", gap: 2}}>
                                    <CloudIcon style={{
                                        stroke: "transparent",
                                        color: "#0090D1"
                                    }}/>
                                    <Box>
                                        <Typography fontWeight="bold">Resource Optimization: <Typography
                                            fontWeight="normal" component={"span"}>Actionable
                                            recommendations</Typography></Typography>
                                    </Box>
                                </Box>
                                <Box sx={{display: "flex", gap: 2}}>
                                    <CloudIcon style={{
                                        stroke: "transparent",
                                        color: "#0090D1"
                                    }}/>
                                    <Box>
                                        <Typography fontWeight="bold">Add 1 API Key<Typography
                                            fontWeight="normal" component={"span"}></Typography></Typography>
                                    </Box>
                                </Box>
                                <Box sx={{display: "flex", gap: 2}}>
                                    <CloudIcon style={{
                                        stroke: "transparent",
                                        color: "#0090D1"
                                    }}/>
                                    <Box>
                                        <Typography fontWeight="bold">Single Cloud Support<Typography
                                            fontWeight="normal" component={"span"}></Typography></Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                    <Card style={{
                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                        borderRadius: '5px',
                        maxHeight: "400px"
                    }}>
                        <Box
                            style={{display: 'grid', gridTemplateRows: '1fr 2.5fr 60px'}}>
                            <Box>
                                <Box sx={{textAlign: 'center', paddingY: 4}}>
                                    <Typography variant="h5">Recommended Plans</Typography>
                                </Box>
                                <Divider/>
                            </Box>
                            <CardContent style={{position: 'relative'}}>
                                <Box style={{
                                    display: 'grid',
                                    placeItems: 'center',
                                    height: '100%',
                                    padding: "20px",
                                }}>
                                    <img src={rightHalfCloud} alt="" width="64px" height="56px"
                                         style={{position: "absolute", left: 0, bottom: -10}}/>
                                    <img src={leftHalfCloud} alt="" width="64px" height="56px"
                                         style={{position: "absolute", top: 0, right: 0}}/>
                                    <Typography variant="h4" style={{color: 'green', textAlign: 'center'}}
                                                fontWeight="bold">Save $110 with
                                        YEARLY
                                        plan</Typography>
                                </Box>
                            </CardContent>
                            <Box style={{background: "white", position: 'relative', zIndex: 10}}>
                                <Divider/>
                                <Box style={{display: 'grid', placeItems: 'center', height: '100%'}}>
                                    <Button>Explore &nbsp;&nbsp;&nbsp;&nbsp;> </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Card>
                </Box>
            </Box>
            <Box>
                <Box style={{display: "flex", justifyContent: 'space-between', gap: '20px'}}>
                    <Avatar src="" alt="profile avatar" sx={{width: 80, height: 80}}/>
                    <Box textAlign="right">
                        <Typography fontWeight="bold">$3/Month</Typography>
                        <Typography fontWeight="bold">Next payment on 5th Jan 2024</Typography>
                    </Box>
                </Box>


                <Box style={{display: "flex", gap: "10px", alignItems: "center", margin: "20px 0 40px 0"}}>
                    <Typography fontWeight="bold">Hi, User Name</Typography>
                    <Chip label="Admin" sx={{fontWeight: "bold", backgroundColor: "rgba(33, 150, 243, 0.3)"}}/>
                </Box>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box style={{display: "grid", gap: "20px"}}>
                        <InputField
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'The User Name field is required.',
                                },
                            }}
                            name="name"
                            placeholder="User Name"
                            label="User Name"
                        />
                        <InputField
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'The email field is required.',
                                },
                                pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: 'Entered value does not match email format',
                                },
                            }}
                            name="email"
                            placeholder="Email"
                            label="User Email ID"
                            type="email"
                        />
                        <InputField
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'The Organization Name field is required.',
                                },
                            }}
                            name="organization_name"
                            placeholder="Organization Name"
                            label="Organization Name"
                            type="text"
                        />
                        <InputField
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'The New Password field is required.',
                                },
                            }}
                            name="new_password"
                            placeholder="New Password"
                            label="New Password"
                            type="password"
                        />
                        <InputField
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'The Confirm New Password field is required.',
                                },
                                validate: (value) => {
                                    const password = getValues('new_password');
                                    return password === value || 'New Password and Confirm Password should match!';
                                },
                            }}
                            name="confirm_new_password"
                            placeholder="Confirm New Password"
                            label="Confirm New Password"
                            type="text"
                        />
                    </Box>
                    <Box style={{display: "flex", gap: "20px", justifyContent: "center", marginTop: "40px"}}>
                        <SolidButton
                            style={{
                                padding: "4px 24px",
                                border: !isValid || isSubmitting ? 'rgba(0, 0, 0, 0.12)' : '1px solid #2196f3'
                            }}
                            type="submit" disabled={!isValid || isSubmitting}>
                            Save</SolidButton>
                    </Box>
                </form>

            </Box>
        </Box>
    );
};

export default ProfileAndPlanForAdmin;
