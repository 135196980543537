import {
  Box,
  Divider,
  Typography,
  InputAdornment,
  TextField,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

import InputField from "../../InputField/InputField";
import SelectField from "../../Select/SelectField";
import { SolidButton } from "../../../ui/SolidButton";
import { TinyText } from "../../../ui/TinyText";
import TeamsSection from "./TeamsSection";

const TeamManagement = () => {
  const [inviteLinkValue, setInviteLinkValue] = useState("testurl");
  const [copied, setCopied] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    console.log("asdf", data);
  };

  const handleCopy = () => {
    const inputValue = document.querySelector('input[name="inviteLink"]').value;
    navigator.clipboard
      .writeText(inputValue)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 1000);
      })
      .catch((err) => {
        setCopied(false);
        console.error("Error in copying text: ", err);
      });
  };

  return (
    <Box>
      <TeamsSection />
      <Box>
        <Divider sx={{ my: "10px" }} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "20px 0",
            }}
          >
            <Box>
              <Typography variant="h5">Invite via Mail</Typography>
              <TinyText>
                Teammates will create a account via the received mail
              </TinyText>
            </Box>
            <Box style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <Box
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <Box>
                  <InputField
                    placeholder="Enter Teammate Email ID"
                    control={control}
                    label="Enter Teammate Email ID"
                    name="teammateEmail"
                    rules={{
                      required: "Email is required",
                    }}
                    size="small"
                  />
                </Box>
                <Box width="200px">
                  <SelectField
                    control={control}
                    label="User Role"
                    name="userRole"
                    placeholder="Select User Role"
                    options={[
                      { label: "Admin", value: "admin" },
                      { label: "Member", value: "member" },
                    ]}
                    size="small"
                    style={{ width: "100%" }}
                    rules={{
                      required: "User Role is required",
                    }}
                  />
                </Box>
              </Box>
              <SolidButton
                type="submit"
                style={{
                  width: "100px",
                  height: "40px",
                  marginBottom:
                    errors.teammateEmail || errors.userRole ? "20px" : "0",
                }}
              >
                Invite
              </SolidButton>
            </Box>
          </Box>
        </form>
        <Divider sx={{ my: "10px" }} />
      </Box>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "20px 0",
            }}
          >
            <Box>
              <Typography variant="h5">Invite via Link</Typography>
              <TinyText>
                Share the generated link to invite the teammates
              </TinyText>
            </Box>
            <Box style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <TextField
                name="inviteLink"
                id="inviteLink"
                value={inviteLinkValue}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      onClick={handleCopy}
                      position="end"
                      style={{ cursor: "pointer" }}
                    >
                      {copied ? "Copied" : <ContentCopyIcon />}
                    </InputAdornment>
                  ),
                }}
              />
              <SolidButton
                type="submit"
                style={{
                  padding: "8px 20px",
                  marginBottom:
                    errors.teammateEmail || errors.userRole ? "20px" : "0",
                }}
              >
                Generate Link
              </SolidButton>
            </Box>
          </Box>
        </form>
        <Divider sx={{ my: "10px" }} />
      </Box>
    </Box>
  );
};

export default TeamManagement;
