import React from "react";
import { Box as BaseBox } from "@material-ui/core";
import Background from "../../assets/background_image.svg";
import styled from "@emotion/styled/macro";

const Box = styled(BaseBox)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(${Background}) no-repeat center center;
  background-size: cover;
  color: white;
  text-align: center;
  padding: 32px;
`;

export const LeftPane = () => {
  return (
    <Box>
      <h1>
        Optimizing costs isn't about spending less; it's about spending smarter
        to unlock greater value and sustainable success.
      </h1>
    </Box>
  );
};
