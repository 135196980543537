import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import CloudIcon from "@material-ui/icons/Cloud";
import DoneIcon from "@material-ui/icons/Done";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import history from "../../utils/history";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Autocomplete,
  Box,
  CircularProgress,
  Alert,
  Typography,
  Button,
  Divider,
  Dialog,
  DialogContent,
} from "@mui/material";
import { providers } from "../../mock/mock";
import useCloudProviderToken from "../../hooks/useCloudProviderToken";
import { SolidButton } from "../../ui/SolidButton";
import ConfirmDialog from "./ConfirmDialog";
import {
  CLOUD_PROVIDER_GCP,
  CLOUD_PROVIDER_AWS,
  CLOUD_PROVIDER_AZURE,
  CLOUD_PROVIDER_OCI,
  STORAGE_HAS_SHOWN_GUIDED_TOUR,
} from "../../utils/constants";
import add_token_bg from "../../assets/addtokenbg.png";
import {
  DEFAULT_201_CREATED_MESSAGE,
  DEFAULT_CLOUD_PROVIDER,
  DEFAULT_DELETED_MESSAGE,
  DEFAULT_MESSAGE_EMPTY,
  DEFAULT_MESSAGE_ERROR,
  DEFAULT_UPDATED_MESSAGE,
} from "../../config/config";
import { ROUTE_DASHBOARD } from "../../routers/routes";
import CheckIcon from "@mui/icons-material/Check";
import ConfirmationDialog from "../../ui/ConfirmationDialog";
import ListIcon from "@mui/icons-material/List";

import LogoAWS from "../../assets/cloud_provider_aws.svg";
import LogoAzure from "../../assets/cloud_provider_azure.svg";
import LogoGCP from "../../assets/cloud_provider_gcp.svg";
import LogoOci from "../../assets/cloud_provider_oracle.svg";
import { useTheme } from "@material-ui/core/styles";
import { H4 } from "../../ui/H4";
import CloudProviderCard from "../CloudProviderCard";
import FileUpload from "../FileUpload";
import AddTokenInstructionModal from "./AddTokenInstructionModal";
import { getLocalStorage, setLocalStorage } from "../../utils/storageUtil";
import AWSCloudFormation from "./AWSCloudFormation";

const NotificationBox = ({ message, onClose }) => {
  return (
    <Alert
      onClose={onClose}
      icon={<CheckIcon fontSize="inherit" />}
      severity="success"
    >
      {message}
    </Alert>
  );
};

const EmptyStateView = ({ onClick }) => {
  return (
    <Paper
      elevation={3}
      sx={{
        padding: 4,
        textAlign: "center",
        width: "100%",
        margin: "auto",
        mt: 4,
      }}
    >
      <Box>
        <ListIcon sx={{ fontSize: 150, color: "grey.500" }} />
      </Box>
      <H4>Woops! There is nothing to show right now.</H4>
      <Typography variant="body1" color="textSecondary" sx={{ mt: 1 }}>
        Please add a token credentials for the selected cloud provider
      </Typography>
      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 3 }}
        onClick={onClick}
      >
        Add Token
      </Button>
    </Paper>
  );
};

function AddCloudProvider() {
  // TODO: make selectCloudProvider as selectedCloudProvider
  const [selectCloudProvider, setSelectedCloudProvider] = useState(
    DEFAULT_CLOUD_PROVIDER
  );
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [validationError, setValidationError] = useState(null);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [formData, setFormData] = useState({});
  const [instructionModalOpen, setInstructionModalOpen] = useState(false);
  const [hasShownGuideTour, setHasShownGuideTour] = useState(
    getLocalStorage(STORAGE_HAS_SHOWN_GUIDED_TOUR) ?? false
  );

  const handleGuidTourClose = () => {
    setHasShownGuideTour(true);
    setLocalStorage(STORAGE_HAS_SHOWN_GUIDED_TOUR, true);
  };

  const theme = useTheme();

  const {
    fetchTokenList,
    insertToken,
    updateToken,
    deleteToken,
    isLoading,
    getAwsRegions,
    awsRegions,
  } = useCloudProviderToken();

  const tokenList = useSelector(
    (state) => state.cloudProviderTokenReducer?.data?.data || []
  );

  const initialTableData = providers.reduce((acc, current) => {
    acc[current.type] = [];
    return acc;
  }, {});

  const [tableData, setTableData] = useState(initialTableData);

  useEffect(() => {
    if (selectCloudProvider !== CLOUD_PROVIDER_AWS) {
      setTableData((prevData) => ({
        ...prevData,
        [selectCloudProvider]: tokenList,
      }));
    }
  }, [tokenList]);

  useEffect(() => {
    getAwsRegions();
  }, []);

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        setMessage(null);
      }, 4000);
    }
  }, [message]);

  const handleClickOpen = (e) => {
    setOpen(true);
    setFormData({});
    setSelectedRegion({});
    setEditMode(false);
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteItemId(null);
    setValidationError(null);
  };

  const handleFormChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleGCPCredentialsFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "application/json") {
      setFormData({ ...formData, [e.target.name]: file });
      setValidationError(null);
    } else {
      setValidationError("Invalid file type, please upload JSON file only");
    }
  };

  const handleFormSubmit = async () => {
    if (Object.keys(formData).length == 0) {
      setValidationError("No credentials provided");
      return;
    }

    if (
      (selectCloudProvider == CLOUD_PROVIDER_AWS &&
        Object.keys(formData).length < 4) ||
      (selectCloudProvider == CLOUD_PROVIDER_AZURE &&
        Object.keys(formData).length < 6) ||
      (selectCloudProvider == CLOUD_PROVIDER_GCP &&
        Object.keys(formData).length < 2)
    ) {
      setValidationError("All fields are mandatory");
      return;
    }

    setValidationError(null);
    try {
      let result;

      if (editMode) {
        result = await updateToken(selectCloudProvider, formData.id, formData);
        setMessage(DEFAULT_UPDATED_MESSAGE);
      } else {
        result = await insertToken(selectCloudProvider, formData);
        setMessage(DEFAULT_201_CREATED_MESSAGE);
      }

      if (result && result.data && result.data.id) {
        fetchTableData(selectCloudProvider);
        handleClose(); // Close the modal after successful submission
      }
    } catch (error) {
      setValidationError(error.message || DEFAULT_MESSAGE_ERROR);
    }
  };

  const fetchTableData = async (cloudType) => {
    try {
      if (cloudType !== CLOUD_PROVIDER_AWS) {
        await fetchTokenList(cloudType);
      }
    } catch (error) {
      // Handle error (e.g., show a notification or error message)
    }
  };

  useEffect(() => {
    fetchTableData(selectCloudProvider);
  }, [selectCloudProvider]);

  const renderFields = () => {
    switch (selectCloudProvider) {
      case CLOUD_PROVIDER_AWS:
        return (
          <>
            <TextField
              name="key_label"
              label="Key Label"
              fullWidth
              required
              margin="dense"
              onChange={handleFormChange}
              value={formData.key_label || ""}
            />
            <TextField
              name="aws_access_key_id"
              label="AWS Access Key ID"
              fullWidth
              required
              margin="dense"
              onChange={handleFormChange}
              value={formData.aws_access_key_id || ""}
            />
            <TextField
              name="aws_secret_access_key"
              label="AWS Secret Access Key"
              fullWidth
              required
              margin="dense"
              onChange={handleFormChange}
              value={formData.aws_secret_access_key || ""}
            />
            {/*  Implemented Autocomplete search */}
            {awsRegions ? (
              <Autocomplete
                options={awsRegions}
                getOptionLabel={(option) => option.name}
                value={awsRegions?.find(
                  (region) => region.id === selectedRegion
                )}
                onChange={(event, newValue) => {
                  setSelectedRegion(newValue?.id);
                  handleFormChange({
                    target: {
                      name: "region",
                      value: newValue ? newValue?.id : "",
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="region"
                    label="Region"
                    fullWidth
                    required
                    margin="dense"
                  />
                )}
              />
            ) : (
              DEFAULT_MESSAGE_EMPTY
            )}
          </>
        );
      case CLOUD_PROVIDER_AZURE:
        return (
          <>
            <TextField
              name="key_label"
              label="Key Label"
              fullWidth
              required
              margin="dense"
              onChange={handleFormChange}
              value={formData.key_label || ""}
            />
            <TextField
              name="tenant_id"
              label="Tenant ID"
              fullWidth
              required
              margin="dense"
              onChange={handleFormChange}
              value={formData.tenant_id || ""}
            />
            <TextField
              name="client_id"
              label="Client ID"
              fullWidth
              required
              margin="dense"
              onChange={handleFormChange}
              value={formData.client_id || ""}
            />
            <TextField
              name="client_secret"
              label="Client Secret"
              fullWidth
              required
              margin="dense"
              onChange={handleFormChange}
              value={formData.client_secret || ""}
            />
            <TextField
              name="subscription_id"
              label="Subscription ID"
              fullWidth
              required
              margin="dense"
              onChange={handleFormChange}
              value={formData.subscription_id || ""}
            />
            <TextField
              name="resource_group_name"
              label="Resource Group Name"
              fullWidth
              required
              margin="dense"
              onChange={handleFormChange}
              value={formData.resource_group_name || ""}
            />
          </>
        );
      case CLOUD_PROVIDER_GCP:
        return (
          <>
            <TextField
              name="key_label"
              label="Key Label"
              fullWidth
              required
              margin="dense"
              onChange={handleFormChange}
              value={formData.key_label || ""}
            />
            <TextField
              name="project_id"
              label="Project ID"
              fullWidth
              required
              margin="dense"
              onChange={handleFormChange}
              value={formData.project_id || ""}
            />
            <TextField
              name="dataset_id"
              label="DataSet ID"
              fullWidth
              required
              margin="dense"
              onChange={handleFormChange}
              value={formData.dataset_id || ""}
            />
            <TextField
              name="table_id"
              label="Table ID"
              fullWidth
              required
              margin="dense"
              onChange={handleFormChange}
              value={formData.table_id || ""}
            />
            <TextField
              name="credential_file"
              label="GCP Credentials File"
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
              margin="dense"
              type="file"
              inputProps={{
                accept: ".json",
              }}
              onChange={handleGCPCredentialsFileChange}
              // value={formData.gcp_credentials_file.name || ""}
            />
          </>
        );
      case CLOUD_PROVIDER_OCI:
        return (
          <>
            {/* <TextField
              name="key_label"
              label="Key Label"
              fullWidth
              required
              margin="dense"
              onChange={handleFormChange}
              value={formData?.key_label || ""}
            />
            <TextField
              name="config_file"
              label="Config File ID"
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
              margin="dense"
              type="file"
              onChange={handleFormChange}
              inputProps={{
                style: { textOverflow: 'ellipsis' },
              }}
            />
            <TextField
              name="pem_file"
              label="Pem File ID"
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
              margin="dense"
              type="file"
              accept=".json"
              onChange={handleFormChange}
              // value={formData?.pem_file || ""}
            /> */}
            <FileUpload
              selectCloudProvider={selectCloudProvider}
              tokenId={formData.id}
              editMode={editMode}
              key_label={formData.key_label}
              onClose={handleClose} // Pass the close handler
            />
          </>
        );

      default: {
        return null;
      }
    }
  };

  const renderTable = () => {
    const columns = {
      aws: [
        "Sr No",
        "Key Label",
        "AWS Access Key ID",
        "AWS Secret Access Key",
        "Region",
        // "Accounts",
        "Actions",
      ],
      azure: [
        "Sr No",
        "Key Label",
        "Tenant ID",
        "Client ID",
        "Client Secret",
        "Subscription ID",
        "Resource Group Name",
        "Actions",
      ],
      gcp: [
        "Sr No",
        "Key Label",
        "Project ID",
        "GCP Credentials File",
        "Actions",
      ],
      oci: ["Sr No", "Key Label", "Config File ID", "Pem File ID", "Actions"],
    };

    return (
      <Box
        sx={{
          my: 2,
        }}
      >
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {columns[selectCloudProvider].map((column) => (
                  <TableCell sx={{ fontWeight: "bold" }} key={column}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData[selectCloudProvider] &&
                Array.isArray(tableData[selectCloudProvider]) &&
                tableData[selectCloudProvider].map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    {selectCloudProvider === CLOUD_PROVIDER_AWS && (
                      <>
                        <TableCell>
                          {row.key_label ? row.key_label : "NA"}
                        </TableCell>
                        <TableCell>{row.aws_access_key_id}</TableCell>
                        <TableCell>{row.aws_secret_access_key}</TableCell>
                        <TableCell>
                          {row.region ? row.region?.name : "NA"}
                        </TableCell>
                        {/* <TableCell sx={{ maxWidth: "300px", overflow: "auto" }}>
                          {row.accounts && row.accounts.length > 0
                            ? row.accounts.map((account, index) => (
                                <Chip
                                  key={index}
                                  label={account.name}
                                  sx={{ margin: "2px" }} // Adjust the margin as needed
                                />
                              ))
                            : "NA"}
                        </TableCell> */}
                      </>
                    )}
                    {selectCloudProvider === CLOUD_PROVIDER_AZURE && (
                      <>
                        <TableCell>{row.key_label}</TableCell>
                        <TableCell>{row.tenant_id}</TableCell>
                        <TableCell>{row.client_id}</TableCell>
                        <TableCell>{row.client_secret}</TableCell>
                        <TableCell>{row.subscription_id}</TableCell>
                        <TableCell>{row.resource_group_name}</TableCell>
                      </>
                    )}
                    {selectCloudProvider === CLOUD_PROVIDER_GCP && (
                      <>
                        <TableCell>
                          {row.key_label ? row.key_label : "NA"}
                        </TableCell>
                        <TableCell>{row.project_id}</TableCell>
                        <TableCell>
                          {row.credential_file.length > 8
                            ? `${row.credential_file.slice(0, 8)}...`
                            : row.credential_file}
                          <SaveAltIcon
                            variant="outlined"
                            color="primary"
                            onClick={() =>
                              handleDownload({
                                config_file: row.credential_file,
                              })
                            }
                            style={{
                              marginLeft: 8,
                              marginTop: 8,
                              marginBottom: -4,
                              cursor: "pointer",
                            }}
                          />
                        </TableCell>
                        {/* <TableCell>
                          {row.user_groups && row.user_groups.length > 0
                            ? row.user_groups.toString()
                            : "NA"}
                        </TableCell> */}
                      </>
                    )}
                    {selectCloudProvider === CLOUD_PROVIDER_OCI && (
                      <>
                        <TableCell>
                          {row.key_label ? row.key_label : "NA"}
                        </TableCell>
                        <TableCell>
                          {row.config_file_name.length > 8
                            ? `${row.config_file_name.slice(0, 8)}...`
                            : row.config_file_name}
                          {/* <VisibilityIcon
                            variant="outlined"
                            color="primary"
                            onClick={() => handleDownload(row)}
                            style={{
                              marginLeft: 8,
                              marginTop: 8,
                              marginBottom: -6,
                              cursor: 'pointer',
                            }}
                          /> */}
                          <SaveAltIcon
                            variant="outlined"
                            color="primary"
                            onClick={() => handleDownload(row)}
                            style={{
                              marginLeft: 8,
                              marginTop: 8,
                              marginBottom: -4,
                              cursor: "pointer",
                            }}
                          />
                        </TableCell>

                        <TableCell>
                          {row.pem_file_name.length > 8
                            ? `${row.pem_file_name.slice(0, 8)}...`
                            : row.pem_file_name}
                          {/* <VisibilityIcon
                            variant="outllined"
                            color="primary"
                            onClick={() => handleDownload(row)}
                            style={{
                              marginLeft: 8,
                              marginTop: 8,
                              marginBottom: -6,
                              cursor: 'pointer',
                            }}
                          /> */}
                          <SaveAltIcon
                            variant="outllined"
                            color="primary"
                            onClick={() => handleDownloadForPem(row)}
                            style={{
                              marginLeft: 8,
                              marginTop: 8,
                              marginBottom: -4,
                              cursor: "pointer",
                            }}
                          />
                        </TableCell>
                      </>
                    )}
                    <TableCell>
                      <SolidButton
                        sx={{ margin: "2px" }}
                        variant="contained"
                        color="primary"
                        onClick={() => handleEdit(row)}
                      >
                        Edit
                      </SolidButton>
                      <SolidButton
                        sx={{ margin: "2px" }}
                        variant="contained"
                        color="primary"
                        onClick={() => setDeleteItemId(row.id)}
                      >
                        Delete
                      </SolidButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  const handleDownload = (row) => {
    const url = row.config_file; // Replace with your file URL
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "config_file.pdf"); // Replace with your file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadForPem = (row) => {
    const url = row.pem_file; // Replace with your file URL
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "pem_file.pdf"); // Replace with your file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleEdit = (row) => {
    setSelectedRegion(row?.region?.id);
    const rowData = { ...row };
    if (selectCloudProvider === CLOUD_PROVIDER_AWS && rowData.region) {
      rowData.region = rowData.region.id;
    }
    setFormData(rowData);
    setEditMode(true);
    setOpen(true);
  };

  const handleDelete = async () => {
    if (deleteItemId) {
      await deleteToken(selectCloudProvider, deleteItemId);
      setMessage(DEFAULT_DELETED_MESSAGE);
      fetchTableData(selectCloudProvider);
      setDeleteItemId(null);
    }
  };

  const handleCardClick = (cloudProvider) => {
    setSelectedCloudProvider(cloudProvider);
    fetchTableData(cloudProvider);
  };

  return (
    <>
      <Dialog
        maxWidth="lg"
        fullWidth
        open={!hasShownGuideTour}
        keepMounted
        onClose={() => handleGuidTourClose()}
        aria-describedby="alert-dialog-slide-description"
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            margin: "24px 24px 0 24px",
          }}
        >
          <Button variant="contained" onClick={() => handleGuidTourClose()}>
            Skip Tour
          </Button>
        </Box>
        <DialogContent>
          <div
            style={{
              position: "relative",
              "box-sizing": "content-box",
              "max-height": "80vh",
              "max-height": "80svh",
              height: "100vh",
              width: "100%",
              "aspect-ratio": 1.7777777777777777,
              // padding: "0 24px",
            }}
          >
            <iframe
              src="https://app.supademo.com/embed/cm3zpkh0o0tl210a5mspv48ud?embed_v=2"
              loading="lazy"
              title="New Demo"
              allow="clipboard-write"
              frameborder="0"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowfullscreen
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            ></iframe>
          </div>
        </DialogContent>
      </Dialog>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          gap: 10,
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${add_token_bg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: 200,
            padding: 3,
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            alignItems: "left",
            justifyContent: "center",
            fontWeight: 200,
            color: "white",
            fontSize: 40,
          }}
        >
          {" "}
          Get started today to optimize your <br /> cloud costs and maximize
          your savings!
        </Box>

        <Divider sx={{ marginY: 4 }} />
        <Box sx={{ display: "flex", marginLeft: "9px", alignItems: "center" }}>
          <CloudIcon fontSize="large" color="primary" marginLeft="6px" />
          <Typography variant="h4" sx={{ ml: 2, left: "30px" }}>
            Choose your platform
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            my: 2,
          }}
        >
          <Box display="flex" gap={2} justifyContent="center" mb={4}>
            <CloudProviderCard
              logo={LogoAWS}
              alt="AWS Logo"
              isSelected={selectCloudProvider === CLOUD_PROVIDER_AWS}
              onClick={() => handleCardClick(CLOUD_PROVIDER_AWS)}
              theme={theme}
            />
            <CloudProviderCard
              logo={LogoAzure}
              alt="Azure Logo"
              isSelected={selectCloudProvider === CLOUD_PROVIDER_AZURE}
              onClick={() => handleCardClick(CLOUD_PROVIDER_AZURE)}
              theme={theme}
            />
            <CloudProviderCard
              logo={LogoGCP}
              alt="GCP Logo"
              isSelected={selectCloudProvider === CLOUD_PROVIDER_GCP}
              onClick={() => handleCardClick(CLOUD_PROVIDER_GCP)}
              theme={theme}
            />
            <CloudProviderCard
              logo={LogoOci}
              alt="Oracle Logo"
              isSelected={selectCloudProvider === CLOUD_PROVIDER_OCI}
              onClick={() => handleCardClick(CLOUD_PROVIDER_OCI)}
              theme={theme}
            />
          </Box>
        </Box>

        <Divider sx={{ marginY: 4 }} />
        {selectCloudProvider === CLOUD_PROVIDER_AWS ? (
          <AWSCloudFormation />
        ) : (
          <Box
            sx={{ display: "flex", marginLeft: "9px", alignItems: "center" }}
          >
            <DoneIcon fontSize="large" color="primary" marginLeft="6px" />
            <Typography variant="h4" sx={{ ml: 2, mr: 4, left: "30px" }}>
              Optimize your cloud cost & maximize your savings today !{" "}
            </Typography>
            <SolidButton
              sx={{
                height: "100%",
                Padding: "6px",
              }}
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
            >
              Add Token
            </SolidButton>
          </Box>
        )}

        <Divider sx={{ marginY: 2 }} />

        {selectCloudProvider === CLOUD_PROVIDER_AWS ? null : isLoading ? (
          <Box
            sx={{
              width: "100%",
              height: "500px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress
              sx={{
                width: "50%",
              }}
            />
          </Box>
        ) : (
          <>
            {message && (
              <>
                <NotificationBox
                  message={message}
                  onClose={() => {
                    setMessage(null);
                  }}
                />
              </>
            )}
            {tableData[selectCloudProvider].length == 0 ? (
              <EmptyStateView onClick={handleClickOpen} />
            ) : (
              renderTable()
            )}
          </>
        )}

        <ConfirmDialog
          error={validationError}
          open={open}
          handleClose={handleClose}
          selectedCloudProvider={selectCloudProvider}
          handleFormSubmit={handleFormSubmit}
          editMode={editMode}
          renderFields={renderFields()}
          handleInstructionClick={() => {
            setInstructionModalOpen(true);
          }}
        />
        <ConfirmationDialog
          open={!!deleteItemId}
          title={"Are you sure you want to delete?"}
          onClose={() => {
            setDeleteItemId(null);
          }}
          onNegativeBtnClick={() => {
            setDeleteItemId(null);
          }}
          onPositiveBtnClick={() => {
            handleDelete();
          }}
        />
        <AddTokenInstructionModal
          instructionModalOpen={instructionModalOpen}
          handleClose={() => setInstructionModalOpen(false)}
          selectedCloudProvider={selectCloudProvider}
        />
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
          onClick={() => {
            history.push(ROUTE_DASHBOARD);
          }}
        >
          Get Analysis
        </Button>
      </Box>
    </>
  );
}

export default AddCloudProvider;
