import {
  CLOUD_PROVIDER_ALL,
  CLOUD_PROVIDER_AWS,
  DURATION_KEY_LASTMONTH,
} from "../utils/constants";

export const apiPath = "api/";

const URL_SCHEME = process.env.REACT_APP_APP_SCHEME || "https";
export const APP_HOST = process.env.REACT_APP_API_HOST;
export const APP_PORT = process.env.REACT_APP_APP_PORT;
// export const HOST = `${APP_HOST}:${APP_PORT}/`;
export const HOST = `${APP_HOST}/`;

export const API_URL = `${URL_SCHEME}://${HOST}`;

/**
 * This constant especially is tied to a UI than a philosophy.
 * We are using it to decide how the UI is displayed
 */
export const DEFAULT_CLOUD_PROVIDER_SELECTOR = CLOUD_PROVIDER_ALL;

/**
 * Duration key which we are using to keep a default selection on number of
 * days/weeks/month in one of the parts of the UI.
 */
export const DEFAULT_DURATION_KEY = DURATION_KEY_LASTMONTH;

/**
 * Default cloud provider. In case when theres a drop down to select from a list of global cloud providers
 * or a multi-select-button/radio button, the default we will fallback on should be AWS.
 * This can be changed with unanimous decision within the team.
 */
export const DEFAULT_CLOUD_PROVIDER = CLOUD_PROVIDER_AWS;

export const DEFAULT_201_CREATED_MESSAGE = "Successfully Created!";
export const DEFAULT_UPDATED_MESSAGE = "Successfully Updated!";
export const DEFAULT_DELETED_MESSAGE = "Deleted Successfully!";
export const DEFAULT_MESSAGE_EMPTY = "No results found!";
export const DEFAULT_MESSAGE_ERROR = "Something went wrong!";
export const DEFAULT_MESSAGE_BACKEND_RESPONSE_BROKEN =
  "Backend API response broken. Server developers have been notified";

export const CACHED_DATA_STALE_TIME = 180000;
export const CACHED_DATA_CACHE_TIME = 180000;

export const IS_DEBUG = window.location.host.includes("localhost") && false;
