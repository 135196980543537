import React from "react";
import { useState } from "react";
import { Box, Tabs, Tab } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import ProfilePage from "./ProfilePage";
import AuditLogs from "./auditLogs/AuditLogs";
import TeamManagement from "./teamManagement/TeamManagement";

const TABS = {
  PROFILE_AND_PLAN: "profileAndPlan",
  AUDIT_LOGS: "auditLogs",
  TEAM_MANAGEMENT: "teamManagement",
};

const TAB_LABELS = {
  [TABS.PROFILE_AND_PLAN]: "Profile & Plan",
  [TABS.AUDIT_LOGS]: "Audit Logs",
  [TABS.TEAM_MANAGEMENT]: "Team Management",
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Index = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const isDisabled = (tab) => {
    return tab === TABS.TEAM_MANAGEMENT || tab === TABS.AUDIT_LOGS;
  };

  return (
    <>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="profile-tabs"
          style={{ padding: "0 0 20px 0" }}
        >
          {Object.entries(TABS).map(([key, value], index) => {
            return (
              <Tab
                label={<Box>{TAB_LABELS[value]}</Box>}
                icon={isDisabled(value) ? <LockOutlinedIcon /> : null}
                iconPosition="end"
                {...a11yProps(index)}
                key={key}
                disabled={isDisabled(value)}
                style={{
                  cursor: isDisabled(value) ? "not-allowed" : "pointer",
                }}
              />
            );
          })}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <ProfilePage />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <AuditLogs />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <TeamManagement />
      </CustomTabPanel>
    </>
  );
};

export default Index;
