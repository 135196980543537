import React from "react";
import { Typography, Box, Divider } from "@mui/material";
import { useTheme } from "@material-ui/core/styles";

import { formatCurrency } from "../../../utils/commonUtil";
import { Card } from "../../../ui/Card";
import {
  RECOMMENDATION_TYPE_CHANGING_ARCH,
  RECOMMENDATION_TYPE_DOWNSIZING_INSTANCE,
  RECOMMENDATION_TYPE_DOWNSIZING_INSTANCE_V2,
  RECOMMENDATION_TYPE_OPTIMAL,
  RECOMMENDATION_TYPE_OPTIMAL_V2,
  RECOMMENDATION_TYPE_TERMINATE_IDLE_VMS,
  RECOMMENDATION_TYPE_IDLE_V2,
  RECOMMENDATION_TYPE_CHANGING_ARCH_V2,
} from "../../../utils/constants";
import { Dot } from "../../../ui/Dot";

const ADDITIONAL_RECOMMENDATION_TYPE = "all";
const getSelectedBorder = (theme) => {
  return `2px solid #2196F3`;
};

const getItemStyle = (theme, isSelected) => {
  return {
    border: isSelected ? getSelectedBorder(theme) : "none",
  };
};

const getLabelForAction = (action) => {
  if (action === null) {
    return "Optimal";
  } else if (action === RECOMMENDATION_TYPE_DOWNSIZING_INSTANCE) {
    return "Downsize";
  } else if (action === RECOMMENDATION_TYPE_CHANGING_ARCH) {
    return "Change Architecture";
  } else if (action === RECOMMENDATION_TYPE_TERMINATE_IDLE_VMS) {
    return "Terminate";
  } else if (action === RECOMMENDATION_TYPE_OPTIMAL) {
    return "Optimal";
  } else {
    // console.error("Some unknonw action received", action);
    return "Invalid";
  }
};

const getColorForAction = (action) => {
  if (action === null) {
    return "black";
  } else if (action === RECOMMENDATION_TYPE_DOWNSIZING_INSTANCE) {
    return "#FFCC00";
  } else if (action === RECOMMENDATION_TYPE_CHANGING_ARCH) {
    return "#9747FF";
  } else if (action === RECOMMENDATION_TYPE_TERMINATE_IDLE_VMS) {
    return "#EF1F25";
  } else if (action === RECOMMENDATION_TYPE_OPTIMAL) {
    return "#358A01";
  } else {
    // console.error("Some unknonw action received", action);
    return "black";
  }
};

const getBorder = (action) => {
  // TODO: fix this after the backend fixes it.
  if (action === RECOMMENDATION_TYPE_DOWNSIZING_INSTANCE) {
    return "1px solid orange";
  } else if (action === RECOMMENDATION_TYPE_CHANGING_ARCH) {
    return "1px solid green";
  } else if (action === RECOMMENDATION_TYPE_TERMINATE_IDLE_VMS) {
    return "1px solid yellow";
  } else {
    return "1px solid yellow";
  }
};

const getBorderV2 = (action) => {
  if (action === RECOMMENDATION_TYPE_OPTIMAL_V2) {
    return "1px solid green";
  } else if (action === RECOMMENDATION_TYPE_DOWNSIZING_INSTANCE_V2) {
    return "1px solid yellow";
  } else if (action === RECOMMENDATION_TYPE_IDLE_V2) {
    return "1px solid red";
  } else {
    return "1px solid yellow";
  }
};

const VmCard = ({ vm, onClick, selectedVm }) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        padding: "10px",
        borderRadius: "8px",
        margin: "20px 2px",
        cursor: "pointer",
        gap: 2,
        display: "grid",
        gridTemplateColumns: "minmax(10ch, 1fr) 1fr",
        columnGap: "16px",
        rowGap: "10px",
        alignItems: "start",
        overflow: "hidden",
        wordWrap: "break-word",
        ...getItemStyle(theme, vm?.instance_id === selectedVm?.instance_id),
      }}
      key={vm.instance_id}
      onClick={() => onClick(vm)}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: 1,
          height: "100%",
        }}
      >
        <Box>
          <Typography variant="body" fontWeight="600">
            {vm.instance_name}
          </Typography>
          <Divider />
          <Typography variant="body" fontWeight="600">
            {vm.machine_type}{" "}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body">Price</Typography>
          <Typography variant="body2">{vm.cost_per_month?.str}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: 1,
          height: "100%",
        }}
      >
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              flexWrap: "wrap",
              overflow: "hidden",
              whiteSpace: "pre-wrap", // Ensure long content wraps correctly
              wordBreak: "break-word", // Break words if they are too long
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                border: vm.recommended_action
                  ? getBorderV2(vm.recommended_action)
                  : vm.percentage_saved >= 0
                  ? "1px solid green"
                  : "1px solid red",
                borderRadius: "8px",
                padding: "4px",
                width: "80%",
              }}
            >
              {/*  This is for insights view as in insights view, we don't have any recommended action */}
              {!vm.recommended_action && vm.percentage_saved === 0 ? (
                <Typography
                  variant="text"
                  fontWeight="600"
                  textAlign="center"
                  color={"#358A01"}
                >
                  Optimal
                </Typography>
              ) : (
                <>
                  <Typography
                    variant="text"
                    fontWeight="600"
                    textAlign="center"
                    color={
                      vm.recommended_action === RECOMMENDATION_TYPE_OPTIMAL_V2
                        ? "#358A01"
                        : "black"
                    }
                  >
                    {vm.recommended_action}
                  </Typography>
                  {vm.recommended_action !== RECOMMENDATION_TYPE_OPTIMAL_V2 ? (
                    <Typography
                      color={vm.percentage_saved >= 0 ? "green" : "red"}
                      variant="body"
                      fontWeight="600"
                      textAlign="center"
                    >
                      {vm.percentage_saved >= 0
                        ? `Save - ${vm.percentage_saved}%`
                        : `Value increased by - ${vm.percentage_saved * -1}%`}
                    </Typography>
                  ) : null}
                </>
              )}
            </Box>
          </Box>
          {/* <AlternateRecommendations vm={vm} /> */}
        </>
      </Box>
    </Card>
  );
};

export default VmCard;
