import { useQuery, useMutation } from "@tanstack/react-query";

import { parseNetworkErrorV2 } from "../utils/commonUtil";
import { fetch, store } from "../utils/httpUtil";
import {
  QUERY_KEY_CLOUD_KEYS,
  QUERY_KEY_CLOUD_SERVICES,
  QUERY_KEY_CLOUD_TYPES,
  QUERY_KEY_RECOMMENDATION_TYPES,
  QUERY_KEY_DASHBOARD_RECOMMENDATIONS,
  CLOUD_PROVIDER_AWS,
} from "../utils/constants";

const getDashBoardRecommendations = async (
  key_id = null,
  cloudProvider = null
) => {
  try {
    let url = `/dashboard/recommendations/`;
    if (key_id && cloudProvider) {
      url = `/dashboard/cloud-based-recommendations/?cloud_name=${cloudProvider}&key_id=${key_id}`;
    }
    const resp = await fetch(url);
    return resp.data.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw parsedError;
  }
};

export const useDashBoardRecommendationsV2 = (
  key_id = null,
  cloudProvider = null
) => {
  let queryKey = [QUERY_KEY_DASHBOARD_RECOMMENDATIONS];
  if (key_id && cloudProvider) {
    queryKey = [...queryKey, key_id, cloudProvider];
  }
  const getDashBoardRecommendationsQuery = useQuery({
    queryKey,
    queryFn: () => getDashBoardRecommendations(key_id, cloudProvider),
    refetchOnWindowFocus: false,
    retry: 2,
  });

  return { getDashBoardRecommendationsQuery };
};

const getCloudTypes = async () => {
  try {
    const resp = await fetch(`/dashboard/cloud-type/`);
    return resp.data || [];
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

export const useGetCloudTypes = () => {
  const getCloudTypesQuery = useQuery({
    queryKey: [QUERY_KEY_CLOUD_TYPES],
    queryFn: getCloudTypes,
    refetchOnWindowFocus: false,
    retry: 3,
  });

  return getCloudTypesQuery;
};

const getCloudKeys = async (cloudProvider) => {
  try {
    const resp = await fetch(`/recommendations/list-keys/${cloudProvider}/`);
    return resp.data || [];
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

export const useGetCloudKeys = (cloudProvider) => {
  const getCloudKeysQuery = useQuery({
    queryKey: [QUERY_KEY_CLOUD_KEYS, cloudProvider],
    queryFn: () => getCloudKeys(cloudProvider),
    enabled: !!cloudProvider,
    refetchOnWindowFocus: false,
    retry: 2,
  });

  return getCloudKeysQuery;
};

const getCloudServices = async () => {
  try {
    const resp = await fetch(`/dashboard/list-cloud-services/`);
    return resp.data || [];
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

export const useGetCloudServices = () => {
  const getCloudServicesQuery = useQuery({
    queryKey: [QUERY_KEY_CLOUD_SERVICES],
    queryFn: getCloudServices,
    refetchOnWindowFocus: false,
    retry: 2,
  });

  return getCloudServicesQuery;
};

const getRecommendationTypes = async () => {
  try {
    const resp = await fetch(`/dashboard/list-recommendation-types/`);
    return resp.data || [];
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

export const useGetRecommendationTypes = () => {
  const getRecommendationTypesQuery = useQuery({
    queryKey: [QUERY_KEY_RECOMMENDATION_TYPES],
    queryFn: getRecommendationTypes,
    refetchOnWindowFocus: false,
    retry: 2,
  });

  return getRecommendationTypesQuery;
};

export const getRecommendations = async (
  cloudProvider,
  accessKeyId,
  service,
  recommendation_type,
  costSaving
) => {
  try {
    let payload = {
      cloud_name: cloudProvider,
      key_id: accessKeyId,
      service: service,
      recommendation_type: recommendation_type,
    };

    if (cloudProvider === CLOUD_PROVIDER_AWS) {
      payload["cost_strategy"] = costSaving;
    }

    const resp = await store(`/recommendations/`, payload);

    return resp.data.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

export const getDropDownOptions = async () => {
  try {
    const resp = await fetch(`/dashboard/get-dropdown-for-recommendations/`);
    return resp.data || [];
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

export const useRecommendationsV2 = () => {
  const getRecommendationDataMutation = useMutation({
    mutationFn: (paylaod) =>
      getRecommendations(
        paylaod.cloudProvider,
        paylaod.accessKeyId,
        paylaod.service,
        paylaod.recommendationType,
        paylaod.costSaving
      ),
    onError: (error) => {
      return error;
    },
  });

  return getRecommendationDataMutation;
};
