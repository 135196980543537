import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { SolidButton } from "./SolidButton"; // Ensure SolidButton is correctly imported
import { Typography } from "@material-ui/core";
import { Button } from "@mui/material";

const ConfirmationDialog = ({
  title,
  body,
  open,
  handleClose,
  onPositiveBtnClick,
  onNegativeBtnClick,
}) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>{body}</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onNegativeBtnClick}>
          Cancel
        </Button>
        <SolidButton onClick={onPositiveBtnClick} color="primary">
          Ok
        </SolidButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
