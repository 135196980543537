import React, { useState } from "react";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
} from "@mui/material";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import history from "../../utils/history";
import { ROUTE_DASHBOARD } from "../../routers/routes";
import { EMAIL_CONTACT, GradientButton, MENU_ITEMS } from "./LandingPage";
import { ButtonStyle } from "./HeroSection";
import { SimpleButton } from "../../pages/landingPage/LandingPage";
import { ROUTE_LOGIN } from "../../routers/routes";
import { INFO_CLOUDNUDGE_EMAIL } from "../../utils/constants";

export default function DrawerBody({ open, setOpen }) {
  const route = useRouteMatch();
  let currentRoute = "/";
  const currentPageRoute = route.path;
  const currentHash = new URL(window.location.href).hash;
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

  if (currentHash) {
    currentRoute = currentHash;
  } else {
    currentRoute = currentPageRoute;
  }

  return (
    <>
      <Toolbar />
      <List>
        {MENU_ITEMS.map((menu, index) => {
          if (menu.subMenu && menu.subMenu.length > 0) {
            return (
              <>
                <ListItem
                  key={menu.name}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                    isMenuActive={currentRoute === menu.route}
                    onClick={() => {
                      setIsSubmenuOpen(!isSubmenuOpen);
                    }}
                  >
                    <ListItemText
                      primary={
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          {menu.name}
                          <KeyboardArrowDownIcon
                            style={{
                              transform: isSubmenuOpen
                                ? "rotate(0deg)"
                                : "rotate(180deg)",
                              transition: "all 0.3s ease-in-out",
                            }}
                          />
                        </Box>
                      }
                      sx={{
                        opacity: open ? 1 : 0,
                        color: `${
                          currentRoute === menu.route ? "#39b2ec" : "black"
                        }`,
                        transition: "all 0.3s ease-in-out",
                      }}
                    />
                  </ListItemButton>
                </ListItem>
                <Box
                  style={{
                    maxHeight: isSubmenuOpen ? "100px" : "0",
                    overflow: "hidden",
                    transition: "all 0.3s ease-in-out",
                    opacity: isSubmenuOpen ? 1 : 0,
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      gap: "10px",
                      marginLeft: "20px",
                    }}
                  >
                    <Box
                      style={{
                        width: "1px",
                        background:
                          "linear-gradient(90deg, #9ED2FA 0%, #10CCD2 100%)",
                      }}
                    />
                    {menu.subMenu.map((subMenu) => {
                      return (
                        <ListItem
                          key={subMenu.name}
                          disablePadding
                          sx={{ display: "block" }}
                        >
                          <ListItemButton
                            sx={{
                              minHeight: 48,
                              justifyContent: open ? "initial" : "center",
                              px: 0,
                            }}
                            isMenuActive={currentRoute === subMenu.route}
                            onClick={() => {
                              window.open(subMenu.route, "_blank");
                            }}
                          >
                            <ListItemText
                              primary={subMenu.name}
                              sx={{
                                opacity: open ? 1 : 0,
                                color: `${
                                  currentRoute === subMenu.route
                                    ? "#39b2ec"
                                    : "black"
                                }`,
                                transition: "all 0.3s ease-in-out",
                              }}
                            />
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </Box>
                </Box>
              </>
            );
          }

          return (
            <ListItem key={menu.name} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                isMenuActive={currentRoute === menu.route}
                onClick={() => {
                  if (menu.route.startsWith("#")) {
                    history.push(`/${menu.route}`);
                    setOpen(false);
                  } else {
                    history.push(menu.route);
                    setOpen(false);
                  }
                }}
              >
                <ListItemText
                  primary={menu.name}
                  sx={{
                    opacity: open ? 1 : 0,
                    color: `${
                      currentRoute === menu.route ? "#39b2ec" : "black"
                    }`,
                    transition: "all 0.3s ease-in-out",
                  }}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
        <ListItem sx={{ display: { mobile: "none", laptop: "block" } }}>
          <SimpleButton
            sx={{ paddingLeft: "16px", paddingRight: "16px" }}
            onClick={() => history.push(ROUTE_LOGIN)}
          >
            Login
          </SimpleButton>
        </ListItem>
      </List>

      <Divider
        sx={{
          height: "2px",
          background: "linear-gradient(90deg, #9ED2FA 0%, #10CCD2 100%)",
          width: "80%",
          alignSelf: "center",
        }}
      />
      <Box
        sx={{
          px: 4,
          mt: 3,
        }}
      >
        <GradientButton
          sx={ButtonStyle}
          onClick={() => {
            window.open(`mailto:${INFO_CLOUDNUDGE_EMAIL}`);
          }}
        >
          Talk to us
        </GradientButton>
      </Box>
    </>
  );
}
