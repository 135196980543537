import { push } from "connected-react-router";

import { isAuthenticated } from "../utils/jwtUtil";
import { ROUTE_DASHBOARD } from "../routers/routes";

export const verifyToken = () => {
  return (dispatch) => {
    if (isAuthenticated()) {
      /* todo: here we are supposed to check if the user has configured cloud. If yes, take to dashboard, if no take to tokensetup
       */
      dispatch(push(ROUTE_DASHBOARD));
    }
  };
};
