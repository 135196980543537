import React, { useState, useMemo, useEffect } from "react";
import { Alert, Box, CircularProgress } from "@mui/material";

import SearchInput from "./SearchInput";
import { DEFAULT_MESSAGE_EMPTY } from "../../../config/config";
import VmCardV2 from "./VmCardV2";

const VmsSection = ({ vmsList, vmsError, handleSelectVm, selectedVm }) => {
  const [searchQuery, setSearchQuery] = useState(null);

  const filteredVmsList = useMemo(() => {
    if (searchQuery?.length > 0) {
      return vmsList?.filter((vm) => {
        return (
          vm.instance_name.toLowerCase().includes(searchQuery?.toLowerCase()) ||
          vm.machine_type.toLowerCase().includes(searchQuery?.toLowerCase())
        );
      });
    }
    return vmsList;
  }, [vmsList, searchQuery]);

  return (
    <>
      {vmsList === null ? (
        <Box>
          <h3>Select an Access Key</h3>
        </Box>
      ) : (
        <Box style={{ height: "100%", overflowY: "hidden" }}>
          <SearchInput
            onSearch={(input) => {
              setSearchQuery(input);
            }}
          />
          {vmsError ? (
            <Alert severity="error" sx={{ my: 1 }}>
              {vmsError?.message}
            </Alert>
          ) : vmsList?.length === 0 ? (
            <Alert severity="warning" sx={{ my: 1 }}>
              {DEFAULT_MESSAGE_EMPTY}
            </Alert>
          ) : (
            <Box
              sx={{
                minHeight: "calc(100% - 70px)",
                maxHeight: "calc(100% - 70px)",
                overflowY: "auto",
                marginTop: "20px",
                "& :first-of-type": { marginTop: "0" },
              }}
            >
              {filteredVmsList &&
                filteredVmsList.map((vm) => (
                  <VmCardV2
                    vm={vm}
                    onClick={handleSelectVm}
                    selectedVm={selectedVm}
                  />
                ))}
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default VmsSection;
