import React from "react";
import { Redirect, Route } from "react-router-dom";

import { isAuthenticated } from "../utils/jwtUtil";
import { ROUTE_DASHBOARD } from "./routes";

const RestrictRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Redirect
          to={{
            /* todo: here we are supposed to check if the user has configured cloud. If yes, take to dashboard, if no take to tokensetup
             */
            pathname: ROUTE_DASHBOARD,
            state: { from: props.location },
          }}
        />
      ) : (
        <Component {...props} />
      )
    }
  />
);

export default RestrictRoute;
