import { Box } from "@mui/material";

import CloudPulseLogo from "../../assets/cloud_pulse_logo_with_tagline.svg";

export const BrandLogo = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: "300px",
        height: "110px",
      }}
      component="img"
      src={CloudPulseLogo}
    />
  );
};
