import {
    GET_COSTS_SUCCESS
} from '../constants/actionType';

export const costFetch = (entity, data) => {
    return {
        type: GET_COSTS_SUCCESS,
        entity: entity,
        data: data
    };
};