export const providers = [
  {
    name: "AWS",
    type: "aws",
  },
  {
    name: "Google Cloud",
    type: "gcp",
  },
  {
    name: "MS Azure",
    type: "azure",
  },
];

export const accountSpends = [
  { id: 4549912, progress: 70, range: "$730- $759" },
  { id: 4549913, progress: 30, range: "$7.5K- $9K" },
  { id: 4549914, progress: 50, range: "$55K- $75K" },
  { id: 4549915, progress: 20, range: "$8K- $10K" },
  { id: 4549916, progress: 80, range: "$730- $759" },
  { id: 4549917, progress: 100, range: "$5K- $7K" },
];

export const mockData = [
  {
    //  vCPUs : 1 - no match and GB : 2 match
    // handle no match
    /* 
    Expected output

    { "id": "3", "machineType": "t3.micro", "vCPUs": 2, "memory": "2 GiB", "disk": "EBS-Only", "network": "Up to 5 Gbps", "savingType": "On-Demand", "savingOpportunity": 0, "price": "$15.28" },
      { "id": "4", "machineType": "t3.small", "vCPUs": 2, "memory": "2 GiB", "disk": "EBS-Only", "network": "Up to 5 Gbps", "savingType": "On-Demand", "savingOpportunity": 0, "price": "$20.28" },
      { "id": "5", "machineType": "t3.xlarge", "vCPUs": 4, "memory": "2 GiB", "disk": "EBS-Only", "network": "Up to 5 Gbps", "savingType": "Savings Plans", "savingOpportunity": 25, "price": "$70.56" },
    
     */
    id: "1",
    name: "prod-web-server-01",
    instanceId: "i-0123456789abcdef0",
    machineType: "n1-standard-1",
    vCPUs: 1,
    memory: "2 GB",
    price: "$24.67",
    zone: "us-central1-a",
    details: [
      {
        id: "1",
        machineType: "t3.medium",
        vCPUs: 2,
        memory: "4 GiB",
        disk: "EBS-Only",
        network: "Up to 5 Gbps",
        savingType: "Reserved Instances",
        savingOpportunity: 30,
        price: "$33.28",
      },
      {
        id: "2",
        machineType: "t3.large",
        vCPUs: 2,
        memory: "8 GiB",
        disk: "EBS-Only",
        network: "Up to 5 Gbps",
        savingType: "Savings Plans",
        savingOpportunity: 22,
        price: "$35.28",
      },
      {
        id: "3",
        machineType: "t3.micro",
        vCPUs: 2,
        memory: "2 GiB",
        disk: "EBS-Only",
        network: "Up to 5 Gbps",
        savingType: "On-Demand",
        savingOpportunity: 0,
        price: "$15.28",
      },
      {
        id: "4",
        machineType: "t3.small",
        vCPUs: 2,
        memory: "2 GiB",
        disk: "EBS-Only",
        network: "Up to 5 Gbps",
        savingType: "On-Demand",
        savingOpportunity: 0,
        price: "$20.28",
      },
      {
        id: "5",
        machineType: "t3.xlarge",
        vCPUs: 4,
        memory: "2 GiB",
        disk: "EBS-Only",
        network: "Up to 5 Gbps",
        savingType: "Savings Plans",
        savingOpportunity: 25,
        price: "$70.56",
      },
      {
        id: "6",
        machineType: "t3.2xlarge",
        vCPUs: 8,
        memory: "32 GiB",
        disk: "EBS-Only",
        network: "Up to 5 Gbps",
        savingType: "Reserved Instances",
        savingOpportunity: 35,
        price: "$120.28",
      },
    ],
  },
  /*
    { "id": "2", "machineType": "t2.large", "vCPUs": 2, "memory": "8 GiB", "disk": "EBS-Only", "network": "Up to 5 Gbps", "savingType": "Savings Plans", "savingOpportunity": 22, "price": "$35.28" },
    { "id": "3", "machineType": "t2.micro", "vCPUs": 2, "memory": "8 GiB", "disk": "EBS-Only", "network": "Up to 5 Gbps", "savingType": "On-Demand", "savingOpportunity": 0, "price": "$15.28" },
  */
  {
    id: "2",
    name: "dev-app-server-01",
    instanceId: "i-0987654321abcdef0",
    machineType: "m1-standard-2",
    vCPUs: 2,
    memory: "8 GB",
    price: "$48.34",
    zone: "us-east1-b",
    details: [
      {
        id: "1",
        machineType: "t2.medium",
        vCPUs: 2,
        memory: "4 GiB",
        disk: "EBS-Only",
        network: "Up to 5 Gbps",
        savingType: "Reserved Instances",
        savingOpportunity: 30,
        price: "$33.28",
      },
      {
        id: "2",
        machineType: "t2.large",
        vCPUs: 2,
        memory: "8 GiB",
        disk: "EBS-Only",
        network: "Up to 5 Gbps",
        savingType: "Savings Plans",
        savingOpportunity: 22,
        price: "$35.28",
      },
      {
        id: "3",
        machineType: "t2.micro",
        vCPUs: 2,
        memory: "8 GiB",
        disk: "EBS-Only",
        network: "Up to 5 Gbps",
        savingType: "On-Demand",
        savingOpportunity: 0,
        price: "$15.28",
      },
      {
        id: "4",
        machineType: "t2.small",
        vCPUs: 2,
        memory: "2 GiB",
        disk: "EBS-Only",
        network: "Up to 5 Gbps",
        savingType: "On-Demand",
        savingOpportunity: 0,
        price: "$20.28",
      },
      {
        id: "5",
        machineType: "t2.xlarge",
        vCPUs: 4,
        memory: "8 GiB",
        disk: "EBS-Only",
        network: "Up to 5 Gbps",
        savingType: "Savings Plans",
        savingOpportunity: 25,
        price: "$70.56",
      },
      {
        id: "6",
        machineType: "t2.2xlarge",
        vCPUs: 8,
        memory: "32 GiB",
        disk: "EBS-Only",
        network: "Up to 5 Gbps",
        savingType: "Reserved Instances",
        savingOpportunity: 35,
        price: "$120.28",
      },
    ],
  },
  /*

  { "id": "4", "machineType": "C6g.small", "vCPUs": 4, "memory": "16 GiB", "disk": "EBS-Only", "network": "Up to 5 Gbps", "savingType": "On-Demand", "savingOpportunity": 0, "price": "$20.28" },
  { "id": "5", "machineType": "C6g.xlarge", "vCPUs": 4, "memory": "16 GiB", "disk": "EBS-Only", "network": "Up to 5 Gbps", "savingType": "Savings Plans", "savingOpportunity": 25, "price": "$70.56" },
  
  */
  {
    id: "3",
    name: "test-db-server-01",
    instanceId: "i-1234567890abcdef0",
    machineType: "c2-standard-4",
    vCPUs: 4,
    memory: "16 GB",
    price: "$96.67",
    zone: "europe-west1-c",
    details: [
      {
        id: "1",
        machineType: "C6g.medium",
        vCPUs: 2,
        memory: "4 GiB",
        disk: "EBS-Only",
        network: "Up to 5 Gbps",
        savingType: "Reserved Instances",
        savingOpportunity: 30,
        price: "$33.28",
      },
      {
        id: "2",
        machineType: "C6g.large",
        vCPUs: 2,
        memory: "8 GiB",
        disk: "EBS-Only",
        network: "Up to 5 Gbps",
        savingType: "Savings Plans",
        savingOpportunity: 22,
        price: "$35.28",
      },
      {
        id: "3",
        machineType: "C6g.micro",
        vCPUs: 4,
        memory: "1 GiB",
        disk: "EBS-Only",
        network: "Up to 5 Gbps",
        savingType: "On-Demand",
        savingOpportunity: 0,
        price: "$15.28",
      },
      {
        id: "4",
        machineType: "C6g.small",
        vCPUs: 4,
        memory: "16 GiB",
        disk: "EBS-Only",
        network: "Up to 5 Gbps",
        savingType: "On-Demand",
        savingOpportunity: 0,
        price: "$20.28",
      },
      {
        id: "5",
        machineType: "C6g.xlarge",
        vCPUs: 4,
        memory: "16 GiB",
        disk: "EBS-Only",
        network: "Up to 5 Gbps",
        savingType: "Savings Plans",
        savingOpportunity: 25,
        price: "$70.56",
      },
      {
        id: "6",
        machineType: "C6g.2xlarge",
        vCPUs: 8,
        memory: "16 GiB",
        disk: "EBS-Only",
        network: "Up to 5 Gbps",
        savingType: "Reserved Instances",
        savingOpportunity: 35,
        price: "$120.28",
      },
    ],
  },
  {
    id: "4",
    name: "backup-file-server-01",
    instanceId: "i-098abcdef0123456",
    machineType: "n2-standard-8",
    vCPUs: 8,
    memory: "30 GB",
    price: "$192.00",
    zone: "asia-east1-a",
    details: [
      {
        id: "1",
        machineType: "t3a.medium",
        vCPUs: 2,
        memory: "4 GiB",
        disk: "EBS-Only",
        network: "Up to 5 Gbps",
        savingType: "Reserved Instances",
        savingOpportunity: 30,
        price: "$33.28",
      },
      {
        id: "2",
        machineType: "t3a.large",
        vCPUs: 2,
        memory: "8 GiB",
        disk: "EBS-Only",
        network: "Up to 5 Gbps",
        savingType: "Savings Plans",
        savingOpportunity: 22,
        price: "$35.28",
      },
      {
        id: "3",
        machineType: "t3a.micro",
        vCPUs: 2,
        memory: "1 GiB",
        disk: "EBS-Only",
        network: "Up to 5 Gbps",
        savingType: "On-Demand",
        savingOpportunity: 0,
        price: "$15.28",
      },
      {
        id: "4",
        machineType: "t3a.small",
        vCPUs: 2,
        memory: "2 GiB",
        disk: "EBS-Only",
        network: "Up to 5 Gbps",
        savingType: "On-Demand",
        savingOpportunity: 0,
        price: "$20.28",
      },
      {
        id: "5",
        machineType: "t3a.xlarge",
        vCPUs: 4,
        memory: "16 GiB",
        disk: "EBS-Only",
        network: "Up to 5 Gbps",
        savingType: "Savings Plans",
        savingOpportunity: 25,
        price: "$70.56",
      },
      {
        id: "6",
        machineType: "t3a.2xlarge",
        vCPUs: 8,
        memory: "32 GiB",
        disk: "EBS-Only",
        network: "Up to 5 Gbps",
        savingType: "Reserved Instances",
        savingOpportunity: 35,
        price: "$120.28",
      },
    ],
  },
  {
    id: "5",
    name: "backup-file-server-01",
    instanceId: "i-098abcdef0123456",
    machineType: "n2-standard-8",
    vCPUs: 8,
    memory: "30 GB",
    price: "$192.00",
    zone: "asia-east1-a",
    details: [
      {
        id: "1",
        machineType: "t3a.medium",
        vCPUs: 2,
        memory: "4 GiB",
        disk: "EBS-Only",
        network: "Up to 5 Gbps",
        savingType: "Reserved Instances",
        savingOpportunity: 30,
        price: "$33.28",
      },
      {
        id: "2",
        machineType: "t3a.large",
        vCPUs: 2,
        memory: "8 GiB",
        disk: "EBS-Only",
        network: "Up to 5 Gbps",
        savingType: "Savings Plans",
        savingOpportunity: 22,
        price: "$35.28",
      },
      {
        id: "3",
        machineType: "t3a.micro",
        vCPUs: 2,
        memory: "1 GiB",
        disk: "EBS-Only",
        network: "Up to 5 Gbps",
        savingType: "On-Demand",
        savingOpportunity: 0,
        price: "$15.28",
      },
      {
        id: "4",
        machineType: "t3a.small",
        vCPUs: 2,
        memory: "2 GiB",
        disk: "EBS-Only",
        network: "Up to 5 Gbps",
        savingType: "On-Demand",
        savingOpportunity: 0,
        price: "$20.28",
      },
      {
        id: "5",
        machineType: "t3a.xlarge",
        vCPUs: 4,
        memory: "16 GiB",
        disk: "EBS-Only",
        network: "Up to 5 Gbps",
        savingType: "Savings Plans",
        savingOpportunity: 25,
        price: "$70.56",
      },
      {
        id: "6",
        machineType: "t3a.2xlarge",
        vCPUs: 8,
        memory: "32 GiB",
        disk: "EBS-Only",
        network: "Up to 5 Gbps",
        savingType: "Reserved Instances",
        savingOpportunity: 35,
        price: "$120.28",
      },
    ],
  },
  {
    id: "6",
    name: "backup-file-server-01",
    instanceId: "i-098abcdef0123456",
    machineType: "n2-standard-8",
    vCPUs: 8,
    memory: "30 GB",
    price: "$192.00",
    zone: "asia-east1-a",
    details: [
      {
        id: "1",
        machineType: "t3a.medium",
        vCPUs: 2,
        memory: "4 GiB",
        disk: "EBS-Only",
        network: "Up to 5 Gbps",
        savingType: "Reserved Instances",
        savingOpportunity: 30,
        price: "$33.28",
      },
      {
        id: "2",
        machineType: "t3a.large",
        vCPUs: 2,
        memory: "8 GiB",
        disk: "EBS-Only",
        network: "Up to 5 Gbps",
        savingType: "Savings Plans",
        savingOpportunity: 22,
        price: "$35.28",
      },
      {
        id: "3",
        machineType: "t3a.micro",
        vCPUs: 2,
        memory: "1 GiB",
        disk: "EBS-Only",
        network: "Up to 5 Gbps",
        savingType: "On-Demand",
        savingOpportunity: 0,
        price: "$15.28",
      },
      {
        id: "4",
        machineType: "t3a.small",
        vCPUs: 2,
        memory: "2 GiB",
        disk: "EBS-Only",
        network: "Up to 5 Gbps",
        savingType: "On-Demand",
        savingOpportunity: 0,
        price: "$20.28",
      },
      {
        id: "5",
        machineType: "t3a.xlarge",
        vCPUs: 4,
        memory: "16 GiB",
        disk: "EBS-Only",
        network: "Up to 5 Gbps",
        savingType: "Savings Plans",
        savingOpportunity: 25,
        price: "$70.56",
      },
      {
        id: "6",
        machineType: "t3a.2xlarge",
        vCPUs: 8,
        memory: "32 GiB",
        disk: "EBS-Only",
        network: "Up to 5 Gbps",
        savingType: "Reserved Instances",
        savingOpportunity: 35,
        price: "$120.28",
      },
    ],
  },
];

export const mockProjects = [
  { id: 1, name: "Project A" },
  { id: 2, name: "Project B" },
  { id: 3, name: "Project C" },
  { id: 4, name: "Project D" },
  { id: 5, name: "Project E" },
  { id: 6, name: "Project F" },
];

export const monitoring = [
  {
    date: "2024-08-20 19:01:00 UTC",
    maximum: "1.25",
  },
  {
    date: "2024-08-20 19:02:00 UTC",
    maximum: "4.0",
  },
  {
    date: "2024-08-20 19:03:00 UTC",
    maximum: "0.2",
  },
  {
    date: "2024-08-20 19:04:00 UTC",
    maximum: "0.2",
  },
  {
    date: "2024-08-20 19:05:00 UTC",
    maximum: "0.55",
  },
  {
    date: "2024-08-20 19:06:00 UTC",
    maximum: "0.15",
  },
  {
    date: "2024-08-20 19:07:00 UTC",
    maximum: "0.2",
  },
  {
    date: "2024-08-20 19:08:00 UTC",
    maximum: "0.25",
  },
  {
    date: "2024-08-20 19:09:00 UTC",
    maximum: "0.5",
  },
  {
    date: "2024-08-20 19:10:00 UTC",
    maximum: "0.9",
  },
];
