import styled from '@emotion/styled/macro';
import { Typography } from '@mui/material';

const Text = styled(Typography)`
  color: black;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
`;

export const SmallText = (props) => {
  return(<Text {...props} />);
};