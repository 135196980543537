import React, { useState, useEffect } from "react";
import { Box, Typography, Divider, Switch } from "@mui/material";
import styled from "@emotion/styled/macro";

import ProfileAndPlanSection from "./ProfileAndPlanSection";
import PublicLayout from "../../components/common/layout/PublicLayout";
import { GradeintText } from "../../pages/landingPage/LandingPage";
import PricingDiscountArrow from "../../assets/png/pricing_discount_arrow.png";

export const MenuText = styled(Typography)`
  color: #3a3a3a;
  font-size: 22px;
  font-style: normal;
  font-weight: 800;
  cursor: pointer;
`;

// TODO: move this to a separate file
export const DOM_ID_FEATURE = "#features";

const PricingPage = () => {
  const [showYearlyPrice, setShowYearlyPrice] = useState(false);

  const handleSwitchChange = () => {
    setShowYearlyPrice(!showYearlyPrice);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PublicLayout>
      <Box marginTop="80px" width="100%">
        <Box>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <GradeintText variant="h3">Our</GradeintText>
            <Typography variant="h3" fontWeight="bold">
              Pricing
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              padding: { laptop: "0 400px" },
            }}
          >
            <Divider style={{ flex: 1, backgroundColor: "#10CCD2" }} />
            <Typography fontWeight="bold">Monthly</Typography>
            <Switch checked={showYearlyPrice} onChange={handleSwitchChange} />
            <Box position="relative">
              <Typography fontWeight="bold">Yearly</Typography>
              <Box
                style={{
                  position: "absolute",
                  left: "10px",
                  top: "25px",
                  display: "flex",
                  alignItems: "flex-end",
                  gap: "10px",
                }}
              >
                <img
                  src={PricingDiscountArrow}
                  alt="discount arrow"
                  style={{ width: "87px", height: "39px" }}
                />
                <Typography
                  variant="subtitle2"
                  style={{
                    color: "#2196F3",
                    whiteSpace: "nowrap",
                    position: "relative",
                    top: "-5px",
                  }}
                >
                  Save up to 20%
                </Typography>
              </Box>
            </Box>
            <Divider style={{ flex: 1, backgroundColor: "#10CCD2" }} />
          </Box>
        </Box>

        <ProfileAndPlanSection showYearlyPrice={showYearlyPrice} />
      </Box>
    </PublicLayout>
  );
};

export default PricingPage;
