import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import store, { history } from "./store/configureStore";
import { verifyToken } from "./services/tokenService";
import App from "./containers/app/AppContainer";
// do not remove this index.css.
// It has some classes that we are using
import index from "./index.css";
import { ThemeProvider } from "@mui/material";
import theme from "./theme.js";
import { ROUTE_LANDING_PAGE } from "./routers/routes.js";

const mountNode = document.getElementById("root");
const queryClient = new QueryClient();

// Used to log in if token is valid
store.dispatch(verifyToken());

console.log("window.location: ", window.location);
console.log("theme: ", theme);

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Suspense fallback={<div>Loading...</div>}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </ConnectedRouter>
      </Provider>
    </Suspense>
  </ThemeProvider>,
  mountNode
);
