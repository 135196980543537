import styled from "@emotion/styled/macro";
import React from "react";
import { Link as BaseLink } from "react-router-dom";

/* const LocalLink = styled(BaseLink)`
  text-decoration: none;

  &:focus,
  &:hover {
    text-decoration: underline;
  }

  &:active {
    text-decoration: underline;
    color: green;
  }
`; */

const LocalLink = styled(BaseLink)(({ theme }) => {
  return {
    textDecoration: "none",
    "&:focus,&:hover": {
      textDecoration: "underline",
    },
    "&:active": {
      textDecoration: "underline",
      color: theme.palette.primary.main,
      backgroundColor: "transparent",
    },
  };
});

/* const LocalLink = styled(BaseLink)`
  text-decoration: none;

  &:focus,
  &:hover {
    text-decoration: underline;
  }

  &:active {
    text-decoration: underline;
    color: green;
  }
`; */

export const Link = (props) => {
  return (
    <LocalLink
      variant="h4"
      style={
        {
          // base style goes here
        }
      }
      {...props}
    />
  );
};
