import { H4 } from "../../ui/H4";
import { USER_FACING_PRODUCT_NAME } from "../../utils/constants";

export const WelcomeMessage = () => {
  return (
    <H4
      sx={{
        textAlign: "center",
        whiteSpace: "nowrap",
        mt: 4,
      }}
    >
      Welcome To {USER_FACING_PRODUCT_NAME}
    </H4>
  );
};
