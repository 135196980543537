import {
  Box,
  Card,
  CardHeader,
  Chip,
  Divider,
  Typography,
  Avatar,
  Button,
} from "@mui/material";
import React, { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";

import SelectField from "../../Select/SelectField";

const TeamsSection = () => {
  const {
    handleSubmit,
    control,
    watch,
  } = useForm();

  useEffect(() => {
    const subscription = watch((value, { name, type }) =>
      console.log("asdf",value, name, type)
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <Box>
      <Card style={{ padding: "20px" }}>
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <CardHeader title="Team" />
          <Box style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Chip
              label="Whileone"
              style={{
                backgroundColor: "rgba(33, 150, 243, 0.09)",
                fontWeight: "bold",
              }}
            />
            <Button variant="outlined">Leave Organization</Button>
          </Box>
        </Box>
        <Box style={{ marginLeft: "20px" }}>
          <Box
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gap: "20px",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography fontWeight="bold">Member details</Typography>
            </Box>
            <Box>
              <Typography fontWeight="bold">Role</Typography>
            </Box>
            <Box>
              <Typography fontWeight="bold">Action</Typography>
            </Box>
          </Box>
          <Box>
            <Divider sx={{ my: "10px" }} />

            <Box
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: "20px",
              }}
            >
              <Box
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <Avatar size="large" />
                <Typography>Alisha Bhale</Typography>
              </Box>
              <Box>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <SelectField
                    control={control}
                    label="User Role"
                    name="userRole"
                    placeholder="Select User Role"
                    options={[
                      { label: "Admin", value: "admin" },
                      { label: "Member", value: "member" },
                    ]}
                    size="small"
                    style={{ width: "50%" }}
                    rules={{
                      required: "User Role is required",
                    }}
                  />
                </form>
              </Box>
              <Box>
                <Button variant="outlined" color="error">
                  Remove
                </Button>
              </Box>
            </Box>
          </Box>
          <Box>
            <Divider sx={{ my: "10px" }} />

            <Box
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: "20px",
              }}
            >
              <Box
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <Avatar size="large" />
                <Typography>Akshay Bhide</Typography>
              </Box>
              <Box>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <SelectField
                    control={control}
                    label="User Role"
                    name="userRole"
                    placeholder="Select User Role"
                    options={[
                      { label: "Admin", value: "admin" },
                      { label: "Member", value: "member" },
                    ]}
                    size="small"
                    style={{ width: "50%" }}
                    rules={{
                      required: "User Role is required",
                    }}
                  />
                </form>
              </Box>
              <Box>
                <Button variant="outlined" color="error">
                  Remove
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default TeamsSection;
