import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetch, update, store, destroy } from "../utils/httpUtil";
import {
  getCloudProviderTokenListProgress,
  insertCloudProviderTokenProgress,
  updateTokenProgress,
  deleteTokenProgress,
} from "../actions/cloudProviderTokenAction";
import { parseNetworkError } from "../utils/commonUtil";
import { CLOUD_PROVIDER_GCP } from "../utils/constants";

export default function useCloudProviderToken() {
  const [responseTokenList, setResponseTokenList] = useState(null);
  const [awsRegions, setAwsRegions] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const dispatch = useDispatch();

  const getAwsRegions = async () => {
    try {
      setIsLoading(true);
      const resp = await fetch(`/dashboard/list-aws-regions/`);
      setAwsRegions(resp.data);
      setIsLoading(false);
    } catch (err) {
      setError(err);
      setIsLoading(false);
    }
  };

  const fetchTokenList = async (cloudType) => {
    try {
      setIsLoading(true);
      const endpoints = {
        aws: `cloud/aws-creds/`,
        azure: `cloud/azure-creds/`,
        gcp: `cloud/gcp-creds/`,
        oci: `cloud/oci-creds/`,
      };

      const resp = await fetch(endpoints[cloudType]);
      setResponseTokenList(resp);
      dispatch(getCloudProviderTokenListProgress(resp));
      setIsLoading(false);
    } catch (err) {
      setError(err);
      setIsLoading(false);
    }
  };

  const insertToken = async (cloudType, tokenData) => {
    try {
      setIsLoading(true);
      let formSubmittionData = tokenData;
      let isMultipart = false;
      const formData = new FormData();
      if (cloudType === CLOUD_PROVIDER_GCP) {
        for (let key in tokenData) {
          if (tokenData[key] instanceof File) {
            formData.append(key, tokenData[key], tokenData[key].name);
            isMultipart = true;
          } else {
            formData.append(key, tokenData[key]);
          }
        }
        formSubmittionData = formData;
      }

      const resp = await store(
        `cloud/${cloudType}-creds/`,
        formSubmittionData,
        isMultipart
      );
      // dispatch(insertCloudProviderTokenProgress(resp));
      setIsLoading(false);
      // fetchTokenList(cloudType);
      return resp;
    } catch (err) {
      setIsLoading(false);
      const parsedError = parseNetworkError({
        error: err?.response?.data?.error,
      });
      setError(parsedError);
      throw Error(parsedError);
    }
  };

  const updateToken = async (cloudType, tokenId, tokenData) => {
    try {
      setIsLoading(true);
      let formSubmittionData = tokenData;
      let isMultipart = false;
      const formData = new FormData();
      if (cloudType === CLOUD_PROVIDER_GCP) {
        for (let key in tokenData) {
          if (tokenData[key] instanceof File) {
            formData.append(key, tokenData[key], tokenData[key].name);
            isMultipart = true;
          } else {
            formData.append(key, tokenData[key]);
          }
        }
        formSubmittionData = formData;
      }
      const endpoint = `cloud/${cloudType}-creds/${tokenId}/`;
      const resp = await update(endpoint, formSubmittionData, isMultipart);
      dispatch(updateTokenProgress(resp));
      setIsLoading(false);
      fetchTokenList(cloudType);
      return resp;
    } catch (err) {
      setIsLoading(false);
      const parsedError = parseNetworkError({
        error: err?.response?.data?.error,
      });
      setError(parsedError);
      throw Error(parsedError);
    }
  };

  const updateTokenForOCI = async (cloudType, tokenId, tokenData) => {
    try {
      setIsLoading(true);
      const endpoint = `cloud/${cloudType}-creds/${tokenId}/`;
      const resp = await update(endpoint, tokenData, true);
      console.log("resp: ", resp);
      dispatch(updateTokenProgress(resp));
      setIsLoading(false);
      fetchTokenList(cloudType);
      return resp;
    } catch (err) {
      setError(err);
      setIsLoading(false);
    }
  };

  const insertTokenForOCI = async (cloudType, tokenData) => {
    try {
      setIsLoading(true);
      const resp = await store(`cloud/${cloudType}-creds/`, tokenData, true);
      // dispatch(insertCloudProviderTokenProgress(resp));
      setIsLoading(false);
      fetchTokenList(cloudType);
      return resp;
    } catch (err) {
      setIsLoading(false);
      const parsedError = parseNetworkError({
        error: err?.response?.data?.error,
      });
      setError(parsedError);
      throw Error(parsedError);
    }
  };
  const deleteToken = async (cloudType, tokenId) => {
    try {
      setIsLoading(true);
      const endpoint = `cloud/${cloudType}-creds/${tokenId}/`;
      const resp = await destroy(endpoint);
      dispatch(deleteTokenProgress());
      setIsLoading(false);
      fetchTokenList(cloudType);
      return resp;
    } catch (err) {
      setError(err);
      setIsLoading(false);
    }
  };

  return {
    fetchTokenList,
    insertToken,
    updateToken,
    deleteToken,
    updateTokenForOCI,
    insertTokenForOCI,
    isLoading,
    responseTokenList,
    getAwsRegions,
    awsRegions,
    error,
  };
}
