import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { store, fetch, destroy } from "../utils/httpUtil";
import { parseNetworkErrorV2 } from "../utils/commonUtil";
import {
  QUERY_KEY_AWS_CLOUD_FORMATION,
  QUERY_KEY_AWS_CLOUD_FORMATION_URL,
} from "../utils/constants";

const getAWSCloudFormationURL = async () => {
  try {
    const response = await fetch("cloud/cloudformation/");
    return response.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

const createAWSCloudFormationStack = async (data) => {
  try {
    const response = await store("cloud/aws-creds/", data);
    return response;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

const getAWSCloudFormation = async () => {
  try {
    const response = await fetch("cloud/aws-creds/");
    return response?.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

const deleteAWSCloudFormationStack = async (stack_id) => {
  try {
    const response = await destroy(`cloud/aws-creds/${stack_id}/`);
    return response?.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

export const useCloudFormation = () => {
  const queryClient = useQueryClient();

  const getCloudFormationURLQuery = useQuery({
    queryKey: [QUERY_KEY_AWS_CLOUD_FORMATION_URL],
    queryFn: getAWSCloudFormationURL,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: 2,
    enabled: false,
  });

  const createAWSCloudFormationStackMutation = useMutation({
    mutationFn: (payload) => createAWSCloudFormationStack(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_AWS_CLOUD_FORMATION],
      });
    },
    onError: (error) => {
      return error;
    },
  });

  const getAWSCloudFormationQuery = useQuery({
    queryKey: [QUERY_KEY_AWS_CLOUD_FORMATION],
    queryFn: getAWSCloudFormation,
    refetchOnWindowFocus: false,
    retry: 2,
  });

  const deleteAWSCloudFormationStackMutation = useMutation({
    mutationFn: (payload) => deleteAWSCloudFormationStack(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_AWS_CLOUD_FORMATION],
      });
    },
    onError: (error) => {
      return error;
    },
  });

  return {
    createAWSCloudFormationStackMutation,
    getAWSCloudFormationQuery,
    deleteAWSCloudFormationStackMutation,
    getCloudFormationURLQuery,
  };
};
