import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Box,
  List,
  ListItem,
} from "@mui/material";
import CloseFilledIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { TOKEN_INSTRUCTIONS_DATA } from "../../utils/constants";
import JsonViwer from "../common/JsonViewer/JsonViewer";

const isObject = (variable) => {
  if (
    typeof variable === "object" &&
    !Array.isArray(variable) &&
    variable !== null
  ) {
    return "object";
  }
  return "not-object";
};

const JsonView = ({ json }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        overflow: "auto",
        backgroundColor: "white",
        border: "1px solid #2196F3",
        borderRadius: "8px",
      }}
    >
      {JsonViwer({ jsonData: json })}
    </Box>
  );
};

const AddTokenInstructionModal = ({
  instructionModalOpen,
  handleClose,
  selectedCloudProvider,
}) => {
  const [stepNumber, setStepNumber] = useState(0);

  const currentCloudInstruction =
    TOKEN_INSTRUCTIONS_DATA[selectedCloudProvider];

  const handleNext = () => {
    if (stepNumber === currentCloudInstruction.instructions.length - 1) return;
    setStepNumber(stepNumber + 1);
  };

  const handleBack = () => {
    if (stepNumber === 0) return;
    setStepNumber(stepNumber - 1);
  };

  const closeInstructionModal = () => {
    setStepNumber(0);
    handleClose();
  };

  return (
    <Dialog
      open={instructionModalOpen}
      onClose={closeInstructionModal}
      maxWidth="md"
      scroll="paper"
      PaperProps={{
        sx: {
          height: 750,
        },
      }}
      disableEnforceFocus={false}
      disableScrollLock={true}
    >
      <DialogTitle>
        <Box
          style={{
            display: "flex",
            gap: 2,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h4">Instruction</Typography>
          <Button onClick={closeInstructionModal} variant="text">
            <CloseFilledIcon fontSize="medium" />
          </Button>
        </Box>
      </DialogTitle>
      {currentCloudInstruction?.images?.length > 0 &&
      currentCloudInstruction?.instructions?.length > 0 ? (
        <DialogContent dividers>
          <Box>
            <Box style={{ position: "relative" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 2,
                  marginBottom: 2,
                }}
              >
                <Box>
                  <Typography variant="h5" fontWeight="bold">
                    Step {stepNumber + 1} of{" "}
                    {currentCloudInstruction.instructions.length}
                  </Typography>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    startIcon={<ArrowBackIosIcon />}
                    onClick={handleBack}
                    sx={{
                      backgroundColor: "white",
                      color: "#2196F3",
                      marginRight: 2,
                    }}
                    disabled={stepNumber === 0}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    endIcon={<ArrowForwardIosIcon />}
                    onClick={handleNext}
                    disabled={
                      stepNumber ===
                      currentCloudInstruction.instructions.length - 1
                    }
                  >
                    Next
                  </Button>
                </Box>
              </Box>
              <Box height="500px" width="920px">
                <Box
                  component="img"
                  src={currentCloudInstruction.images[stepNumber]}
                  width={"100%"}
                  height={"500px"}
                  style={{
                    objectFit: "contain",
                  }}
                  alt={`add token instruction step ${stepNumber + 1}`}
                />
              </Box>
            </Box>
            <Box>
              <Typography variant="h5" fontWeight="bold" sx={{ px: 2, py: 1 }}>
                Instructions
              </Typography>
              <List>
                {currentCloudInstruction.instructions[stepNumber].map(
                  (instruction, index) => {
                    if (isObject(instruction) && instruction.type === "json") {
                      return <JsonView json={instruction.value} />;
                    }

                    if (isObject(instruction) && instruction.type === "html") {
                      return (
                        <Box sx={{ px: 2, py: 1 }}>
                          {instruction.hideNumber ? (
                            // <Typography variant="body1">
                            instruction.value
                          ) : (
                            // </Typography>
                            // <Typography variant="body1">
                            <>
                              {index + 1}. {instruction.value}
                            </>
                            // </Typography>
                          )}
                        </Box>
                      );
                    }

                    return (
                      <ListItem>
                        <Typography variant="body1">{`${
                          index + 1
                        }. ${instruction}`}</Typography>
                      </ListItem>
                    );
                  }
                )}
              </List>
            </Box>
          </Box>
        </DialogContent>
      ) : (
        <DialogContent>
          <Box sx={{ py: 4, px: 8 }}>
            <Typography variant="body1" fontWeight="bold">
              No instructions found...
            </Typography>
          </Box>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default AddTokenInstructionModal;
