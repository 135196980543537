import styled from "@emotion/styled/macro";
import { Box } from "@mui/material";

export const RightPane = styled(Box)`
  flex: 0.5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 00px 60px 0px 60px;
`;
