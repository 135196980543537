import styled from "@emotion/styled/macro";
import { Typography } from "@mui/material";

const Text = styled(Typography)`
  color: black;
  font-size: 10px;
  font-weight: 400;
  text-align: left;
`;

export const TinyText = (props) => {
  return <Text {...props} style={props.sx ?? props.sx} />;
};
