export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_IN_IN_PROGRESS = 'LOG_IN_IN_PROGRESS';
export const LOG_IN_FAILURE = 'LOG_IN_FAILURE';

export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';

export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_IN_PROGRESS = 'SIGN_UP_PROGRESS';
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE';

export const ENTITY_FAILURE = 'ENTITY_FAILURE';
export const ENTITY_CREATE = 'ENTITY_CREATE';
export const ENTITY_UPDATE = 'ENTITY_UPDATE';
export const ENTITY_FETCH = 'ENTITY_FETCH';
export const ENTITY_DELETE = 'ENTITY_DELETE';
export const SELECT_ENTITY_ITEM = 'SELECT_ENTITY_ITEM';
export const CLEAR_ENTITY_LIST = 'CLEAR_ENTITY_LIST';

export const GET_COSTS_SUCCESS = 'GET_COSTS_SUCCESS';
export const GET_COSTS_IN_PROGRESS = 'GET_COSTS_IN_PROGRESS';
export const GET_COSTS_FAILURE = 'GET_COSTS_FAILURE';


/* Actions for Cloud provider token setup */
export const INSERT_TOKEN = 'INSERT_TOKEN'
export const UPDATE_TOKEN = 'UPDATE_TOKEN'
export const DELETE_TOKEN = 'DELETE_TOKEN'
export const GET_TOKEN_LIST = 'GET_TOKEN_LIST'
