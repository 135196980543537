import {TextField} from '@mui/material';
import {Controller} from 'react-hook-form';

const InputField = (props) => {
    const {control, rules = {}, defaultValue, name, ...restProps} = props;
    return (
        <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            {...(rules && Object.entries(rules).length > 0 ? {rules} : {})}
            render={({field, fieldState: {error: fieldError}}) => (
                <TextField
                    {...field}
                    error={!!fieldError}
                    helperText={fieldError ? fieldError.message : null}
                    type="text"
                    variant="outlined"
                    {...restProps}
                />
            )}
        />
    );
};

export default InputField;
