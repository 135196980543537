import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Box } from '@mui/material';

const styles = () => ({
  legal: {
    backgroundColor: '#202940',
    position: 'static',
    bottom: 0,
    width: '100%',
    borderTop: '1px solid #eee',
    padding: '10px',
    overflow: 'hidden',
    textAlign: 'start',
    color: 'white',
  },
});

const Footer = (props) => {
  const { classes } = props;

  return (
    <div className={classes.legal}>
      <Box className="copyright">
        2024 © LOGO
      </Box>
    </div>
  );
};

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);
