import { useState } from "react";
import { parseNetworkError } from "../utils/commonUtil";
import { store } from "../utils/httpUtil";

export default function useAuth() {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const verifyOtp = async (otp) => {
    try {
      setIsLoading(true);
      const resp = await store("auth/confirm-registration/", {
        verification_code: otp,
      });
      setData(resp);
      setIsLoading(false);
    } catch (error) {
      console.log("error.response: ", error.response);
      const parsedError = parseNetworkError({
        error: error?.response?.data?.error ?? error.response?.toString(),
      });
      console.log("error: ", parsedError);
      setError(parsedError);
      setIsLoading(false);
    }
  };

  const resetPassword = async (email) => {
    try {
      setIsLoading(true);
      const resp = await store("auth/reset-password/", {
        email,
      });
      setIsLoading(false);
      setData(resp);
    } catch (error) {
      console.log("11error.response: ", error.response);
      const parsedError = parseNetworkError({
        error: error?.response?.data?.error ?? error.response?.toString(),
      });
      console.log("error: ", parsedError);
      setError(parsedError);
      setIsLoading(false);
    }
  };

  const updatePassword = async (password, token, part) => {
    console.log(`passwd: ${password} token: ${token} uid64 ${part}`);
    try {
      setIsLoading(true);
      const resp = await store(`auth/reset-password-confirm/`, {
        password,
        token,
        uidb64: part,
      });
      console.log("resp: ", resp);
      setIsLoading(false);
      setData(resp);
    } catch (error) {
      const parsedError = parseNetworkError({
        error: error?.response?.data?.error ?? error.message.toString(),
      });
      console.log("error: ", parsedError);
      setError(parsedError);
      setIsLoading(false);
    }
  };

  return {
    resetPassword,
    verifyOtp,
    updatePassword,
    isLoading,
    data,
    error,
  };
}
