import { useMutation } from "@tanstack/react-query";
import { store } from "../utils/httpUtil";
import { parseNetworkErrorV2 } from "../utils/commonUtil";

const submitFeedbackApi = async (data) => {
  try {
    const resp = await store(`/auth/submit-feedback/`, data);

    return resp?.data?.message;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

export default function useProfile() {
  const submitFeedbackMutation = useMutation({
    mutationFn: submitFeedbackApi,
    onSuccess: (data) => {
      return data;
    },
    onError: (e) => {
      return e;
    },
  });

  return { submitFeedbackMutation };
}
