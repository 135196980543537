// Import actionType constants
import {
    UPDATE_TOKEN,
    INSERT_TOKEN,
    DELETE_TOKEN,
    GET_TOKEN_LIST
} from '../constants/actionType';

export const insertCloudProviderTokenProgress = (data) => {
    return {
        type: INSERT_TOKEN,
        data
    };
}

 export const getCloudProviderTokenListProgress = (data) => {
    return {
        type: GET_TOKEN_LIST,
        data
    };
 }

export const updateTokenProgress = (data) => {
    return {
        type: UPDATE_TOKEN,
        data
    };
};

export const deleteTokenProgress = () => {
    return {
        type: DELETE_TOKEN,
    };
};
