import React, { useEffect } from "react";
import {
  Box,
  Alert,
  Skeleton,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import { Card } from "../../ui/Card";

import { getFormattedDate } from "../../utils/commonUtil";
import { SolidButton } from "../../ui/SolidButton";
import { H4 } from "../../ui/H4";
import { useCpuMonitoring } from "../../hooks/useRecommendations";
import { LineChart } from "@mui/x-charts";

export const GRAPH_HEIGHT = 600;

export const MonitoringGraph = ({
  open,
  title,
  handleClose,
  vm,
  cloudProvider,
  accessToken,
  recommendation,
}) => {
  const {
    data: monitoringData,
    isLoading,
    isError,
    error,
    refetch,
  } = useCpuMonitoring(cloudProvider, accessToken, vm, recommendation, open);

  console.log("monitoringData: ", monitoringData);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableEnforceFocus={false}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "1000px", // Set your width here
          },
        },
      }}
      // fullWidth
      /* width="50vw"
      height="50vh" */
    >
      <DialogTitle disableTypography>
        <H4>{title}</H4>
      </DialogTitle>
      <DialogContent dividers>
        <Card>
          {isLoading ? (
            <Box
              height={GRAPH_HEIGHT}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress variant="indeterminate" />
              {/* <Skeleton
                animation={"wave"}
                variant="rectangular"
                height="600px"
              /> */}
            </Box>
          ) : isError ? (
            <>
              <Alert severity="warning" sx={{ my: 1 }}>
                {error}
              </Alert>
            </>
          ) : (
            <Box
              sx={{
                p: 2,
                gap: 2,
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              {monitoringData && (
                <LineChart
                  width={1200}
                  height={GRAPH_HEIGHT}
                  /* sx={{
                    width: 1200,
                    border: "10px solid orange",
                    height: {
                      sm: 200,
                      md: 400,
                      lg: 600,
                    },
                  }} */
                  // xAxis={[{ label: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] }]}
                  /* xAxis={[
                  {
                    data: monitoringData?.map((item) =>
                      getFormattedDate(item.date)
                    ),
                  },
                ]} */
                  xAxis={[
                    {
                      label: "time",
                      scaleType: "time",
                      /* data: [
                      new Date(2014, 0, 1),
                      new Date(2015, 0, 1),
                      new Date(2016, 0, 1),
                      new Date(2017, 0, 1),
                      new Date(2018, 0, 1),
                      new Date(2019, 0, 1),
                      new Date(2020, 0, 1),
                      new Date(2021, 0, 1),
                      new Date(2022, 0, 1),
                      new Date(2023, 0, 1),
                      new Date(2024, 0, 1),
                    ], */
                      data: monitoringData?.monitoring_data.map((item) => {
                        const date = new Date(item.date);
                        // console.log("date1: ", date);
                        const time = getFormattedDate(date);
                        // console.log("time: ", time);
                        return date;
                      }),
                      valueFormatter: (dateStr) => {
                        // console.log("dt: ", dateStr);
                        const date = new Date(dateStr);
                        const options = {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: true,
                        };

                        // return `${date.getHours()}:${date.getMinutes()}`;
                        return date.toLocaleTimeString("en-US", options);
                      },
                    },
                  ]}
                  yAxis={[{ label: "utilization in %", max: 100 }]}
                  series={[
                    {
                      // data: [2, 3, 5.5, 8.5, 1.5, 5, 1, 4, 3, 8],
                      data: monitoringData?.monitoring_data.map(
                        (item) => item.maximum
                      ),
                      // showMark: ({ index }) => index % 2 === 0,
                      showMark: false,
                      label: `Current (${vm.machine_type})`,
                      color: "#000000",
                    },
                    {
                      data: monitoringData?.predicted_monitoring_data.map(
                        (item) => item.maximum
                      ),
                      showMark: false,
                      label: `Recommended (${recommendation.machine_type})`,
                    },
                  ]}
                />
              )}
            </Box>
          )}
        </Card>
      </DialogContent>
      <DialogActions>
        <SolidButton onClick={handleClose} color="primary">
          OK
        </SolidButton>
      </DialogActions>
    </Dialog>
  );
};
