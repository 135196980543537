import currency from "currency.js";
import {
  CLOUD_PROVIDER_AWS,
  CLOUD_PROVIDER_AZURE,
  CLOUD_PROVIDER_GCP,
  CLOUD_PROVIDER_OCI,
  DURATION_KEY_LAST7DAYS,
  DURATION_KEY_LASTMONTH,
  DURATION_KEY_LASTQUARTER,
  DURATION_KEY_LASTYEAR,
} from "./constants";
import LogoAWS from "../assets/cloud_provider_aws.svg";
import LogoAzure from "../assets/cloud_provider_azure.svg";
import LogoGCP from "../assets/cloud_provider_gcp.svg";
import LogoOracle from "../assets/cloud_provider_oracle.svg";
import {
  DEFAULT_DURATION_KEY,
  DEFAULT_MESSAGE_BACKEND_RESPONSE_BROKEN,
  DEFAULT_MESSAGE_ERROR,
} from "../config/config";

/**
 * Checks if `value` is `null`.
 *
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is `null`, else `false`.
 * @example
 *
 * isNull(null)
 * // => true
 *
 * isNull(void 0)
 * // => false
 */
export const isNull = (value) => {
  return value === null;
};

export const getRandomBoolean = () => {
  return Math.random() < 0.5;
};

export const isValidEmail = (email) => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
};

export function getLastSecondSegment(url) {
  // Split the pathname into segments based on the '/' character
  const segments = getAllSegments(url);

  // Return the last segment of the pathname, excluding any empty segments
  const list = segments.filter((segment) => segment?.length > 0);
  list.pop();
  return list.pop();
}

export function getLastSegment(url) {
  // Split the pathname into segments based on the '/' character
  const segments = getAllSegments(url);

  // Return the last segment of the pathname, excluding any empty segments
  return segments.filter((segment) => segment.length > 0).pop();
}

export function getAllSegments(url) {
  // Create a URL object from the provided URL string
  const urlObject = new URL(url);

  // Get the pathname from the URL object
  const pathname = urlObject.pathname;

  // Split the pathname into segments based on the '/' character
  return pathname.split("/");
}

/**
 * Checks if `value` is the `Object`. (e.g. arrays, functions, objects, regexes, `new Number(0)`, and `new String('')`)
 *
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is an object, else `false`.
 * @example
 *
 * isObject({})
 * // => true
 *
 * isObject([1, 2, 3])
 * // => true
 *
 * isObject(Function)
 * // => true
 *
 * isObject(null)
 * // => false
 */
export const isObject = (value) => {
  const type = typeof value;
  return value != null && (type === "object" || type === "function");
};

/**
 * Checks if `obj` is an empty object.
 *
 * @param {*} obj The obj to check.
 * @returns {boolean} Returns `true` if `value` is empty, else `false`.
 * @example
 *
 * isEmpty({ 'a': 1 })
 * // => false
 */
export const isEmpty = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
};

export const toUpper = (v, prev) => {
  if (v === prev) {
    return v;
  }
  return v && v.charAt(0).toUpperCase() + v.slice(1);
};

export const stringExplode = (str, delimiter) => {
  return str.split(delimiter);
};

export const convertStingToPascalCase = (str) => {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
};

export const arrayCompare = (arr1, arr2) => {
  if (!arr1 || !arr2) return;
  let result;
  arr1.forEach((e1, i) =>
    arr2.forEach((e2) => {
      if (e1.length > 1 && e2.length) {
        result = arrayCompare(e1, e2);
      } else if (e1 !== e2) {
        result = false;
      } else {
        result = true;
      }
    })
  );
  return result;
};

export const stringCompare = (str1, str2) => {
  const string1 = !isEmpty(str1) ? str1.toString() : "";
  const string2 = !isEmpty(str2) ? str2.toString() : "";
  return string1 === string2;
};

export const objectCompare = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export const durationToBackendString = (duration) => {
  let backendString = DEFAULT_DURATION_KEY;
  if (duration === DURATION_KEY_LAST7DAYS) {
    backendString = "last-seven-days";
  } else if (duration === DURATION_KEY_LASTMONTH) {
    backendString = "monthly";
  } else if (duration === DURATION_KEY_LASTQUARTER) {
    backendString = "quarterly";
  } else {
    backendString = "yearly";
  }
  return backendString;
};

export const isBase64 = (str, mimeRequired = true) => {
  if (str instanceof Boolean || typeof str === "boolean" || str === "") {
    return false;
  }
  let regex =
    "(?:[A-Za-z0-9+\\/]{4})*(?:[A-Za-z0-9+\\/]{2}==|[A-Za-z0-9+/]{3}=)?";
  let mimeRegex = "(data:\\w+\\/[a-zA-Z\\+\\-\\.]+;base64,)";
  if (mimeRequired === true) {
    regex = mimeRegex + regex;
  }
  return new RegExp("^" + regex + "$", "gi").test(str);
};

export const getLastNDaysDate = (days) => {
  const date = new Date();
  date.setDate(date.getDate() - days);
  return formatDate(date);
};

const getDDMMYYYY = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(date.getDate()).padStart(2, "0");
  return { year, month, day };
};

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

function getShortMonthName(date) {
  return date.toLocaleString("default", { month: "short" });
}

export const formatDateDDMMMYYYY = (dateParam) => {
  const date = new Date(dateParam);
  const { year, month, day } = getDDMMYYYY(date);
  return `${day} ${getShortMonthName(date)} ${year}`;
};

export const getDaysFromDuration = (duration) => {
  let days;
  switch (duration) {
    case DURATION_KEY_LASTMONTH:
      days = 30;
      break;
    case DURATION_KEY_LASTQUARTER:
      days = 90;
      break;
    case DURATION_KEY_LASTYEAR:
      days = 365;
      break;
    default:
      days = 7;
  }

  return days;
};

/**
 *
 * @param cloudProviderKey - one of the values from constants
 * @returns Human readable name for the cloud provider
 */
export const getCloudProviderName = (cloudProviderKey) => {
  let cloudProviderName;
  switch (cloudProviderKey) {
    case CLOUD_PROVIDER_AWS:
      cloudProviderName = "AWS";
      break;
    case CLOUD_PROVIDER_GCP:
      cloudProviderName = "Google Cloud";
      break;
    case CLOUD_PROVIDER_AZURE:
      cloudProviderName = "Azure";
      break;
    case CLOUD_PROVIDER_OCI:
      cloudProviderName = "Oracle";
      break;
    default:
      console.log("Invalid cloudProvider: ", cloudProviderKey);
      cloudProviderName = null;
  }

  return cloudProviderName;
};

export const getLogoForType = (type) => {
  if (type === CLOUD_PROVIDER_AWS) {
    return LogoAWS;
  } else if (type === CLOUD_PROVIDER_AZURE) {
    return LogoAzure;
  } else if (type === CLOUD_PROVIDER_GCP) {
    return LogoGCP;
  } else if (type === CLOUD_PROVIDER_OCI) {
    return LogoOracle;
  } else {
    console.error("Unknown cloud provider detected provider: ", type);
  }
};

const USD = (value) => currency(value);
const EURO = (value) =>
  currency(value, { symbol: "€", decimal: ",", separator: "." });
export const formatCurrency = (cur = null, value) => {
  if (value < 999) {
    return USD(value).format(); // => "$789.23";
  } else {
    return `${currency(value).s.symbol}${nFormatter(value)}`; // => "$89K"
  }
};

function nFormatter(num, digits) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
  const item = lookup.findLast((item) => num >= item.value);
  return item
    ? (num / item.value).toFixed(digits).replace(regexp, "").concat(item.symbol)
    : "0";
}

/**
 * @deprecated Use parseNetworkErrorV2 instead
 */
export const parseNetworkError = (errorObject) => {
  // TODO: make a v2 of this function and
  // pass in the whole response itself so that
  // error response parsing logic stays in one place
  let errorMessage = DEFAULT_MESSAGE_ERROR;
  console.log("errorObject: ", errorObject);
  const message = errorObject.error;
  console.log("message1: ", message);
  if (typeof message === "string" || message instanceof String) {
    // error.response is in correct format as discussed
    // between the frontend and backend
    console.log("Message is a string", message);
    if (message) {
      return message;
    }

    if (message.includes("502")) {
      errorMessage = "Server error. (Code: 502)";
    }

    if (message.includes("500")) {
      errorMessage = "Server error. (Code: 500)";
    }

    if (message.includes("400")) {
      errorMessage = "Incorrect input. (Code: 400)";
    }

    return errorMessage;
  } else {
    return DEFAULT_MESSAGE_BACKEND_RESPONSE_BROKEN;
  }
};

export const parseNetworkErrorV2 = (errorResponse) => {
  const errorObject = errorResponse.data;
  let errorMessage = DEFAULT_MESSAGE_ERROR;
  const message = errorObject.error;
  if (typeof message === "string" || message instanceof String) {
    // error.response is in correct format as discussed
    // between the frontend and backend
    if (message) {
      return message;
    }

    if (message.includes("502")) {
      errorMessage = "Server error. (Code: 502)";
    }

    if (message.includes("400")) {
      errorMessage = "Incorrect input. (Code: 400)";
    }

    return errorMessage;
  } else {
    return DEFAULT_MESSAGE_BACKEND_RESPONSE_BROKEN;
  }
};

export function areAllValuesEmptyArrays(obj) {
  // Iterate through the keys in the object
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      // Check if the value is an array and if it's empty
      if (!Array.isArray(obj[key]) || obj[key].length !== 0) {
        return false;
      }
    }
  }
  return true;
}

export function checkObjectProperties(obj) {
  // Iterate through the keys in the object
  let errorMessage = "";
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      // Check if the value is empty, undefined, or null
      if (value === undefined || value === null || value === "") {
        errorMessage = `Error: Property "${key}" has an invalid value: ${value}`;
        console.error(errorMessage);
        return errorMessage; // Exit the function after finding the first invalid property
      }
    }
  }
  return errorMessage;
}

export function showFirstAndLastNChars(str, maskChars = 3) {
  if (!str) {
    return str;
  }

  if (str.length <= 6) {
    return str;
  }

  const firstThreeChars = str.substring(0, maskChars);
  const lastThreeChars = str.substring(str.length - maskChars);
  return firstThreeChars + "..." + lastThreeChars;
}

// Function to get the day with ordinal suffix (e.g., 20th, 1st, 2nd)
function getDayWithSuffix(day) {
  if (day > 3 && day < 21) return `${day}th`;
  switch (day % 10) {
    case 1:
      return `${day}st`;
    case 2:
      return `${day}nd`;
    case 3:
      return `${day}rd`;
    default:
      return `${day}th`;
  }
}

// TODO: It was added for a specific purpose.
// Remove this if not used after 30 Sep 2024
export function getFormattedDate(dateStr) {
  const date = new Date(dateStr);

  // Format the date components
  const day = getDayWithSuffix(date.getUTCDate());
  const month = date.toLocaleString("en-US", {
    month: "long",
    timeZone: "UTC",
  });
  const year = date.getUTCFullYear();
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");

  // Convert to 12-hour format with AM/PM
  const period = hours >= 12 ? "PM" : "AM";
  const hour12 = hours % 12 || 12;

  // Create the formatted string
  // const formattedDate = `${day} ${month} ${year}, ${hour12}:${minutes} ${period}`;
  const formattedDate = `${hour12}:${minutes} ${period}`;
  return formattedDate;
}
