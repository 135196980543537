/**
 * Store string record in the storage
 *
 * @param {string} key
 * @param {string | array | object} value
 */
export let setLocalStorage = (key, value) => {
  if (value && typeof value === "string") {
    localStorage.setItem(key, value);
  } else {
    localStorage.setItem(key, JSON.stringify(value)); // convert arrays or objects into strings
  }
};

/**
 * Retrieve record from the storage using the key
 *
 * @param {string} key
 */
export let getLocalStorage = (key) => {
  const data = localStorage.getItem(key);
  try {
    return JSON.parse(data); // converts a JSON string into a Javascript Object
  } catch (e) {
    return data;
  }
};

/**
 * Clear records from the storage using the key
 *
 * @param {string} key
 */
export let clearLocalStorage = (key) => localStorage.removeItem(key);

export const setCookie = (name, value) => {
  const date = new Date();
  date.setTime(date.getTime() + 24 * 60 * 60 * 1000); // 24 hours
  document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
};

export const getCookie = (key) => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.substring(0, key.length + 1) === `${key}=`) {
      return decodeURIComponent(cookie.substring(key.length + 1));
    }
  }
  return null;
};

export const deleteCookie = (name, path = "/") => {
  document.cookie = `${name}=; path=${path}; max-age=0;`;
};
