import React, { useState } from "react";
import { Box, Typography, IconButton, Snackbar } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export default function JSONFormatter({ jsonData }) {
  const [copySuccess, setCopySuccess] = useState(false);
  const parsedJSON = JSON.parse(jsonData);

  const formatJSON = (obj, indent = 0) => {
    if (typeof obj !== "object" || obj === null) {
      return (
        <Typography
          component="span"
          color={typeof obj === "string" ? "success.main" : "primary.main"}
        >
          {JSON.stringify(obj)}
        </Typography>
      );
    }

    const isArray = Array.isArray(obj);
    const openBracket = isArray ? "[" : "{";
    const closeBracket = isArray ? "]" : "}";

    return (
      <Box>
        <Typography component="span">{openBracket}</Typography>
        <Box ml={2}>
          {Object.entries(obj).map(([key, value], index, array) => (
            <Box key={key} display="flex">
              {!isArray && (
                <Typography component="span" color="text.secondary" mr={1}>
                  "{key}":
                </Typography>
              )}
              {formatJSON(value, indent + 2)}
              {index < array.length - 1 && (
                <Typography component="span">,</Typography>
              )}
            </Box>
          ))}
        </Box>
        <Typography component="span">{closeBracket}</Typography>
      </Box>
    );
  };

  if (parsedJSON === null) {
    return null;
  }

  const handleCopy = () => {
    const jsonString = JSON.stringify(parsedJSON, null, 2);
    navigator.clipboard.writeText(jsonString).then(
      () => {
        setCopySuccess(true);
      },
      (err) => {
        console.error("Failed to copy text: ", err);
      }
    );
  };

  return (
    <Box component="pre" sx={{ m: 0, padding: 2 }}>
      <IconButton
        onClick={handleCopy}
        sx={{
          position: "absolute",
          top: 8,
          right: 8,
          backgroundColor: "background.paper",
          "&:hover": {
            backgroundColor: "action.hover",
          },
        }}
        aria-label="Copy JSON"
      >
        <ContentCopyIcon />
      </IconButton>
      {formatJSON(parsedJSON)}
      <Snackbar
        open={copySuccess}
        autoHideDuration={2000}
        onClose={() => setCopySuccess(false)}
        message="JSON copied to clipboard"
      />
    </Box>
  );
}
