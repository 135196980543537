import React from "react";
import ProfileAndPlanForAdmin from "./ProfileAndPlanForAdmin";
import ProfileAndPlanForMember from "./ProfileAndPlanForMember";
import ProfileAndPlanForUnpaidUser from "./ProfileAndPlanForUnpaidUser";

const ProfilePage = () => {
  return (
    // // TODO if authenticated user is of role Admin render this UI
    // <ProfileAndPlanForAdmin />
    // // else
    // <ProfileAndPlanForMember/>
    <ProfileAndPlanForUnpaidUser />
  );
};

export default ProfilePage;
