// Import custom components
import { LOCATION_CHANGE } from "connected-react-router";
import {
  LOG_IN_SUCCESS,
  LOG_IN_FAILURE,
  LOG_OUT_SUCCESS,
  LOG_IN_IN_PROGRESS,
  SIGN_UP_FAILURE,
  SIGN_UP_SUCCESS,
  SIGN_UP_IN_PROGRESS,
} from "../constants/actionType";

var initialState = {
  token: null,
  isAuthenticated: false,
  isLoading: false,
  errorMessage: null,
};

/**
 * A reducer takes two arguments, the current state and an action.
 */
const authReducer = (state, action) => {
  // console.log("action: ", action);
  state = state || initialState;

  switch (action.type) {
    // this is used when we go from login to signup
    // the message in the state remains the same.
    // We use below case to reset the message.
    case LOCATION_CHANGE:
      return Object.assign({}, state, {
        errorMessage: null,
        isLoading: false,
      });

    case LOG_IN_IN_PROGRESS:
      return Object.assign({}, state, {
        isAuthenticated: false,
        isLoading: true,
        token: null,
        errorMessage: null,
      });

    case LOG_IN_SUCCESS:
      return Object.assign({}, state, {
        isAuthenticated: true,
        isLoading: false,
        token: action.data,
      });

    case LOG_IN_FAILURE:
      return Object.assign({}, state, {
        isAuthenticated: false,
        isLoading: false,
        token: null,
        errorMessage: action.error.message,
      });

    case SIGN_UP_IN_PROGRESS:
      return Object.assign({}, state, {
        isAuthenticated: false,
        isLoading: true,
        token: null,
        errorMessage: null,
      });

    case SIGN_UP_SUCCESS:
      return Object.assign({}, state, {
        isAuthenticated: false,
        isLoading: false,
        token: action.data,
        errorMessage: null,
      });

    case SIGN_UP_FAILURE:
      return Object.assign({}, state, {
        isAuthenticated: false,
        isLoading: false,
        token: null,
        errorMessage: action.error.message,
      });

    case LOG_OUT_SUCCESS:
      return Object.assign({}, state, {
        isAuthenticated: false,
        isLoading: false,
        token: null,
      });

    default:
      return state;
  }
};

export default authReducer;
