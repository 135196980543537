import styled from "@emotion/styled/macro";
import { Card as BaseCard } from "@mui/material";

const CustomCard = styled(BaseCard)`
  background: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 1px;
  // box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
`;

export const Card = (props) => {
  return (
    <CustomCard
      {...props}
      /* style={{
        ...props.sx,
      }} */
    />
  );
};
