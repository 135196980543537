import { useState } from "react";
import { useDispatch } from "react-redux";
import { fetch } from "../utils/httpUtil";
import { costFetch } from "../actions/costActions";
import { useQuery } from "@tanstack/react-query";
import { parseNetworkError, parseNetworkErrorV2 } from "../utils/commonUtil";
import {
  QUERY_KEY_COST_BY_REGION,
  QUERY_KEY_POSSIBLE_SAVINGS,
  QUERY_KEY_COST_BY_ACCOUNT,
  CLOUD_PROVIDER_AWS,
  CLOUD_PROVIDER_AZURE,
  CLOUD_PROVIDER_GCP,
  CLOUD_PROVIDER_OCI,
} from "../utils/constants";
import {
  CACHED_DATA_CACHE_TIME,
  CACHED_DATA_STALE_TIME,
} from "../config/config";

export default function useCosts() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [costByTrend, setCostByTrend] = useState();
  const [costByRegion, setCostByRegion] = useState();
  const [costByService, setCostByService] = useState();
  const [costByServiceV2, setCostByServiceV2] = useState();
  const [costByResouceGroup, setCostByResouceGroup] = useState();
  const [costByResouceGroupV2, setCostByResouceGroupV2] = useState();
  const [resourceGroupSpendEffeciency, setResourceGroupSpendEffeciency] =
    useState();
  const dispatch = useDispatch();

  const getCosts = async (startDate, endDate) => {
    try {
      setIsLoading(true);
      const resp = await fetch(
        `/dashboard/total-cost/${startDate}/${endDate}/`
      );
      dispatch(costFetch("costs", resp));
    } catch (error) {
      const parsedError = parseNetworkError({
        error: error?.response?.data?.error,
      });
      setError(parsedError);
    } finally {
      setIsLoading(false);
    }
  };

  const getCostsByTrend = async (startDate, endDate, duration) => {
    try {
      setIsLoading(true);
      const resp = await fetch(
        `/dashboard/cloud-cost-trend/${startDate}/${endDate}/${duration}/`
      );
      setCostByTrend(resp.data);
    } catch (error) {
      const parsedError = parseNetworkError({
        error: error?.response?.data?.error,
      });
      setError(parsedError);
    } finally {
      setIsLoading(false);
    }
  };

  // TODO: delete this.
  const getCloudCostByRegion = async (cloud, startDate, endDate) => {
    try {
      setIsLoading(true);
      const resp = await fetch(
        `/dashboard/cloud-cost/${cloud}/region/${startDate}/${endDate}/`
      );
      setCostByRegion(resp.data);
    } catch (error) {
      const parsedError = parseNetworkError({
        error: error?.response?.data?.error,
      });
      setError(parsedError);
    } finally {
      setIsLoading(false);
    }
  };

  const getCostByResourceGroup = async (cloud, startDate, endDate) => {
    try {
      setIsLoading(true);
      setCostByResouceGroup(null);
      const resp = await fetch(
        `/dashboard/cloud-cost/${cloud}/resource-group/${startDate}/${endDate}/`
      );
      setCostByResouceGroup(resp.data);
    } catch (error) {
      const parsedError = parseNetworkError({
        error: error?.response?.data?.error,
      });
      setError(parsedError);
    } finally {
      setIsLoading(false);
    }
  };

  const getCostByResourceGroupV2 = async (
    cloud,
    startDate,
    endDate,
    activeKey
  ) => {
    try {
      setIsLoading(true);
      setCostByResouceGroupV2(null);
      const resp = await fetch(
        `/dashboard/cloud-cost/${cloud}/resource-group/${startDate}/${endDate}/?key_id=${activeKey}`
      );
      setCostByResouceGroupV2(resp.data);
    } catch (error) {
      const parsedError = parseNetworkError({
        error: error?.response?.data?.error,
      });
      setError(parsedError);
    } finally {
      setIsLoading(false);
    }
  };

  const getCloudCostByService = async (cloud, startDate, endDate) => {
    try {
      setIsLoading(true);
      const resp = await fetch(
        `/dashboard/cloud-cost/${cloud}/service/${startDate}/${endDate}/`
      );
      setCostByService(resp.data);
    } catch (error) {
      const parsedError = parseNetworkError({
        error: error?.response?.data?.error,
      });
      setError(parsedError);
    } finally {
      setIsLoading(false);
    }
  };

  const getCloudCostByServiceV2 = async (
    cloud,
    startDate,
    endDate,
    accessKeyId
  ) => {
    try {
      setIsLoading(true);
      const resp = await fetch(
        `/dashboard/cloud-cost/${cloud}/service/${startDate}/${endDate}/?key_id=${accessKeyId}`
      );
      setCostByServiceV2(resp.data);
    } catch (error) {
      const parsedError = parseNetworkError({
        error: error?.response?.data?.error,
      });
      setError(parsedError);
    } finally {
      setIsLoading(false);
    }
  };

  // TODO: remove this.
  const getResourceGroupSpendEffeciency = async (cloud, startDate, endDate) => {
    try {
      setIsLoading(true);
      const resp = await fetch(
        `/dashboard/resource-group-spend-efficiency/${cloud}/${startDate}/${endDate}/`
      );
      setResourceGroupSpendEffeciency(resp.data);
    } catch (error) {
      const parsedError = parseNetworkError({
        error: error?.response?.data?.error,
      });
      setError(parsedError);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    getCosts,
    getCostsByTrend,
    costByTrend,
    getCloudCostByRegion,
    costByRegion,
    getCloudCostByService,
    getCloudCostByServiceV2,
    costByService,
    costByServiceV2,
    getCostByResourceGroup,
    getCostByResourceGroupV2,
    costByResouceGroup,
    costByResouceGroupV2,
    getResourceGroupSpendEffeciency,
    resourceGroupSpendEffeciency,
    isLoading,
    error,
  };
}

const getCloudCostByRegion = async (cloudProvider, startDate, endDate) => {
  try {
    const resp = await fetch(
      `/dashboard/cloud-cost/${cloudProvider}/region/${startDate}/${endDate}/`
    );
    return resp.data.data;
  } catch (error) {
    console.log("error: ", error?.response);
    const parsedError = parseNetworkErrorV2(error?.response);
    console.log("parsedError: ", parsedError);
    throw parsedError;
  }
};

export function useCostByRegion(cloudProvider, startDate, endDate) {
  const { isLoading, isError, data, error, refetch } = useQuery({
    queryKey: [QUERY_KEY_COST_BY_REGION, startDate, endDate, cloudProvider],
    queryFn: async () => {
      return await getCloudCostByRegion(cloudProvider, startDate, endDate);
    },
    staleTime: CACHED_DATA_STALE_TIME,
  });

  return {
    data,
    isLoading,
    isError,
    error,
    refetch,
  };
}

const getCloudCostByRegionV2 = async (
  cloudProvider,
  startDate,
  endDate,
  accessKeyId
) => {
  try {
    const resp = await fetch(
      `/dashboard/cloud-cost/${cloudProvider}/region/${startDate}/${endDate}/?key_id=${accessKeyId}`
    );
    return resp.data.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

export function useCostByRegionV2(
  cloudProvider,
  startDate,
  endDate,
  accessKeyId
) {
  const { isLoading, isError, data, error, refetch } = useQuery({
    queryKey: [
      QUERY_KEY_COST_BY_REGION,
      startDate,
      endDate,
      cloudProvider,
      accessKeyId,
    ],
    queryFn: async () => {
      return await getCloudCostByRegionV2(
        cloudProvider,
        startDate,
        endDate,
        accessKeyId
      );
    },
    staleTime: CACHED_DATA_STALE_TIME,
    retry: 2,
    enabled: !!accessKeyId,
  });

  return {
    data,
    isLoading,
    isError,
    error,
    refetch,
  };
}

export function useTrends() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [costByTrend, setCostByTrend] = useState();
  const [accountSpendEffeciency, setAccountSpendEffeciency] = useState();

  const getCostsByTrend = async (startDate, endDate, duration) => {
    try {
      setIsLoading(true);
      const resp = await fetch(
        `/dashboard/cloud-cost-trend/${startDate}/${endDate}/${duration}/`
      );
      setCostByTrend(resp.data);
    } catch (error) {
      const parsedError = parseNetworkError({
        error: error?.response?.data?.error,
      });
      setError(parsedError);
    } finally {
      setIsLoading(false);
    }
  };

  // TODO: delete this
  const getAccountSpendEffeciency = async (startDate, endDate) => {
    try {
      setIsLoading(true);
      const resp = await fetch(
        `/dashboard/account-spend-efficiency/${startDate}/${endDate}/`
      );
      setAccountSpendEffeciency(resp.data);
    } catch (error) {
      const parsedError = parseNetworkError({
        error: error?.response?.data?.error,
      });
      setError(parsedError);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    getCostsByTrend,
    costByTrend,
    getAccountSpendEffeciency,
    accountSpendEffeciency,
    isLoading,
    error,
  };
}

const getRecommendationsPossibleSavings = async (
  cloudProvider,
  accessKeyId
) => {
  try {
    const resp = await fetch("/recommendations/possible-total-savings/", {
      cloud_name: cloudProvider,
      key_id: accessKeyId,
    });

    return resp.data.data;
  } catch (error) {
    throw parseNetworkErrorV2(error.response);
  }
};

/**
 * Get all the tokens/accesskey added for this user.
 * @param {*} cloudProvider
 * @returns
 */
export function useRecommendationsPossibleSavings(cloudProvider, accessKeyId) {
  const { isLoading, isError, data, error, refetch } = useQuery({
    queryKey: [QUERY_KEY_POSSIBLE_SAVINGS, cloudProvider, accessKeyId],
    queryFn: async () =>
      getRecommendationsPossibleSavings(cloudProvider, accessKeyId),
    staleTime: CACHED_DATA_STALE_TIME,
  });

  return {
    data,
    isLoading,
    isError,
    error,
    refetch,
  };
}

const getCostByAccountV2 = async (
  cloudProvider,
  startDate,
  endDate,
  accessKeyId
) => {
  let url = "";

  if (cloudProvider === CLOUD_PROVIDER_AWS) {
    url = `/dashboard/cloud-cost/aws/account/${startDate}/${endDate}/?key_id=${accessKeyId}`;
  }
  if (cloudProvider === CLOUD_PROVIDER_AZURE) {
    url = `/dashboard/cloud-cost/azure/resource-group/${startDate}/${endDate}/?key_id=${accessKeyId}`;
  }
  if (cloudProvider === CLOUD_PROVIDER_GCP) {
    url = `/dashboard/cloud-cost/gcp/project/${startDate}/${endDate}/?key_id=${accessKeyId}`;
  }
  if (cloudProvider === CLOUD_PROVIDER_OCI) {
    url = `/dashboard/cloud-cost/oci/compartment/${startDate}/${endDate}/?key_id=${accessKeyId}`;
  }
  try {
    if (url === "") {
      throw Error("Invalid cloud provider");
    }
    const resp = await fetch(url);
    return resp?.data?.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw parsedError;
  }
};

export const useCostByAccountV2 = (
  cloudProvider,
  startDate,
  endDate,
  accessKeyId
) => {
  const getCostByAccountQuery = useQuery({
    queryKey: [
      QUERY_KEY_COST_BY_ACCOUNT,
      cloudProvider,
      startDate,
      endDate,
      accessKeyId,
    ],
    queryFn: () =>
      getCostByAccountV2(cloudProvider, startDate, endDate, accessKeyId),
    staleTime: CACHED_DATA_STALE_TIME,
    refetchOnWindowFocus: false,
    retry: 2,
    enabled: !!accessKeyId,
  });

  return getCostByAccountQuery;
};
