import {
  CACHED_DATA_STALE_TIME,
  DEFAULT_MESSAGE_BACKEND_RESPONSE_BROKEN,
} from "../config/config";
import {
  CLOUD_PROVIDER_AWS,
  CLOUD_PROVIDER_AZURE,
  CLOUD_PROVIDER_GCP,
  CLOUD_PROVIDER_OCI,
  QUERY_KEY_MONITORING_MEMORY,
} from "../utils/constants";
import { store } from "../utils/httpUtil";
import { useQuery } from "@tanstack/react-query";
import { parseNetworkErrorV2 } from "../utils/commonUtil";

const getMemMonitoringParams = (
  cloudProvider,
  accessToken,
  vm,
  recommendation
) => {
  if (cloudProvider === CLOUD_PROVIDER_AWS) {
    return {
      instance_id: vm.instance_id,
      machine_type: vm.machine_type,
      instance_name: vm.instance_name,
      memory: vm.memory?.toString(),
      aws_key_id: accessToken,
    };
  } else if (cloudProvider === CLOUD_PROVIDER_OCI) {
    return {
      instance_id: vm.instance_id,
      instance_name: vm.instance_name,
      memory: vm.memory?.toString(),
      machine_type: vm.machine_type,
      region: vm.region,
      oci_key_id: accessToken,
      compartment_id: vm.compartment_id,
    };
  } else if (cloudProvider === CLOUD_PROVIDER_AZURE) {
    return {
      azure_key_id: accessToken,
      instance_id: vm.instance_id,
      instance_name: vm.instance_name,
      machine_type: vm.machine_type,
      recommended_instance_type: recommendation.machine_type,
      memory: vm.memory?.toString(),
    };
  } else if (cloudProvider === CLOUD_PROVIDER_GCP) {
    return {
      gcp_key_id: accessToken,
      instance_id: vm.instance_id,
      instance_name: vm.instance_name,
      machine_type: vm.machine_type,
      recommended_instance_type: recommendation.machine_type,
      memory: vm.memory?.toString(),
    };
  }
};

const getMemMonitoring = async (
  cloudProvider,
  accessToken,
  vm,
  recommendation
) => {
  console.log(
    `cloudProvider ${cloudProvider}, accessToken ${accessToken},  vm ${vm}, recommendation ${recommendation}`
  );
  console.log("vm:", vm);
  console.log("recommendation:", recommendation);
  try {
    const resp = await store(
      `/recommendations/monitoring/memory/${cloudProvider}/`,
      getMemMonitoringParams(cloudProvider, accessToken, vm, recommendation)
    );

    /*   if (resp.data.status === 500) {
      throw Error({
        error: DEFAULT_MESSAGE_BACKEND_RESPONSE_BROKEN,
      });
    } */

    return resp.data.data;
  } catch (error) {
    // console.log("thrown error", error);
    const parsedError = parseNetworkErrorV2(error.response);
    throw parsedError;
  }
};

/**
 *
 * @param {*} cloudProvider
 * @returns
 */
export function useMemoryMonitoring(
  open,
  cloudProvider,
  accessToken,
  vm,
  recommendation
) {
  const { isLoading, isError, data, error, refetch } = useQuery({
    queryKey: [
      QUERY_KEY_MONITORING_MEMORY,
      cloudProvider,
      accessToken,
      vm,
      recommendation,
    ],
    enabled: open,
    queryFn: async () =>
      getMemMonitoring(cloudProvider, accessToken, vm, recommendation),
    staleTime: CACHED_DATA_STALE_TIME,
  });

  return {
    data,
    isLoading,
    isError,
    error,
    refetch,
  };
}
