import styled from "@emotion/styled/macro";
import { TextField as BaseTextField } from "@mui/material";

const LocalTextField = styled(BaseTextField)`
  background: #ffffff;
  border: none;
  border-radius: 4px;
`;

export const InputTextField = (props) => {
  return (
    <LocalTextField
      style={
        {
          // base style goes here
        }
      }
      {...props}
    />
  );
};
