// Import custom components
import {
  GET_COSTS_SUCCESS,
} from "../constants/actionType";

var initialState = {
  data: {},
};

/**
 * A reducer takes two arguments, the current state and an action.
 */
const costReducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case GET_COSTS_SUCCESS:
      return {...state, data: action.data.data };

    default:
      return state;
  }
};

export default costReducer;
