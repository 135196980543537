import { Select, MenuItem, InputLabel, FormControl, FormHelperText } from "@mui/material";
import { Controller } from "react-hook-form";

const SelectField = (props) => {
  const {
    control,
    rules = {},
    defaultValue = "", // Set a default value if not provided
    options = [], // Set default options array
    name,
    label,
    ...restProps
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field, fieldState: { error: fieldError } }) => (
        <FormControl fullWidth error={!!fieldError} size={restProps.size}>
          <InputLabel id={`${name}-label`} htmlFor={name}>
            {label}
          </InputLabel>
          <Select
            labelId={`${name}-label`}
            id={name}
            {...field}
            label={label}
            {...restProps}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          {fieldError && (
            <FormHelperText error>{fieldError.message}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

export default SelectField;
