import { useQuery, useMutation } from "@tanstack/react-query";

import { parseNetworkErrorV2 } from "../utils/commonUtil";
import { fetch, store } from "../utils/httpUtil";
import {
  QUERY_KEY_CC_CLOUD_REGIONS,
  QUERY_KEY_CC_CLOUD_KEYS,
  QUERY_KEY_CC_CLOUD_TYPES,
} from "../utils/constants";

const getCloudTypes = async () => {
  try {
    const resp = await fetch(`/dashboard/cloud-type/`);
    return resp.data || [];
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

const getCloudKeys = async (cloudProvider) => {
  try {
    const resp = await fetch(`/recommendations/list-keys/${cloudProvider}/`);
    return resp.data || [];
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

const getRegions = async (cloudType) => {
  try {
    const resp = await fetch(`/dashboard/list-regions/${cloudType}/`);
    return resp.data || [];
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

const getCrossCloudRecommendations = async (
  currentCloudId,
  crossCloudId,
  crossRegionId,
  keyId
) => {
  try {
    const response = await store(`/crosscloud/recommendation/`, {
      current_cloud_id: currentCloudId,
      cross_cloud_id: crossCloudId,
      cross_region_id: crossRegionId,
      key_id: keyId,
    });
    return response.data.data;
  } catch (error) {
    console.log("error: ", error);
    throw new Error(error);
  }
};

export const useGetRegions = (cloudType) => {
  const getRegionsQuery = useQuery({
    queryKey: [QUERY_KEY_CC_CLOUD_REGIONS, cloudType],
    queryFn: () => getRegions(cloudType),
    enabled: !!cloudType,
    refetchOnWindowFocus: false,
    retry: 3,
  });

  return getRegionsQuery;
};

export const useGetCloudTypes = () => {
  const getCloudTypesQuery = useQuery({
    queryKey: [QUERY_KEY_CC_CLOUD_TYPES],
    queryFn: getCloudTypes,
    refetchOnWindowFocus: false,
    retry: 3,
  });

  return getCloudTypesQuery;
};

export const useGetCloudKeys = (cloudProvider) => {
  const getCloudKeysQuery = useQuery({
    queryKey: [QUERY_KEY_CC_CLOUD_KEYS, cloudProvider],
    queryFn: async () => getCloudKeys(cloudProvider),
    refetchOnWindowFocus: false,
    enabled: !!cloudProvider,
  });

  return getCloudKeysQuery;
};

export const useCrossCloudRecommendations = () => {
  const getCrossCloudRecommendationsMutation = useMutation({
    mutationFn: ({ currentCloudId, crossCloudId, crossRegionId, keyId }) => {
      return getCrossCloudRecommendations(
        currentCloudId,
        crossCloudId,
        crossRegionId,
        keyId
      );
    },
    onError: (error) => {
      return error;
    },
  });

  return getCrossCloudRecommendationsMutation;
};
