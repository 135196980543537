import jwtDecode from "jwt-decode";

import { getCookie } from "./storageUtil";
import { STORAGE_AUTH_TOKEN, STORAGE_USER_INFO } from "./constants";

let isTokenExpired = (token) => {
  try {
    const decoded = jwtDecode(token);
    if (decoded.exp < Date.now() / 1000) {
      // Checking if token is expired.
      return true;
    }

    return false;
  } catch (e) {
    return false;
  }
};

export let getToken = () => {
  return getCookie(STORAGE_AUTH_TOKEN);
};

export let getInfo = () => {
  return JSON.parse(getCookie(STORAGE_USER_INFO));
};

export let isAuthenticated = () => {
  const token = getCookie(STORAGE_AUTH_TOKEN);
  const result = token !== null;
  return result;
};

export let hasCloudCredentials = () => {
  const userInfo = getInfo();
  const result = userInfo != null && userInfo.has_token;
  return result;
};
