import styled from "@emotion/styled/macro";
import { Chip as BaseChip } from "@mui/material";

const CustomChip = styled(BaseChip)`
  border-radius: 4px;
  background: "red";
`;

export const Chip = (props) => {
  return <CustomChip {...props} style={props.sx} />;
};
