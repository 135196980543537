import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import CloudBlue from "../../assets/svg/cloud_blue.svg";
import { debugColor } from "../../debugTools/debugColors";
import { ROUTE_SIGNUP } from "../../routers/routes";
import { APP_BAR_HEIGHT } from "../../ui/AppBar";
import { Link } from "../../ui/Link";
import history from "../../utils/history";
import { ButtonStyle } from "./HeroSection";
import {
  EMAIL_BOOK_DEMO,
  GradeintTextStyle,
  GradientButton,
  HeaderText,
  SimpleButton,
} from "./LandingPage";

const PLAN_PRICING = [
  {
    type: "pricing",
    planName: "Starter Plan",
    pricing: "3",
    currency: "USD",
    info: "per instance/user for a 48-hour window (will be activated after first token sync)",
    characteristics: [
      "Usage Analytics: Overview of top 3 cost drivers.",
      "Budget Alerts: Simple threshold alerts.",
      "Multi-cloud Support: Limited to 4 cloud provider.",
      "Resource Optimization: Recommendations with actionable insight",
      "Automated Reporting: Weekly email report with CSV export",
    ],
    ctaLabel: "SignUp",
    highlight: false,
  },
  {
    type: "custom",
    planName: "Ultimate Plan",
    // pricing: "Custom Pricing",
    pricing: "4",
    currency: "USD",
    info: "Based on Usage & Needs. Includes everything from Starter Plan",
    characteristics: [
      "Dedicated Account Manager",
      "Priority Support",
      "Custom Integrations",
      "Advanced Security Features",
      "On-demand Cost Audits and Optimization Consultations",
    ],
    ctaLabel: "Talk to us",
    highlight: true,
  },
];

const CardTitle = styled(Typography)`
  color: var(--text-primary, rgba(0, 0, 0, 0.87));
  /* font-feature-settings: "liga" off, "clig" off; */

  /* typography/h3 */
  /* font-family: Roboto; */
  font-style: normal;
  font-weight: 400;
  line-height: 116.7%; /* 56.016px */
`;

const PlanInfo = styled(Typography)`
  color: rgba(0, 0, 0, 0.87);

  /* typography/h5 */
  /* font-family: Roboto; */
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 133.4%; /* 32.016px */
`;

const DIVIDER_STYLE = {
  height: "1.5px",
  width: {
    mobile: "90%",
    tablet: "100%",
    laptop: "100%",
    desktop: "90%",
  },
  alignSelf: "center",
  color: "rgba(0, 0, 0, 0.12)",
  mt: {
    mobile: "8px",
    tablet: "20px",
    laptop: "20px",
    desktop: "12px",
  },
  mb: {
    mobile: "38px",
    tablet: "20px",
    laptop: "20px",
    desktop: "32px",
  },
};

const CharacteristicText = styled(Typography)`
  color: rgba(0, 0, 0, 0.87);
  /* font-feature-settings: 'liga' off, 'clig' off; */
  /* typography/h6 */
  /* font-family: Roboto; */
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 32px */
  letter-spacing: 0.15px;
`;

function PlanCharacteristic({ planInfo }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("mobile"));
  const characteristics = planInfo.characteristics;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        minHeight: {
          mobile: "46vh",
          tablet: "44vh",
          laptop: "50vh",
          desktop: "50vh",
        },
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "24px",
        }}
      >
        {characteristics.map((characteristic) => {
          return (
            <Box
              key={characteristic}
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "top",
                justifyContent: "start",
                textAlign: "start",
                gap: "16px",
              }}
            >
              <Box component="img" src={CloudBlue} />
              <CharacteristicText
                sx={{
                  fontSize: {
                    mobile: "14px",
                    tablet: "16px",
                    laptop: "14px",
                    desktop: "20px",
                  },
                }}
              >
                {characteristic}
              </CharacteristicText>
            </Box>
          );
        })}
      </Box>
      <Box>
        {planInfo.type === "custom" ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "24px",
            }}
          >
            <Link to="#">Discount on 6 or 12 Months Commitments</Link>
            <GradientButton
              onClick={() => {
                window.open(`mailto:${EMAIL_BOOK_DEMO}`);
              }}
              sx={{
                justifySelf: "end",
                alignSelf: "center",
                ...ButtonStyle,
              }}
            >
              {planInfo.ctaLabel}
            </GradientButton>
          </Box>
        ) : (
          <>
            {/* we are not allowing to navigate to signup page
         on a mobile device */}
            {!isMobile && (
              <SimpleButton
                sx={ButtonStyle}
                onClick={() => {
                  history.push(ROUTE_SIGNUP);
                }}
              >
                {planInfo.ctaLabel}
              </SimpleButton>
            )}
          </>
        )}
      </Box>
    </Box>
  );
}

function HighlightText({ text, highlight, color }) {
  // Split the text into parts: before, highlighted, and after
  const parts = text.split(new RegExp(`(${highlight})`, "gi"));

  return (
    <p>
      {parts.map((part, index) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          <span key={index} style={{ color }}>
            {part}
          </span>
        ) : (
          part
        )
      )}
    </p>
  );
}

function PricingCard({ planInfo }) {
  return (
    <Card
      sx={{
        border: planInfo.highlight ? "4px solid rgba(0, 144, 209, 1)" : "none",
        borderRadius: {
          mobile: "10px",
          tablet: "14px",
          laptop: "14px",
          desktop: "14px",
        },
        px: {
          mobile: "18px",
          tablet: "28px",
          laptop: "28px",
          desktop: "28px",
        },
        boxShadow: {
          mobile:
            "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)",
          tablet:
            "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)",
          laptop:
            "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)",
          desktop: "4px 4px 15px 0px rgba(0, 0, 0, 0.25)",
        },
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          justifyContent: "center",
          // background: debugColor("blue"),
          /*  minHeight: {
            mobile: "650px",
            tablet: "750px",
            laptop: "880px",
            desktop: "980px",
          }, */
        }}
      >
        <Box
          sx={{
            background: debugColor("green"),
            // flex: 0.2,
          }}
        >
          <CardTitle
            gutterBottom
            variant="h5"
            component="div"
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              justifyContent: "center",
              fontSize: {
                mobile: "34px",
                tablet: "34px",
                laptop: "34px",
                desktop: "48px",
              },
              mt: {
                mobile: "24px",
                tablet: "28px",
                laptop: "28px",
                desktop: "34px",
              },
            }}
          >
            {planInfo.planName}
          </CardTitle>
          <Divider sx={DIVIDER_STYLE} />
        </Box>
        <Box
          sx={{
            background: debugColor("yellow"),
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            height: {
              mobile: "180px",
              tablet: "210px",
              laptop: "260px",
              desktop: "280px",
            },
            // flex: 1.5,
          }}
        >
          {planInfo.type === "custom" ? (
            //TODO: resolve the font sizing on price and cutom price
            // text on these cards as per the design
            <>
              <Typography
                variant="h3"
                sx={{
                  fontSize: {
                    mobile: "40px",
                    tablet: "40px",
                    laptop: "40px",
                    desktop: "48px",
                  },
                }}
              >
                Custom Pricing
              </Typography>
            </>
          ) : (
            <Typography
              variant="h1"
              sx={{
                fontSize: {
                  mobile: "70px",
                  tablet: "60px",
                  laptop: "60px",
                  desktop: "96px",
                },
              }}
            >
              ${planInfo.pricing}
            </Typography>
          )}
          <PlanInfo
            sx={{
              mt: {
                mobile: "14px",
                tablet: "20px",
                laptop: "20px",
                desktop: "20px",
              },
            }}
          >
            {/* TODO: there has to be custom fonts here */}
            <Typography
              variant="h5"
              sx={{
                fontSize: {
                  mobile: "14px",
                  tablet: "20px",
                  laptop: "20px",
                  desktop: "24px",
                },
              }}
            >
              {planInfo.type === "custom" ? (
                <>{planInfo.info}</>
              ) : (
                <HighlightText
                  text={planInfo.info}
                  highlight="48-hour window"
                  color="blue"
                />
              )}
            </Typography>
          </PlanInfo>
          <Divider sx={DIVIDER_STYLE} />
        </Box>
        <Box
          sx={{
            flex: 1.2,
          }}
        >
          <PlanCharacteristic planInfo={planInfo} />
        </Box>
      </CardContent>
    </Card>
  );
}

export default function PricingSection() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        mt: {
          // this addition of dimension was done in order to scroll to this header correctly
          // for unknown reasons, scrolling to other headers
          // didnt cause any problem on mobile responsive.
          mobile: `${APP_BAR_HEIGHT + 50}px`,
          tablet: "82px",
          laptop: "74px",
          desktop: "169px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <HeaderText variant="h6">
          <span style={GradeintTextStyle}>Predictable</span>
          &nbsp;Pricing
        </HeaderText>
      </Box>
      <Grid
        container
        rowSpacing={{
          mobile: "24px",
          tablet: "36px",
        }}
        columnSpacing={{
          laptop: "52px",
          desktop: "56px",
        }}
        sx={{
          justifyContent: "center",
          alignItems: "start",
          mt: {
            mobile: "36px",
            tablet: "39px",
            laptop: "67px",
            desktop: "74px",
          },
          mb: {
            mobile: "92px",
            tablet: "80px",
            laptop: "109px",
            desktop: "152px",
          },
          px: {
            mobile: "5%",
            tablet: "0%",
            laptop: "0%",
            desktop: "0%",
          },
          /* px: {
            mobile: "10%",
            tablet: "18%",
            laptop: "18%",
            desktop: "20%",
          }, */
        }}
      >
        {PLAN_PRICING.map((planInfo, index) => {
          return (
            <Grid
              key={index}
              item
              mobile={12}
              tablet={12}
              laptop={6}
              desktop={6}
            >
              <PricingCard planInfo={planInfo} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
