import React, { useState, useEffect } from "react";
import {
  Typography,
  CardContent,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Grid,
  CardHeader,
  Paper,
  IconButton,
  InputBase,
  Alert,
  Skeleton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useTheme } from "@material-ui/core/styles";
import { Card } from "../../ui/Card";

import {
  formatCurrency,
  getCloudProviderName,
  showFirstAndLastNChars,
} from "../../utils/commonUtil";
import { Chip } from "../../ui/Chip";
import { TinyText } from "../../ui/TinyText";
import { H6 } from "../../ui/H6";
import { useRecommendationsPossibleSavings } from "../../hooks/useCosts";

const SummaryItem = ({ children }) => {
  return (
    <>
      <Box
        flex={1}
        gap={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "top",
        }}
      >
        {children}
      </Box>
    </>
  );
};

const ValueChip = ({ value, highlight }) => {
  return (
    <>
      <Chip
        sx={{
          border: highlight ? "4px solid #30A952" : "2px solid grey",
        }}
        variant="outlined"
        label={`${formatCurrency("", value)}/m`}
      />
    </>
  );
};

export const SummaryViewCard = ({ selectCloudProvider, selectedAccessKey }) => {
  console.log(
    `selec.selectedAccessKey: , ${selectedAccessKey} selectCloudProvider:  ${selectCloudProvider}`
  );
  const {
    data: possibleSavings,
    isLoading: arePossibleSavingsLoading,
    isError: isPossibleSavingsError,
    error: possibleSavingsError,
  } = useRecommendationsPossibleSavings(selectCloudProvider, selectedAccessKey);

  // console.log("possibleSavings: ", possibleSavings);

  return (
    <Card
      style={{
        border: "2px solid #BCE1FF",
        // background: "green",
      }}
    >
      {arePossibleSavingsLoading ? (
        <>
          <Skeleton animation={"wave"} variant="rectangular" height="100px" />
        </>
      ) : isPossibleSavingsError ? (
        <>
          <Alert severity="warning" sx={{ my: 1 }}>
            {possibleSavingsError}
          </Alert>
        </>
      ) : (
        <Box
          sx={{
            p: 2,
            gap: 2,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <SummaryItem>
            <ValueChip value={possibleSavings?.current_cost} />
            <TinyText
              sx={{
                textAlign: "center",
              }}
            >
              Current Cost
            </TinyText>
          </SummaryItem>
          <SummaryItem>
            <ValueChip value={possibleSavings?.estimated_cost} />
            <TinyText
              sx={{
                textAlign: "center",
              }}
            >
              Estimated Cost
            </TinyText>
          </SummaryItem>
          <SummaryItem>
            <ValueChip
              value={possibleSavings?.possible_total_savings}
              highlight={possibleSavings?.possible_total_savings > 0}
            />
            <TinyText
              sx={{
                textAlign: "center",
              }}
            >
              Possible Total Savings
            </TinyText>
          </SummaryItem>
        </Box>
      )}
    </Card>
  );
};
