// Package Imports
import React, { useState, useEffect, useMemo } from "react";
import {
  Select,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
  Card,
  Skeleton,
  Tabs,
  Tab,
  Alert,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

// Local Imports
import { getLogoForType } from "../../utils/commonUtil";
import {
  useGetCloudKeys,
  useGetCloudTypes,
  useGetRegions,
  useCrossCloudRecommendations,
} from "../../hooks/useCrossCloudRecommendations";
import { CLOUD_PROVIDER_OCI } from "../../utils/constants";
import RecommendationArea from "./components/RecommendationArea";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CrossCloudRecommendations = () => {
  const [selectedCloudProvider, setSelectedCloudProvider] = useState("");
  const [selectedCrossCloudProvider, setSelectedCrossCloudProvider] =
    useState("");
  const [selectedAccessKey, setSelectedAccessKey] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [tabValue, setTabValue] = React.useState(0);
  const [vmListToShow, setVmListToShow] = useState([]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const [selectedVm, setSelectedVm] = useState(null);

  const handleVmClick = (vm) => {
    setSelectedVm(vm);
  };

  const {
    data: cloudTypes,
    isLoading: areCloudTypesLoading,
    isError: isCloudTypesError,
    error: cloudTypesError,
  } = useGetCloudTypes();

  const crossCloudOptions = useMemo(() => {
    if (cloudTypes?.length > 0) {
      const options =
        cloudTypes
          ?.filter(
            (cloudType) =>
              cloudType.name.toLowerCase() !== selectedCloudProvider
          )
          ?.map((cloudType) => ({
            label: cloudType.name,
            value: cloudType.id,
          })) ?? [];

      setSelectedCrossCloudProvider(options[0]?.value);
      return options;
    }
    return [];
  }, [cloudTypes, selectedCloudProvider]);

  useEffect(() => {
    if (cloudTypes?.length > 0) {
      setSelectedCloudProvider(cloudTypes[0]?.name?.toLowerCase());
      setSelectedCrossCloudProvider(crossCloudOptions[0]?.value);
    }
  }, [cloudTypes]);

  const {
    data: accessKeys,
    isLoading: areAccessKeyLoading,
    isError: isAccessKeyError,
    error: accessKeyError,
  } = useGetCloudKeys(
    selectedCloudProvider || cloudTypes?.[0]?.name?.toLowerCase()
  );

  useEffect(() => {
    if (accessKeys?.length > 0) {
      setSelectedAccessKey(accessKeys[0]?.id);
    }
  }, [accessKeys]);

  const selectedCrossCloudProviderName = useMemo(() => {
    if (selectedCrossCloudProvider) {
      return crossCloudOptions
        ?.find((cd) => cd.value === selectedCrossCloudProvider)
        ?.label?.toLowerCase();
    }
    return crossCloudOptions?.[0]?.label?.toLowerCase();
  }, [crossCloudOptions, selectedCrossCloudProvider]);

  const {
    data: regions,
    isLoading: areRegionsLoading,
    isError: isRegionsError,
    error: regionsError,
  } = useGetRegions(selectedCrossCloudProviderName);

  useEffect(() => {
    if (regions?.length > 0) {
      setSelectedRegion(regions[0]?.id);
    }
  }, [regions]);

  const onCloudChanged = (cloudProvider) => {
    setSelectedAccessKey(null);
    setSelectedVm(null);
    setSelectedCloudProvider(cloudProvider.target.value);
  };

  const onCrossCloudChanged = (cloudProvider) => {
    setSelectedVm(null);
    setSelectedCrossCloudProvider(cloudProvider.target.value);
  };

  const handleAccessKeyChanged = (event) => {
    const value = event.target.value;
    setSelectedAccessKey(value);
  };

  const onRegionChanged = (event) => {
    setSelectedRegion(event.target.value);
  };

  const {
    isPending: isCrossCloudRecommendationPending,
    data: crossCloudRecommendations,
    error: crossCloudRecommendationsError,
    mutate: getCrossCloudRecommendations,
    reset: getCrossCloudRecommendationsReset,
  } = useCrossCloudRecommendations();

  useEffect(() => {
    if (
      selectedCrossCloudProviderName?.toLowerCase() ===
        CLOUD_PROVIDER_OCI.toLowerCase() &&
      selectedCloudProvider &&
      selectedAccessKey &&
      selectedCrossCloudProvider
    ) {
      const currentCloudId = cloudTypes?.find(
        (cd) => cd.name.toLowerCase() === selectedCloudProvider
      )?.id;
      getCrossCloudRecommendations({
        currentCloudId: currentCloudId,
        crossCloudId: selectedCrossCloudProvider,
        crossRegionId: selectedRegion,
        keyId: selectedAccessKey,
      });
      return;
    } else {
      setVmListToShow([]);
      setSelectedVm(null);
      getCrossCloudRecommendationsReset();
    }

    if (
      selectedCloudProvider &&
      selectedAccessKey &&
      selectedCrossCloudProvider &&
      selectedRegion
    ) {
      const currentCloudId = cloudTypes?.find(
        (cd) => cd.name.toLowerCase() === selectedCloudProvider
      )?.id;
      getCrossCloudRecommendations({
        currentCloudId: currentCloudId,
        crossCloudId: selectedCrossCloudProvider,
        crossRegionId: selectedRegion,
        keyId: selectedAccessKey,
      });
    } else {
      setVmListToShow([]);
      setSelectedVm(null);
      getCrossCloudRecommendationsReset();
    }
  }, [
    selectedCloudProvider,
    selectedAccessKey,
    selectedCrossCloudProvider,
    selectedRegion,
  ]);

  useEffect(() => {
    if (tabValue === 0) {
      setVmListToShow(crossCloudRecommendations?.insights ?? []);
    } else if (tabValue === 1) {
      setVmListToShow(crossCloudRecommendations?.optimize ?? []);
    } else {
      setVmListToShow([]);
    }
  }, [crossCloudRecommendations, tabValue]);

  useEffect(() => {
    setSelectedVm(vmListToShow?.[0]);
  }, [vmListToShow]);

  const showDropDownErrorMessage = useMemo(() => {
    if (isCloudTypesError || isAccessKeyError || isRegionsError) {
      return (
        cloudTypesError?.message ||
        accessKeyError?.message ||
        regionsError?.message
      );
    }
  });

  return (
    <Box>
      <Box>
        <Box>
          <Box display="grid" gap="20px" gridTemplateColumns="repeat(7, 1fr)">
            <Box gridColumn="span 5">
              <Box
                display="grid"
                gap="20px"
                gridTemplateColumns="1fr 1fr 1fr 1fr"
              >
                {showDropDownErrorMessage ? (
                  <Alert severity="error" sx={{ my: 1, gridColumn: "span 4" }}>
                    {showDropDownErrorMessage}
                  </Alert>
                ) : areCloudTypesLoading ||
                  areAccessKeyLoading ||
                  areRegionsLoading ? (
                  <>
                    <Skeleton
                      animation={"wave"}
                      variant="rectangular"
                      height="50px"
                    />
                    <Skeleton
                      animation={"wave"}
                      variant="rectangular"
                      height="50px"
                    />
                    <Skeleton
                      animation={"wave"}
                      variant="rectangular"
                      height="50px"
                    />
                    <Skeleton
                      animation={"wave"}
                      variant="rectangular"
                      height="50px"
                    />
                  </>
                ) : (
                  <>
                    <FormControl fullWidth>
                      <InputLabel id="select-cloud-provider-label">
                        Select a Cloud
                      </InputLabel>
                      <Select
                        labelId="select-cloud-provider-label"
                        id="select-cloud-provider"
                        value={selectedCloudProvider}
                        label="Cloud Provider"
                        onChange={onCloudChanged}
                      >
                        {cloudTypes?.map((cloudProvider) => (
                          <MenuItem
                            key={cloudProvider.id}
                            value={cloudProvider.name.toLowerCase()}
                          >
                            {cloudProvider.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel
                        shrink={!!selectedAccessKey}
                        id="select-access-key-label"
                      >
                        Access Key
                      </InputLabel>
                      <Select
                        labelId="select-access-key-label"
                        id="select-access-key"
                        value={selectedAccessKey || ""}
                        label="Access Key"
                        onChange={handleAccessKeyChanged}
                      >
                        {accessKeys?.map((account) => (
                          <MenuItem key={account.id} value={account.id}>
                            {account.key_label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel id="select-cross-cloud-label">
                        Select a Cross Cloud
                      </InputLabel>
                      <Select
                        labelId="select-cross-cloud-label"
                        id="select-cross-cloud"
                        value={selectedCrossCloudProvider}
                        label="Select a Cross Cloud"
                        onChange={onCrossCloudChanged}
                      >
                        {crossCloudOptions?.map((cloudProvider) => (
                          <MenuItem
                            key={cloudProvider.value}
                            value={cloudProvider.value}
                          >
                            {cloudProvider.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl
                      fullWidth
                      disabled={
                        selectedCrossCloudProviderName?.toLowerCase() ===
                        CLOUD_PROVIDER_OCI.toLowerCase()
                      }
                    >
                      <InputLabel id="select-region-label">Region</InputLabel>
                      <Select
                        labelId="select-region-label"
                        id="select-region"
                        value={selectedRegion}
                        label="Select Region"
                        onChange={onRegionChanged}
                      >
                        {regions?.map((region) => (
                          <MenuItem key={region.id} value={region.id}>
                            {region.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                )}

                {isCrossCloudRecommendationPending ||
                showDropDownErrorMessage ||
                areCloudTypesLoading ||
                areAccessKeyLoading ||
                areRegionsLoading ? (
                  <Skeleton
                    animation={"wave"}
                    variant="rectangular"
                    height="50px"
                    style={{
                      gridColumn: "span 4",
                    }}
                  />
                ) : (
                  <Card
                    style={{
                      padding: "10px",
                      borderRadius: 4,
                      gridColumn: "span 5",
                      boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
                      opacity: accessKeys?.length > 0 ? 1 : 0,
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "4px",
                      }}
                    >
                      <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        aria-label="recommendation-tabs"
                        style={{ overflow: "initial" }}
                      >
                        <Tab label="INSIGHTS" {...a11yProps(0)} />
                        <Tab label="OPTIMIZE" {...a11yProps(1)} />
                      </Tabs>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 2,
                          border: "1px solid rgba(33, 150, 243, 0.5)",
                          padding: "5px",
                          borderRadius: 4,
                        }}
                      >
                        <InfoOutlinedIcon />
                        <Typography variant="body2">
                          Explore the "Insights" to compare across different
                          cloud platforms, & "Optimize" to discover cross-cloud
                          recommendations.
                        </Typography>
                      </Box>
                    </Box>
                  </Card>
                )}
              </Box>
            </Box>
            <Box>
              {isCrossCloudRecommendationPending ||
              areCloudTypesLoading ||
              areAccessKeyLoading ||
              areRegionsLoading ||
              !selectedCloudProvider ? (
                <Skeleton
                  animation={"wave"}
                  variant="rectangular"
                  height="100%"
                  style={{
                    borderRadius: "14px",
                  }}
                />
              ) : (
                <Box
                  style={{
                    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                    borderRadius: "14px",
                    backgroundColor: "rgba(33, 150, 243, 0.3)",
                    border: "1px solid #2196F3",
                    opacity: accessKeys?.length > 0 ? 1 : 0,
                  }}
                >
                  <Typography
                    variant="body2"
                    style={{
                      padding: "4px",
                      borderTopLeftRadius: "14px",
                      borderTopRightRadius: "14px",
                      borderBottom: "none",
                      textAlign: "center",
                    }}
                  >
                    Selected Cloud
                  </Typography>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    backgroundColor="white"
                    style={{
                      padding: "10px",
                      borderRadius: "14px",
                      height: "100%",
                    }}
                  >
                    <img
                      src={getLogoForType(selectedCloudProvider)}
                      alt={`${selectedCloudProvider} Logo`}
                      width="46px"
                      height="46px"
                    />
                    <Box textAlign="center">
                      <Typography fontWeight="bold" variant="subtitle2">
                        Total Cost
                      </Typography>
                      <Typography fontWeight="bold">
                        {crossCloudRecommendations?.current_cost?.str}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
            <Box>
              {isCrossCloudRecommendationPending ||
              areCloudTypesLoading ||
              areAccessKeyLoading ||
              areRegionsLoading ||
              !selectedCrossCloudProvider ? (
                <Skeleton
                  animation={"wave"}
                  variant="rectangular"
                  height="100%"
                  style={{
                    borderRadius: "14px",
                  }}
                />
              ) : (
                <Box
                  style={{
                    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                    borderRadius: "14px",
                    background:
                      "linear-gradient(to right,rgba(33, 150, 243, 1) 0%, rgba(16, 204, 210, 1) 100%)",
                    border: "1px solid #2196F3",
                    opacity: accessKeys?.length > 0 ? 1 : 0,
                  }}
                >
                  <Typography
                    variant="body2"
                    style={{
                      padding: "4px",
                      borderTopLeftRadius: "14px",
                      borderTopRightRadius: "14px",
                      borderBottom: "none",
                      textAlign: "center",
                    }}
                  >
                    Cross Cloud
                  </Typography>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    backgroundColor="white"
                    style={{
                      padding: "10px",
                      borderRadius: "14px",
                      height: "100%",
                    }}
                  >
                    <img
                      src={getLogoForType(selectedCrossCloudProviderName)}
                      alt={`${selectedCrossCloudProviderName} Logo`}
                      width="46px"
                      height="46px"
                    />
                    <Box textAlign="center">
                      <Typography fontWeight="bold" variant="subtitle2">
                        {tabValue === 0
                          ? crossCloudRecommendations?.current_cost?.num >
                            crossCloudRecommendations?.insights_possible_savings
                              ?.num
                            ? "EST. new cost"
                            : "Value Increased By"
                          : "Est. New Cost"}
                      </Typography>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "5px",
                        }}
                      >
                        <Typography
                          fontWeight="bold"
                          variant="h5"
                          color={tabValue === 0 ? "black" : "green"}
                        >
                          {tabValue === 0
                            ? crossCloudRecommendations
                                ?.insights_possible_savings?.str
                            : crossCloudRecommendations?.possible_savings?.str}
                        </Typography>
                        {tabValue === 0 ? (
                          <Box
                            style={{
                              overflow: "hidden",
                            }}
                          >
                            {crossCloudRecommendations?.current_cost?.num >
                            crossCloudRecommendations?.insights_possible_savings
                              ?.num ? (
                              <Box
                                style={{
                                  width: 0,
                                  height: 0,
                                  borderLeft: "10px solid transparent",
                                  borderRight: "10px solid transparent",

                                  borderTop: "10px solid green",
                                }}
                              />
                            ) : crossCloudRecommendations?.current_cost?.num <
                              crossCloudRecommendations
                                ?.insights_possible_savings?.num ? (
                              <Box
                                style={{
                                  width: 0,
                                  height: 0,
                                  borderLeft: "10px solid transparent",
                                  borderRight: "10px solid transparent",
                                  borderBottom: "10px solid red",
                                }}
                              />
                            ) : null}
                          </Box>
                        ) : null}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
            <RecommendationArea
              isLoading={
                isCrossCloudRecommendationPending ||
                areCloudTypesLoading ||
                areAccessKeyLoading ||
                areRegionsLoading
              }
              selectedVm={selectedVm}
              handleVmClick={handleVmClick}
              vmListToShow={vmListToShow}
              tabValue={tabValue}
              crossCloudRecommendationsError={crossCloudRecommendationsError}
              selectedCloudProvider={selectedCloudProvider}
              selectedCrossCloudProvider={selectedCrossCloudProvider}
              selectedCrossCloudProviderName={selectedCrossCloudProviderName}
              accessKeys={accessKeys}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CrossCloudRecommendations;
