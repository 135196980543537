import React from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
  Select,
  Skeleton,
} from "@mui/material";

import {
  CLOUD_PROVIDER_AWS,
  COST_SAVING_OPTIONS,
} from "../../../utils/constants";
import { getLogoForType } from "../../../utils/commonUtil";

const DropDownArea = ({
  areDropDownOptionsLoading,
  selectedCloudProvider,
  selectedAccessKey,
  selectedCloudService,
  selectedRecommendationType,
  selectedCostSaving,
  cloudTypes,
  accessKeys,
  cloudServices,
  recommendationTypes,
  handleCloudChanged,
  handleAccessKeyChanged,
  handleServiceChanged,
  handleRecommendationTypeChanged,
  handleCostSavingChange,
  isRecommendationDataPending,
  showDropDownErrorMessage,
  recommendationData,
}) => {
  return (
    <>
      <Box gridColumn="span 4">
        <Box
          display="grid"
          gap="20px"
          gridTemplateColumns="1fr 1fr 1fr 1fr 1fr"
        >
          {areDropDownOptionsLoading ? (
            <>
              <Skeleton
                animation={"wave"}
                variant="rectangular"
                height="50px"
              />
              <Skeleton
                animation={"wave"}
                variant="rectangular"
                height="50px"
              />
              <Skeleton
                animation={"wave"}
                variant="rectangular"
                height="50px"
              />
              <Skeleton
                animation={"wave"}
                variant="rectangular"
                height="50px"
              />
              <Skeleton
                animation={"wave"}
                variant="rectangular"
                height="50px"
              />
            </>
          ) : (
            <>
              <FormControl fullWidth>
                <InputLabel id="select-cloud-provider-label">
                  Select a Cloud
                </InputLabel>
                <Select
                  labelId="select-cloud-provider-label"
                  id="select-cloud-provider"
                  value={selectedCloudProvider}
                  label="Cloud Provider"
                  onChange={handleCloudChanged}
                >
                  {cloudTypes?.map((cloudProvider) => (
                    <MenuItem
                      key={cloudProvider.id}
                      value={cloudProvider.name.toLowerCase()}
                    >
                      {cloudProvider.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel
                  shrink={!!selectedAccessKey}
                  id="select-access-key-label"
                >
                  Access Key
                </InputLabel>
                <Select
                  labelId="select-access-key-label"
                  id="select-access-key"
                  value={selectedAccessKey || ""}
                  label="Access Key"
                  onChange={handleAccessKeyChanged}
                >
                  {accessKeys?.map((account) => (
                    <MenuItem key={account.id} value={account.id}>
                      {account.key_label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="select-service-label">Services</InputLabel>
                <Select
                  labelId="select-service-label"
                  id="select-service"
                  value={selectedCloudService}
                  label="Services"
                  onChange={handleServiceChanged}
                >
                  {cloudServices?.map((service) => (
                    <MenuItem
                      key={service.id}
                      value={service.name.toLowerCase()}
                    >
                      {service.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="select-type-label">Type</InputLabel>
                <Select
                  labelId="select-type-label"
                  id="select-type"
                  value={selectedRecommendationType}
                  label="Select Project"
                  onChange={handleRecommendationTypeChanged}
                >
                  {recommendationTypes?.map((recoType) => (
                    <MenuItem
                      key={recoType.id}
                      value={recoType.name.toLowerCase()}
                    >
                      {recoType.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                fullWidth
                disabled={selectedCloudProvider !== CLOUD_PROVIDER_AWS}
              >
                <InputLabel id="select-type-label">Cost Saving</InputLabel>
                <Select
                  labelId="select-type-label"
                  id="select-type"
                  value={selectedCostSaving}
                  label="Select Project"
                  onChange={handleCostSavingChange}
                >
                  {COST_SAVING_OPTIONS?.map((savingType) => (
                    <MenuItem key={savingType.value} value={savingType.value}>
                      {savingType.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}

          {isRecommendationDataPending || showDropDownErrorMessage ? (
            <Box gridColumn="span 2">
              <Skeleton
                animation={"wave"}
                variant="rectangular"
                height="50px"
              />
            </Box>
          ) : (
            <Box
              style={{
                padding: "10px",
                border: "1px solid #C3C3C3",
                borderRadius: 4,
                gridColumn: "span 2",
                opacity: accessKeys?.length > 0 ? 1 : 0,
              }}
            >
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "4px",
                }}
              >
                <Typography variant="subtitle2">
                  {recommendationData?.number_of_instances} Virtual Machines
                </Typography>
              </Box>
            </Box>
          )}

          {isRecommendationDataPending || showDropDownErrorMessage ? (
            <Box gridColumn="span 3">
              <Skeleton
                animation={"wave"}
                variant="rectangular"
                height="50px"
              />
            </Box>
          ) : (
            <Box
              style={{
                padding: "10px",
                border: "1px solid #C3C3C3",
                borderRadius: 4,
                gridColumn: "span 3",
                opacity: accessKeys?.length > 0 ? 1 : 0,
              }}
            >
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "4px",
                }}
              >
                <Typography variant="subtitle2">
                  {recommendationData?.number_of_recommendations} Number of
                  Recommendations
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Box>
        {isRecommendationDataPending ||
        showDropDownErrorMessage ||
        !selectedCloudProvider ? (
          <Skeleton animation={"wave"} variant="rectangular" height="100%" />
        ) : (
          <Box
            style={{
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              borderRadius: "14px",
              backgroundColor: "rgba(33, 150, 243, 0.3)",
              border: "1px solid #2196F3",
              display: "flex",
              flexDirection: "column",
              opacity: accessKeys?.length > 0 ? 1 : 0,
            }}
          >
            <Typography
              variant="body2"
              style={{
                padding: "4px",
                borderTopLeftRadius: "14px",
                borderTopRightRadius: "14px",
                borderBottom: "none",
                textAlign: "center",
              }}
            >
              Selected Key
            </Typography>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              backgroundColor="white"
              style={{
                padding: "10px",
                borderRadius: "14px",
                height: "100%",
              }}
            >
              <img
                src={getLogoForType(selectedCloudProvider)}
                alt={`${selectedCloudProvider} Logo`}
                width="46px"
                height="46px"
              />
              <Box textAlign="center">
                <Typography fontWeight="bold" variant="subtitle2">
                  Current Price
                </Typography>
                <Typography fontWeight={"bold"}>
                  {recommendationData?.current_price?.str}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <Box>
        {isRecommendationDataPending ||
        showDropDownErrorMessage ||
        !selectedCloudProvider ? (
          <Skeleton animation={"wave"} variant="rectangular" height="100%" />
        ) : (
          <Box
            style={{
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              borderRadius: "14px",
              background:
                "linear-gradient(to right,rgba(33, 150, 243, 1) 0%, rgba(16, 204, 210, 1) 100%)",
              border: "1px solid #2196F3",
              opacity: accessKeys?.length > 0 ? 1 : 0,
            }}
          >
            <Typography
              variant="body2"
              style={{
                padding: "4px",
                borderTopLeftRadius: "14px",
                borderTopRightRadius: "14px",
                borderBottom: "none",
                textAlign: "center",
              }}
            >
              For Selected Key
            </Typography>

            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              backgroundColor="white"
              style={{
                padding: "10px",
                borderRadius: "14px",
              }}
            >
              <>
                <img
                  src={getLogoForType(selectedCloudProvider)}
                  alt={`${selectedCloudProvider} Logo`}
                  width="46px"
                  height="46px"
                />
                <Box textAlign="center">
                  <Typography fontWeight="bold" variant="subtitle2">
                    Possible Savings
                  </Typography>

                  <Typography color="green" fontWeight={"bold"} variant="h5">
                    {recommendationData?.possible_savings?.str}
                  </Typography>
                </Box>
              </>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default DropDownArea;
