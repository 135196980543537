import React from "react";
import { Box, Card, CardContent } from "@mui/material";
import { getItemStyle } from "./../components/recommendationDetails/RecommendationDetails";

// TODO : Optimise it more - for logo passing and selected cloud
const CloudProviderCard = ({ logo, alt, isSelected, onClick, theme }) => {
  const getCloudProviderCardStyle = () => {
    return {
      ...getItemStyle(theme, isSelected),
      width: 138,
      height: 88,
      borderRadius: "16px",
    };
  };

  const CloudProviderCardContentStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <Card style={getCloudProviderCardStyle()} onClick={onClick}>
      <CardContent style={CloudProviderCardContentStyle}>
        <Box component="img" src={logo} alt={alt} />
      </CardContent>
    </Card>
  );
};

export default CloudProviderCard;
