import styled from "@emotion/styled/macro";
import { Box, Card, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import FeatureBudgeting from "../../assets/gif/budgeting.gif";
import FeatureCloud from "../../assets/gif/cloud.gif";
import FeatureCostEffectiveness from "../../assets/gif/cost_effectiveness.gif";
import FeatureGrowth from "../../assets/gif/growth.gif";
import FeatureLineGraph from "../../assets/gif/line_graph.gif";
import FeatureSave from "../../assets/gif/save.gif";
import Curve from "../../assets/svg/curve.svg";
import { APP_BAR_HEIGHT } from "../../ui/AppBar";
import { USER_FACING_PRODUCT_NAME } from "../../utils/constants";
import { GradeintTextStyle, HeaderText } from "./LandingPage";
import { useBreakpoint } from "../../hooks/useBreakpoint";
import FeatureSectionImageLaptopAndDesktop from "../../assets/png/feature_section_image_laptop_desktop.png";
import FeatureSectionImageMobile from "../../assets/png/feature_section_image_phone.png";
import FeatureSectionImageTablet from "../../assets/png/feature_section_image_tablet.png";

const FeatureCardStyled = styled(Card)`
  border-radius: 12px;
  background: #f9fdff;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.25);
`;

export const Title = styled(Typography)`
  font-style: normal;
  font-weight: 800;
  text-align: center;
`;

export const CARD_HEADING_STYLE = {
  whiteSpace: "break-spaces",
  textAlign: "center",
  fontSize: {
    mobile: "20px",
    tablet: "24px",
    laptop: "32px",
    desktop: "34px",
  },
};

export const CARD_DESC_STYLE = {
  fontSize: {
    mobile: "15px",
    tablet: "18px",
    laptop: "18px",
    desktop: "24px",
  },
};

export const CardHeading = styled(Typography)`
  color: #3a3a3a;

  /* font-feature-settings: "liga" off, "clig" off; */

  /* typography/h4 */
  /* font-style: normal; */
  font-weight: 400;
  line-height: 123.5%; /* 41.99px */
  letter-spacing: 0.25px;
`;

export const CardDescription = styled(Typography)`
  color: #3a3a3a;

  /* font-feature-settings: "liga" off, "clig" off; */

  /* typography/h4 */
  /* font-style: normal; */
  font-weight: 400;
  line-height: 123.5%; /* 41.99px */
  letter-spacing: 0.25px;
`;

const FEATURES = [
  {
    heading: "Resource Optimization",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nunc nulla, porta id lacinia sedsan.",
    image: FeatureLineGraph,
  },
  {
    heading: "Budget Alerts",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nunc nulla, porta id lacinia sedsan.",
    image: FeatureGrowth,
  },
  {
    heading: "Smart Saving Recommendation",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nunc nulla, porta id lacinia sedsan.",
    image: FeatureBudgeting,
  },
  {
    heading: "Multi-Cloud Support",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nunc nulla, porta id lacinia sedsan.",
    image: FeatureCloud,
  },
  {
    heading: "Usage Analytics",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nunc nulla, porta id lacinia sedsan.",
    image: FeatureCostEffectiveness,
  },
  {
    heading: "Automated Reports",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nunc nulla, porta id lacinia sedsan.",
    image: FeatureSave,
  },
];

function FeatureCard({ feature }) {
  return (
    <FeatureCardStyled
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: {
          mobile: "170px",
          tablet: "250px",
          laptop: "250px",
          desktop: "250px",
        },
        padding: {
          mobile: "23px",
        },
      }}
    >
      <CardHeading sx={CARD_HEADING_STYLE}>{feature.heading}</CardHeading>
      {/* <Box
        sx={{
          px: "75px",
          // pb: "50px",
          mt: "24px",
        }}
      >
        <CardDescription sx={CARD_DESC_STYLE}>
          {feature.description}
        </CardDescription>
      </Box> */}
      <Box
        sx={{
          position: "absolute",
          left: "-5px",
          bottom: "-5px",
          display: "flex",
          alignSelf: "baseline",
          alignItems: "center",
          justifyContent: "center",
          background: `url(${Curve})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          width: "92px",
          aspectRatio: 1,
          overflow: "hidden",
        }}
      >
        {/* TODO: fix the gif image and its placement */}
        <Box
          sx={{
            position: "relative",
            left: "-10px",
            bottom: "-10px",
            width: "38px",
            aspectRatio: 1,
            transform: "translate(4px, -4px)",
          }}
          component="img"
          src={feature.image}
        />
      </Box>
    </FeatureCardStyled>
  );
}

export default function FeatureSection() {
  const breakpoint = useBreakpoint();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        maxWidth: {
          desktop: "1400px",
          laptop: "1200px",
          tablet: "750px",
        },
        margin: { tablet: "0 auto" },
        mt: {
          // this addition of dimension was done in order to scroll to this header correctly
          // for unknown reasons, scrolling to other headers
          // didnt cause any problem on mobile responsive.
          mobile: `${APP_BAR_HEIGHT + 44}px`,
          tablet: "114px",
          laptop: "100px",
          desktop: "127px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          // width: "100%",
        }}
      >
        {/* <GradeintText variant="h6">Features</GradeintText>
        <Title variant="h6">&nbsp;Of {USER_FACING_PRODUCT_NAME}</Title> */}
        <HeaderText variant="h6">
          <span style={GradeintTextStyle}>Features</span>
          &nbsp;Of {USER_FACING_PRODUCT_NAME}
        </HeaderText>
      </Box>
      <Box
        sx={{
          pt: "40px",
          mb: {
            tablet: "80px",
            laptop: "107px",
            desktop: "104px",
          },
          // px: {
          //   mobile: "25px",
          //   tablet: "45px",
          //   laptop: "60px",
          //   desktop: "88px",
          // },
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: {
              mobile: "100%",
              laptop: "80%",
              desktop: "100%",
            },
          }}
          component="img"
          src={
            breakpoint === "mobile"
              ? FeatureSectionImageMobile
              : breakpoint === "tablet"
              ? FeatureSectionImageTablet
              : FeatureSectionImageLaptopAndDesktop
          }
        />
      </Box>
      <Divider
        sx={{
          height: "2px",
          background: "linear-gradient(90deg, #9ED2FA 0%, #10CCD2 100%)",
          width: "100%",
          display: {
            mobile: "none",
            tablet: "block",
            laptop: "block",
            desktop: "block",
          },
        }}
      />
    </Box>
  );
}
