import styled from "@emotion/styled/macro";
import { Button as BaseButton } from "@mui/material";

const Button = styled(BaseButton)`
  color: #ffffff;
  border: none;
  background: #2196f3;
  text-transform: uppercase;
  /* &&& {
    &.Mui-disabled {
      color: rgba(0, 0, 0, 0.38);
      background: rgba(0, 0, 0, 0.12);
    },
    &:hover {
      background: #FF0000;
    },
  } */
`;

export const SolidButton = (props) => {
  return <Button {...props} />;
};
