import React, { useState } from "react";
import useCloudProviderToken from "../hooks/useCloudProviderToken";
import { DialogActions, TextField, Box } from "@mui/material";
import { SolidButton } from "../ui/SolidButton";

const FileUpload = ({
  selectCloudProvider,
  tokenId,
  editMode,
  key_label,
  onClose,
}) => {
  const [configFile, setConfigFile] = useState(null);
  const [pemFile, setPemFile] = useState(null);
  const [keyLabel, setKeyLabel] = useState(key_label || "");
  const [error, setError] = useState(null);
  const { updateTokenForOCI, insertTokenForOCI } = useCloudProviderToken();

  const handleConfigFileChange = (e) => {
    const file = e.target.files[0];
    const fileExtension = file.type;
    //Allow file without extension
    if (file && fileExtension === "") {
      setConfigFile(file);
      setError(null);
    } else {
      setError("Invalid file type, please upload valid config file only");
    }
  };

  const handlePemFileChange = (e) => {
    const file = e.target.files[0];
    const fileExtension = file.name.split(".").pop()?.toLowerCase();
    if (fileExtension !== "pem") {
      setError("Invalid file type. Please upload a .pem file.");
      return;
    }
    setError(null);
    setPemFile(file);
  };

  const handleKeyLabelChange = (e) => {
    setKeyLabel(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    if (configFile) formData.append("config_file", configFile);
    if (pemFile) formData.append("pem_file", pemFile);
    formData.append("key_label", keyLabel);

    try {
      let result;
      if (editMode) {
        result = await updateTokenForOCI(
          selectCloudProvider,
          tokenId,
          formData
        ); // Pass isMultipart as true
      } else {
        result = await insertTokenForOCI(selectCloudProvider, formData); // Pass isMultipart as true
      }

      if (onClose) onClose(); // Close the modal after successful submission
    } catch (error) {
      setError(error.message || "An error occurred");
    }
  };

  const handleCancel = () => {
    // Clear the form fields
    setConfigFile(null);
    setPemFile(null);
    setKeyLabel(key_label || ""); // Reset to initial key_label value
    setError(null); // Clear any errors
    if (onClose) onClose(); // Close the modal when "Cancel" is clicked
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div>
          <TextField
            name="key_label"
            label="Key Label"
            fullWidth
            required
            margin="dense"
            onChange={handleKeyLabelChange}
            value={keyLabel}
          />
        </div>
        <div>
          <TextField
            name="config_file"
            label="Config File ID"
            InputLabelProps={{ shrink: true }}
            fullWidth
            required
            margin="dense"
            type="file"
            onChange={handleConfigFileChange}
            inputProps={{
              style: { textOverflow: "ellipsis" },
            }}
          />
        </div>
        <div>
          <TextField
            name="pem_file"
            label="Pem File ID"
            InputLabelProps={{ shrink: true }}
            fullWidth
            required
            margin="dense"
            type="file"
            onChange={handlePemFileChange}
            inputProps={{
              accept: ".pem",
            }}
          />
        </div>
        <Box>
          <Box
            sx={{
              color: "red",
            }}
          >
            {error && <div>Error: {error}</div>}
          </Box>
          <DialogActions>
            <SolidButton
              type="button"
              onClick={handleCancel}
              style={{ marginLeft: "10px" }}
            >
              Cancel
            </SolidButton>
            <SolidButton type="submit" disabled={error != null}>
              Upload
            </SolidButton>
          </DialogActions>
        </Box>
      </form>
    </div>
  );
};

export default FileUpload;
