import axios from "axios";

import { API_URL } from "../config/config";
import { getCookie } from "./storageUtil";
import { STORAGE_AUTH_TOKEN } from "./constants";

export const httpBase = () => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  const token = getCookie(STORAGE_AUTH_TOKEN);
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const api = axios.create({
    baseURL: `${API_URL}`,
    headers,
    responseType: "json",
  });

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      // we are rejecting in this state entirely
      // and letting the upper layers do whatever
      // it wants to do with the error.response object.
      /* if (401 === error.response.status) {
            throw Error('Unauthorized 401');
        }
        if (404 === error.response.status) {
            throw Error('Not found 404');
        }
        if (500 === error.response.status) {
            throw Error('Server error 500');
        } */

      return Promise.reject(error);
    }
  );

  return api;
};
