import React, { useState } from "react";
import {
  Box,
  Switch,
  Typography,
  Divider,
  Card,
  CardContent,
} from "@mui/material";

import { SolidButton } from "../../ui/SolidButton";
import free from "../../assets/gif/free.gif";
import starter from "../../assets/gif/starter.gif";
import premium from "../../assets/gif/premium.gif";
import ultimate from "../../assets/gif/ultimate.gif";
import PricingDiscountArrow from "../../assets/png/pricing_discount_arrow.png";
import { ReactComponent as CheckFilledWithGradient } from "../../assets/CheckFilledWithGradient.svg";
import { GradeintText } from "../../pages/landingPage/LandingPage";

const evalute_plan_id = "evalute_plan";
const starter_plan_id = "starter_plan";
const premium_plan_id = "premium_plan";
const ultimate_plan_id = "ultimate_plan";

const PLAN_NAMES = {
  [evalute_plan_id]: "Evaluate Plan",
  [starter_plan_id]: "Starter Plan",
  [premium_plan_id]: "Premium Plan",
  [ultimate_plan_id]: "Ultimate Plan",
};

const GIF_IMAGES_FOR_PLANS = {
  [PLAN_NAMES.evalute_plan]: free,
  [PLAN_NAMES.starter_plan]: starter,
  [PLAN_NAMES.premium_plan]: premium,
  [PLAN_NAMES.ultimate_plan]: ultimate,
};

const CHARACTERISTICS = {
  monthly_tracked_cloud_spend: "Monthly tracked cloud spend",
  number_of_users: "Number of users",
  accounts: "Accounts",
  number_of_cloud_supported: "Number of cloud supported",
  usage_analytics: "Usage Analytics",
  resource_optimization: "Resource Optimization",
  reports: "Reports",
  anamoly_detection: "Anamoly Detection",
  real_time_monitoring: "Real-time Monitoring",
  budget_alerts: "Budget Alerts",
  priority_support: "Priority Support",
  cross_cloud: "Cross-Cloud",
};

const PLANS = [
  {
    id: evalute_plan_id,
    name: PLAN_NAMES.evalute_plan,
    pricing: "Free",
    info: (
      <>
        <Typography>Claim your</Typography>
        <GradeintText>Free 3 VM recommendations</GradeintText>
        <Typography>by signing up today!</Typography>
      </>
    ),
    characteristics: {
      [CHARACTERISTICS.monthly_tracked_cloud_spend]: "Up to 5K USD",
      [CHARACTERISTICS.number_of_users]: "1",
      [CHARACTERISTICS.accounts]: "1",
      [CHARACTERISTICS.number_of_cloud_supported]: "1",
      [CHARACTERISTICS.usage_analytics]: true,
      [CHARACTERISTICS.resource_optimization]: "3 VM recommendations",
      [CHARACTERISTICS.reports]: null,
      [CHARACTERISTICS.anamoly_detection]: null,
      [CHARACTERISTICS.real_time_monitoring]: null,
      [CHARACTERISTICS.budget_alerts]: null,
      [CHARACTERISTICS.priority_support]: null,
      [CHARACTERISTICS.cross_cloud]: null,
    },
  },
  {
    id: starter_plan_id,
    name: PLAN_NAMES.starter_plan,
    pricing: {
      monthly: "$99/m",
      yearly: "$79/m",
    },
    info: (
      <>
        <GradeintText>Upto 5K USD Monthly Spend</GradeintText>
        <Typography>
          Essential to jumpstart your cloud cost optimization
        </Typography>
      </>
    ),
    characteristics: {
      [CHARACTERISTICS.monthly_tracked_cloud_spend]: "Up to 5K USD",
      [CHARACTERISTICS.number_of_users]: "1",
      [CHARACTERISTICS.accounts]: "1",
      [CHARACTERISTICS.number_of_cloud_supported]: "1",
      [CHARACTERISTICS.usage_analytics]: true,
      [CHARACTERISTICS.resource_optimization]: "unlimited",
      [CHARACTERISTICS.reports]: true,
      [CHARACTERISTICS.anamoly_detection]: null,
      [CHARACTERISTICS.real_time_monitoring]: null,
      [CHARACTERISTICS.budget_alerts]: null,
      [CHARACTERISTICS.priority_support]: null,
      [CHARACTERISTICS.cross_cloud]: null,
    },
  },
  {
    id: premium_plan_id,
    name: PLAN_NAMES.premium_plan,
    pricing: { monthly: "$249/m", yearly: "$229/m" },
    info: (
      <>
        <GradeintText>Upto 20K USD Monthly Spend</GradeintText>
        <Typography>
          Everything you need to optimize your cloud costs.
        </Typography>
      </>
    ),
    characteristics: {
      [CHARACTERISTICS.monthly_tracked_cloud_spend]: "Up to 20K USD",
      [CHARACTERISTICS.number_of_users]: "upto 5",
      [CHARACTERISTICS.accounts]: "unlimited",
      [CHARACTERISTICS.number_of_cloud_supported]: "4",
      [CHARACTERISTICS.usage_analytics]: true,
      [CHARACTERISTICS.resource_optimization]: "unlimited",
      [CHARACTERISTICS.reports]: true,
      [CHARACTERISTICS.anamoly_detection]: true,
      [CHARACTERISTICS.real_time_monitoring]: true,
      [CHARACTERISTICS.budget_alerts]: true,
      [CHARACTERISTICS.priority_support]: true,
      [CHARACTERISTICS.cross_cloud]: true,
    },
    isRecommended: true,
  },
  {
    id: ultimate_plan_id,
    name: PLAN_NAMES.ultimate_plan,
    pricing: "Custom",
    info: (
      <>
        <GradeintText>Customize your pricing</GradeintText>
        <Typography>based on your needs and</Typography>
        <GradeintText>Across Cross-Cloud</GradeintText>
      </>
    ),
    characteristics: {
      [CHARACTERISTICS.monthly_tracked_cloud_spend]: "Up to 20K USD",
      [CHARACTERISTICS.number_of_users]: "upto 5",
      [CHARACTERISTICS.accounts]: "unlimited",
      [CHARACTERISTICS.number_of_cloud_supported]: "4",
      [CHARACTERISTICS.usage_analytics]: true,
      [CHARACTERISTICS.resource_optimization]: "unlimited",
      [CHARACTERISTICS.reports]: true,
      [CHARACTERISTICS.anamoly_detection]: true,
      [CHARACTERISTICS.real_time_monitoring]: true,
      [CHARACTERISTICS.budget_alerts]: true,
      [CHARACTERISTICS.priority_support]: true,
      [CHARACTERISTICS.cross_cloud]: true,
    },
  },
];

const GradientCircleCheckIcon = () => {
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "30px",
        height: "30px",
        borderRadius: "50%",
        background: "linear-gradient(to right, #2196F3, #10CCD2)",
        padding: "2px",
        boxShadow: "0px 3px 5px -1px rgba(0, 0, 0, 0.2)",
      }}
    >
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          background: "white",
          borderRadius: "50%",
        }}
      >
        <CheckFilledWithGradient />
      </Box>
    </Box>
  );
};

const ProfileAndPlanSection = () => {
  const [showYearlyPrice, setShowYearlyPrice] = useState(false);

  const handleSwitchChange = () => {
    setShowYearlyPrice(!showYearlyPrice);
  };

  return (
    <Box
      sx={{
        padding: { desktop: "0 40px", laptop: "0 10px" },
        maxWidth: "calc(100% - 56px)",
      }}
    >
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          padding: "0 400px",
        }}
      >
        <Divider style={{ flex: 1, backgroundColor: "#10CCD2" }} />
        <Typography fontWeight="bold">Monthly</Typography>
        <Switch checked={showYearlyPrice} onChange={handleSwitchChange} />
        <Box position="relative">
          <Typography fontWeight="bold">Yearly</Typography>
          <Box
            style={{
              position: "absolute",
              left: "10px",
              top: "25px",
              display: "flex",
              alignItems: "flex-end",
              gap: "10px",
            }}
          >
            <img
              src={PricingDiscountArrow}
              alt="discount arrow"
              style={{ width: "87px", height: "39px" }}
            />
            <Typography
              variant="subtitle2"
              style={{
                color: "#2196F3",
                whiteSpace: "nowrap",
                position: "relative",
                top: "-5px",
              }}
            >
              Save up to 30%
            </Typography>
          </Box>
        </Box>
        <Divider style={{ flex: 1, backgroundColor: "#10CCD2" }} />
      </Box>
      <Box
        style={{
          display: "grid",
          // gridTemplateColumns: "repeat(4, minmax(272px, 1fr))",
          gridTemplateColumns: "repeat(4, 1fr)",
          gap: "20px",
          marginTop: "40px",
          // alignItems: "flex-end",
        }}
      >
        {PLANS.map((plan) => {
          if (plan.isRecommended) {
            return (
              <Box
                style={{
                  background: "linear-gradient(to right, #2196F3, #10CCD2)",
                  borderRadius: "14px",
                }}
              >
                <Typography style={{ padding: "5px 10px", color: "white" }}>
                  Recommended Plan
                </Typography>
                <Card
                  style={{
                    flexShrink: 0,
                    borderRadius: "14px",
                  }}
                >
                  <CardContent
                    style={{
                      padding: "20px",
                    }}
                  >
                    <Box>
                      <Typography
                        variant="h5"
                        fontWeight="bold"
                        textAlign="center"
                      >
                        {plan.name}
                      </Typography>
                      <Divider style={{ margin: "30px 0" }} />
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={GIF_IMAGES_FOR_PLANS[plan.name]}
                          alt="free"
                          style={{ width: "40px", height: "40px" }}
                        />
                        <Typography variant="h3" textAlign="center">
                          {typeof plan.pricing === "string"
                            ? plan.pricing
                            : showYearlyPrice
                            ? plan.pricing.yearly
                            : plan.pricing.monthly}
                        </Typography>
                      </Box>
                      <Box style={{ textAlign: "center", margin: "20px 0" }}>
                        {plan.info}
                      </Box>
                    </Box>
                    <Divider />
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                      }}
                    >
                      {/* <SolidButton style={{ padding: "4px 24px" }}>
                        Talk to Us
                      </SolidButton> */}
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            );
          }

          return (
            <Card
              style={{ flexShrink: 0, borderRadius: "14px", marginTop: "34px" }}
            >
              <CardContent
                style={{
                  padding: "20px",
                }}
              >
                <Box>
                  <Typography variant="h5" fontWeight="bold" textAlign="center">
                    {plan.name}
                  </Typography>
                  <Divider style={{ margin: "30px 0" }} />
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      justifyContent: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <img
                      src={GIF_IMAGES_FOR_PLANS[plan.name]}
                      alt="free"
                      style={{ width: "40px", height: "40px" }}
                    />
                    <Typography variant="h3" textAlign="center">
                      {typeof plan.pricing === "string"
                        ? plan.pricing
                        : showYearlyPrice
                        ? plan.pricing.yearly
                        : plan.pricing.monthly}
                    </Typography>
                  </Box>
                  <Box style={{ textAlign: "center", marginBottom: "20px" }}>
                    {plan.info}
                  </Box>
                </Box>
                <Divider />
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  {/* <SolidButton style={{ padding: "4px 24px" }}>
                    Talk to Us
                  </SolidButton> */}
                </Box>
              </CardContent>
            </Card>
          );
        })}
      </Box>
      <Box
        style={{
          marginTop: "20px",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          borderRadius: "14px",
        }}
      >
        <Box>
          <Box
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(4, minmax(272px, 1fr))",
              placeItems: "center",
              gap: "20px",
              backgroundColor: "rgba(33, 150, 243, 0.1)",
              padding: "10px",
              borderRadius: "14px 14px 0 0",
            }}
          >
            <Typography
              fontWeight="bold"
              alignSelf="left"
              width="100%"
              paddingLeft="20px"
            >
              Plan Features
            </Typography>
            <Typography fontWeight="bold">Evaluate</Typography>
            <Typography fontWeight="bold">Starter</Typography>
            <Typography fontWeight="bold">Premium</Typography>
          </Box>
          {Object.entries(CHARACTERISTICS).map(([key, value]) => {
            return (
              <Box
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(4, minmax(272px, 1fr))",
                  placeItems: "center",
                  gap: "20px",
                  padding: "10px",
                }}
              >
                <Typography
                  fontWeight="bold"
                  alignSelf="left"
                  width="100%"
                  paddingLeft="20px"
                >
                  {value}
                </Typography>
                <Typography fontWeight="bold">
                  {PLANS[0].characteristics[value] === true ? (
                    <GradientCircleCheckIcon />
                  ) : (
                    PLANS[0].characteristics[value]
                  )}
                </Typography>
                <Typography fontWeight="bold">
                  {PLANS[1].characteristics[value] === true ? (
                    <GradientCircleCheckIcon />
                  ) : (
                    PLANS[1].characteristics[value]
                  )}
                </Typography>
                <Typography fontWeight="bold">
                  {PLANS[2].characteristics[value] === true ? (
                    <GradientCircleCheckIcon />
                  ) : (
                    PLANS[2].characteristics[value]
                  )}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box margin="20px 0">
        <Typography variant="h5" textAlign="center" color="primary">
          Get the nudge you need to take control of your cloud costs
        </Typography>
      </Box>
    </Box>
  );
};

export default ProfileAndPlanSection;
