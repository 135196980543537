import React, { Children } from "react";
import { Box, Typography, Drawer } from "@mui/material";
import styled from "@emotion/styled/macro";

import { AppBar } from "../../../ui/AppBar";
import { useBreakpoint } from "../../../hooks/useBreakpoint";
import { APP_BAR_HEIGHT } from "../../../ui/AppBar";
import Footer from "../../../pages/landingPage/Footer";
import DrawerBody from "../../../pages/landingPage/DrawerBody";

// import HalfCloudRight from "../../assets/half_cloud_right.svg";
// import HalfCloudLeft from "../../assets/half_cloud_left.svg";
// import HalfCloudRightMobile from "../../assets/half_cloud_right_mobile.svg";
// import HalfCloudLeftMobile from "../../assets/half_cloud_left_mobile.svg";
// import HalfCloudLeftWider from "../../assets/half_cloud_left_wider.svg";
// import HalfCloudRightWider from "../../assets/half_cloud_right_wider.svg";
// import PricingSection from "../../pages/landingPage/PricingSection";
// import ProfileAndPlanSection from "./ProfileAndPlanSection";
// import { GradeintText } from "../../pages/landingPage/LandingPage";

const drawerWidth = 246;

export const MenuText = styled(Typography)`
  color: #3a3a3a;
  font-size: 22px;
  font-style: normal;
  font-weight: 800;
  cursor: pointer;
`;

// TODO: move this to a separate file
export const DOM_ID_FEATURE = "#features";

const PublicLayout = ({ children }) => {
  const breakpoint = useBreakpoint();
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const scrollToId = (idWithHash) => {
    // TODO: change this as its an
    // anti pattern in react.
    const section = document.getElementById(idWithHash);
    section.scrollIntoView({ behavior: "smooth" });
  };

  console.log(breakpoint);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        background: "#FFFFFF",
      }}
    >
      <AppBar toggleDrawer={toggleDrawer(!open)} scrollToId={scrollToId} />
      <Drawer
        open={open}
        onClose={toggleDrawer(false)}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <DrawerBody open={open} setOpen={setOpen} scrollToId={scrollToId} />
      </Drawer>
      <Box
        sx={{
          // maxWidth: {
          //   desktop: "1600px",
          //   laptop: "1300px",
          //   tablet: "750px",
          // },
          paddingX: {
            mobile: "10px",
            tablet: "27px",
            laptop: "93px",
            desktop: "251px",
          },
          margin: { mobile: "0 30px", tablet: "0 auto" },
          overflow: "hidden",
          mt: `${APP_BAR_HEIGHT}px`,
        }}
      >
        {children}
      </Box>
      <Footer />
    </Box>
  );
};

export default PublicLayout;
