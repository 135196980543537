import React from "react";
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
} from "@mui/material";

const AuditLogs = () => {
  return (
    <Box>
      <Typography variant="h4">Audit Logs</Typography>
      <Typography variant="body1">
        Monitor user activities and gain insights into platform usages.
      </Typography>

      <Box style={{ marginTop: "20px" }}>
        <Table>
          <TableHead
            style={{ backgroundColor: "rgba(33, 150, 243, 0.1)" }}
            sx={{
              "& .MuiTableRow-root th:first-child": {
                borderTopLeftRadius: "10px",
              },
              "& .MuiTableRow-root th:last-child": {
                borderTopRightRadius: "10px",
              },
            }}
          >
            <TableRow style={{ borderRadius: "14px" }}>
              <TableCell>
                <Typography fontWeight="bold" textAlign="center">
                  TimeStamp
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight="bold" textAlign="center">
                  Logs
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight="bold" textAlign="center">
                  Log Type
                </Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight="bold" textAlign="center">
                  Environment
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                style={{
                  maxWidth: "150px",
                  padding: "10px",
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                }}
              >
                <Typography textAlign="center">
                  Monthly tracked cloud spend
                </Typography>
              </TableCell>
              <TableCell
                style={{
                  maxWidth: "150px",
                  padding: "10px",
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                }}
              >
                <Typography textAlign="center">Up to 5K USD</Typography>
              </TableCell>
              <TableCell
                style={{
                  maxWidth: "150px",
                  padding: "10px",
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                }}
              >
                <Typography textAlign="center">Starter</Typography>
              </TableCell>
              <TableCell
                style={{
                  maxWidth: "150px",
                  padding: "10px",
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                }}
              >
                <Typography textAlign="center">
                  <Chip
                    label="Whileone"
                    style={{
                      backgroundColor: "rgba(33, 150, 243, 0.09)",
                      fontWeight: "bold",
                    }}
                  />
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default AuditLogs;
